import { Form, Layout, notification, Typography } from 'antd';
import { FormProviderProps } from 'antd/lib/form/context';
import { Content } from 'antd/lib/layout/layout';
import React, { FC, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ContactsForm } from '../../components/ContactsForm';
import { EntityHeader } from '../../components/EntityHeader';
import { BaseEventForm, DatesAndTickets } from '../../components/EventForm';
import { RelatedCardData } from '../../components/EventForm/EventForm.typedef';
import { InfoForSiteForm } from '../../components/EventForm/InfoForSiteForm';
import { EventRelatedPlacesAndEvents } from '../../components/EventRelatedPlacesAndEvents';
import { FormHeader } from '../../components/FormHeader';
import { Gallery } from '../../components/Gallery';
import { OurReview } from '../../components/Review';
import { Schedule } from '../../components/Schedule';
import { SocialRoles } from '../../components/SocialRoles';
import { TabsWithButtons } from '../../components/TabsWithButtons/TabsWithButtons';
import TicketsForm from '../../components/TicketsForm/TicketsForm';
import { useSocialRoles } from '../../hooks';
import { eventTabKeys, eventTabs, TAB_PREFIX, useTabs } from '../../hooks/useTabs';
import { ImageEntity, ImageType } from '../../hooks/useUploadImage';
import { showFormErrorMessages } from '../../utils/showFormErrorMessages';
import { EventContentProps } from './Event.types';

const getTabs = (isNewEvent: boolean, isGlobal: boolean) => {
  const tabs = isNewEvent
    ? eventTabs.filter(
        ({ key }) => ![eventTabKeys.review, eventTabKeys.gallery, eventTabKeys.relatedPlacesAndEvents].includes(key),
      )
    : eventTabs;
  if (isGlobal) return tabs.filter(({ key }) => key !== eventTabKeys.datesAndTickets);
  return tabs;
};

export const EventContent: FC<EventContentProps> = ({
  onSubmit,
  event: { baseInfo, infoForSite, image, datesAndTickets, gallery, ourReview, contacts, needs, children, id, tickets },
  onArchive,
}) => {
  const [searchParams] = useSearchParams();
  const initialTab = searchParams.get(TAB_PREFIX)?.toString();
  const needsProps = useSocialRoles(needs ?? []);
  const { activeTab, tabs, setActiveTab, goToNextTab, goToPrevTab } = useTabs(
    getTabs(isNaN(+id), baseInfo.status === 'global'),
    initialTab,
  );
  const [favoriteImage, setFavoriteImage] = useState(image);

  const eventRelatedCardMapper = (ev: RelatedCardData) => {
    return {
      id: ev.id,
      title: ev.name,
      description: ev.address,
      imageUrl: ev.imageUrl,
    };
  };

  const mappedChildrenEvents = (events: Array<RelatedCardData>) => {
    return events.map((item) => {
      return eventRelatedCardMapper(item);
    });
  };

  const defineScheduleType = () => {
    switch (baseInfo.frequency) {
      case 'one-time':
        return <DatesAndTickets {...datesAndTickets} />;
      case 'regular':
        return <Schedule {...datesAndTickets} />;
      default:
        return null;
    }
  };
  const tabContent = {
    [eventTabKeys.common]: <BaseEventForm initialValues={baseInfo} id={id} />,
    [eventTabKeys.siteData]: <InfoForSiteForm initialValues={infoForSite} />,
    [eventTabKeys.tickets]: <TicketsForm {...tickets} />,
    [eventTabKeys.datesAndTickets]: defineScheduleType(),
    [eventTabKeys.gallery]: (
      <Gallery
        gallery={gallery ?? []}
        title="Галерея события"
        entity={ImageEntity.Events}
        imageType={ImageType.Gallery}
        onStarClick={setFavoriteImage}
        favoriteImage={favoriteImage ?? ''}
      />
    ),
    [eventTabKeys.roles]: <SocialRoles {...needsProps} />,
    [eventTabKeys.contacts]: (
      <ContactsForm initialValues={contacts} withLegalAddress isSiteRequired={false} isLegalAddressRequired={false} />
    ),
    [eventTabKeys.review]: <OurReview initialValue={ourReview} />,
    [eventTabKeys.relatedPlacesAndEvents]: (
      <EventRelatedPlacesAndEvents
        place={baseInfo.place && eventRelatedCardMapper(baseInfo.place)}
        parentCard={baseInfo.parent && eventRelatedCardMapper(baseInfo.parent)}
        address={baseInfo.address}
      >
        {children ? mappedChildrenEvents(children) : []}
      </EventRelatedPlacesAndEvents>
    ),
  };
  if (baseInfo.status === 'global') {
    delete tabContent[eventTabKeys.datesAndTickets];
  }
  const filteredTabs = tabs.filter((item) => {
    if (baseInfo.status === 'global') {
      return item.key !== String(eventTabKeys.datesAndTickets);
    }
    return item;
  });

  const onFormFinish: FormProviderProps['onFormFinish'] = async (_, { forms }) => {
    const isCancelled = searchParams.get('cancelled');
    const status = searchParams.get('status');
    const errorMessage = () => notification.error({ message: 'Необходимо заполнить все поля корректно' });

    try {
      const validation = await Promise.allSettled(Object.values(forms).map((f) => f.validateFields()));

      const hasInvalidValues = validation.find((v) => v.status === 'rejected');
      if (!hasInvalidValues) {
        // TODO: Если передать на бэк isCanceled false, то статус события не изменится.
        // eslint-disable-next-line  @typescript-eslint/no-explicit-any
        const data: any = {
          needs: needsProps.needs,
          favoriteImage: favoriteImage,
          isCanceled: isCancelled !== 'false' ? isCancelled : undefined,
          periodicity: id !== 'new' ? baseInfo.status : status,
        };

        Object.entries(forms).forEach(([name, form]) => {
          if (name === 'schedule') {
            const schedule = form.getFieldsValue();
            data.days = schedule.days;
            data.aroundTheClock = schedule.aroundTheClock;
            data.noSchedule = schedule.noSchedule;
          } else {
            Object.assign(data, form.getFieldsValue());
          }
        });

        onSubmit(data);
      } else {
        showFormErrorMessages(validation);
      }
    } catch (error) {
      errorMessage();
    }
  };

  const formHeaderTitle = useMemo(() => {
    if (baseInfo.status === 'global') {
      return 'Новое глобальное событие';
    }
    if (baseInfo.status === 'once') {
      return 'Новое разовое событие';
    }
    if (baseInfo.status === 'regular') {
      return 'Новое регулярное событие';
    }
    return 'Новое событие';
  }, [baseInfo]);
  return (
    <Layout>
      <Form.Provider onFormFinish={onFormFinish}>
        <div>
          <EntityHeader
            title={id !== 'new' ? baseInfo.shortTitle : formHeaderTitle}
            onArchiveButtonClick={isNaN(Number(id)) ? undefined : onArchive}
          />
          <TabsWithButtons
            filteredTabs={filteredTabs}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            onLeftButtonClick={goToPrevTab}
            onRightButtonClick={goToNextTab}
          />
          <Content
            className={`mt-40 p-16 rounded-16 ${activeTab !== eventTabKeys.relatedPlacesAndEvents ? 'bg-neutral' : ''}`}
          >
            {filteredTabs.map(({ key, label, formHeaderType, contentClassName }) => {
              return (
                <div className={contentClassName} key={key}>
                  {activeTab !== eventTabKeys.relatedPlacesAndEvents ? (
                    <FormHeader title={label} type={formHeaderType} />
                  ) : (
                    <div className="p-16 rounded-16 bg-neutral">
                      <Content className="p-16 pl-40">
                        <Typography.Title className="m-0" level={3}>
                          {label}
                        </Typography.Title>
                      </Content>
                    </div>
                  )}
                  <div>{tabContent[key]}</div>
                </div>
              );
            })}
          </Content>
          {/*<Content className="mt-40 p-16 rounded-16 bg-neutral">
            {filteredTabs.map(({ key, label, formHeaderType, contentClassName }) => {
              return (
                <div className={contentClassName} key={key}>
                  <FormHeader title={label} type={formHeaderType} />
                  <div>{tabContent[key]}</div>
                </div>
              );
            })}
          </Content>*/}
        </div>
      </Form.Provider>
    </Layout>
  );
};
