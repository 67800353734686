import { axios } from 'client';
import { useCallback, useContext, useState } from 'react';
import { useQuery } from 'react-query';
import { Index } from '../client/endpoints';
import { UserContext } from '../contexts/UserContext';
import { AdminCreateRoleDataResponseDto, Need } from '../models';
import { useDebounce } from './useDebounce';
import { PaginationParams } from './useSocialNeeds';

export type Role = AdminCreateRoleDataResponseDto['data'];
export type ClientRole = {
  name: Role['name'];
  id: Role['id'];
  needs?: Array<Pick<Need, 'name' | 'id'>>;
  priority: Role['priority'];
};
export type ChangeClientRole = Omit<ClientRole, 'priority'>;

type RoleEntity = {
  roles: ClientRole[];
  addRole: (role: Role) => void;
  removeRole: (roleId: number) => void;
  changeRole: (role: ChangeClientRole) => void;
  totalCount: number;
  updatePaginationInfo: (data: PaginationParams) => void;
  updateSearchQuery: (query: string) => void;
  isLoading: boolean;
};

export const useSocialRolesList = (): RoleEntity => {
  const [paginationData, setPaginationData] = useState<PaginationParams>({
    limit: 10,
    offset: 0,
  });
  const [searchQuery, setSearchQuery] = useState('');
  const debouncedQuery = useDebounce(searchQuery, 500);
  const isAuthorized = typeof useContext(UserContext).user !== 'undefined';
  const searchQueryParam = debouncedQuery.length >= 1 ? `&query=${debouncedQuery}` : '';

  const fetchRoles = async () => {
    const { data } = await axios.get(
      Index.roles + `?limit=${paginationData.limit}&offset=${paginationData.offset}${searchQueryParam}`,
    );
    return data;
  };

  const { data, refetch, isLoading } = useQuery(['roles', paginationData, debouncedQuery], fetchRoles, {
    enabled: isAuthorized,
  });

  const roles = data?.data || [];
  const totalCount = data?.meta.total || 0;

  const updatePaginationInfo = useCallback(({ limit, offset }: PaginationParams) => {
    setPaginationData({ limit: limit, offset: offset });
  }, []);

  const updateSearchQuery = (query: string) => {
    setSearchQuery(query);
  };

  const addRole = (role: Role) => {
    roles.push(role);
    refetch();
  };

  const changeRole = (role: ChangeClientRole) => {
    const index = roles.findIndex((r: Role) => r.id === role.id);
    if (index !== -1) {
      roles[index] = role;
      refetch();
    }
  };

  const removeRole = (roleId: Role['id']) => {
    const index = roles.findIndex((r: Role) => r.id === roleId);
    if (index !== -1) {
      roles.splice(index, 1);
      refetch();
    }
  };

  return {
    addRole,
    removeRole,
    changeRole,
    roles,
    updatePaginationInfo,
    totalCount,
    updateSearchQuery,
    isLoading,
  };
};
