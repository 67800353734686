// eslint-disable-next-line
function getErrors(obj: any) {
  // eslint-disable-next-line
  const errors: any[] = [];

  // eslint-disable-next-line
  function traverse(obj: any) {
    if (Array.isArray(obj)) {
      obj.forEach((item) => traverse(item));
    } else if (typeof obj === 'object') {
      if (obj.errors && obj.errors.length > 0) {
        errors.push(...obj.errors);
      }
      Object.values(obj).forEach((value) => traverse(value));
    }
  }

  traverse(obj);

  return errors;
}

// eslint-disable-next-line
export const getFormErrorMessages = (validation: any) => {
  const errors = validation
    // eslint-disable-next-line
    .filter((v: any) => v.status === 'rejected')
    // eslint-disable-next-line
    .map((error: any) => {
      return error?.reason?.errorFields;
    });

  return getErrors(errors);
};
