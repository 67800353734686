import { notification } from 'antd';
import useAxios from 'axios-hooks';
import {
  createPushEndpointUrl,
  deletePushEndpointUrl,
  getPushByIdEndpointUrl,
  updatePushEndpointUrl,
} from 'client/endpoints/pushes';
import { defaultPushItem } from 'pages/Push/constants';
import { preparePushForBackend } from 'pages/Push/mappers';
import { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AdminAuthorResponseDto } from '../models';
import { NAVIGATION } from '../paths';

type Props = {
  isCreationMode: boolean;
  id?: string;
};

export const usePushesBackendActions = ({ isCreationMode, id }: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const [{ data, error, loading }] = useAxios<BaseResponse<AdminAuthorResponseDto>>(getPushByIdEndpointUrl(id), {
    manual: isCreationMode,
  });

  const deleteNew = useAxios<BaseResponse<AdminAuthorResponseDto>>(deletePushEndpointUrl(id), { manual: true })[1];

  const updatePushData = useAxios(updatePushEndpointUrl(id), { manual: true })[1];

  const savePushData = useAxios(createPushEndpointUrl(), { manual: true })[1];

  const pushData = useMemo(() => {
    if (isCreationMode) {
      return defaultPushItem;
    } else if (data !== undefined) {
      // eslint-disable-next-line
      return data?.data as any;
    }
  }, [isCreationMode, data]);

  const handleDeleteNew = useCallback(async () => {
    setIsLoading(true);
    try {
      await deleteNew();
      notification.success({
        message: `Пуш "${pushData?.name}" удален`,
      });
      navigate(NAVIGATION.authors);
    } catch (error) {
      notification.error({ message: `Извините, произошла ошибка` });
      setIsLoading(false);
    }
  }, [deleteNew, navigate, pushData?.name]);

  const onSave = useCallback(
    // eslint-disable-next-line
    async (formFields: any) => {
      const pushForSave = preparePushForBackend(formFields);
      try {
        setIsLoading(true);
        const query = isCreationMode ? savePushData : updatePushData;

        await query({ data: pushForSave });

        notification.success({ message: isCreationMode ? 'Пуш создан' : 'Пуш обновлен' });
        navigate(NAVIGATION.pushes);
        setIsLoading(false);
      } catch (error) {
        notification.error({ message: 'Извините, при сохранении пуша произошла ошибка' });
        setIsLoading(false);
      }
    },
    [isCreationMode, navigate, savePushData, updatePushData],
  );

  return useMemo(
    () => ({
      info: {
        data,
        error,
        loading,
        isLoading,
        pushData,
      },
      actions: {
        handleDeleteNew: handleDeleteNew,
        onSave,
      },
    }),
    [data, error, handleDeleteNew, isLoading, loading, pushData, onSave],
  );
};
