import { FrontendVideoItem } from './types';
import { mapCities } from '../Author/mappers';

export const getVideoData = (): FrontendVideoItem => ({
  id: NaN,
  category: null,
  title: '',
  announce: '',
  sourceUrl: null,
  body: '',
  isFavorite: false,
  createdAt: '',
  isHidden: false,
  // @ts-ignore: temp
  roles: null,
  areaId: NaN,
  isPinned: false,
  priority: 1,
  author: null,
});

export const mapVideoForFrontend = (videoData: FrontendVideoItem) => {
  return {
    ...videoData,
    rolesIds: mapCities(videoData.roles),
    areasIds: videoData.areas.map((item) => item.id),
    areasNames: mapCities(videoData.areas),
    authorId: videoData.author?.name,
  };
};

// eslint-disable-next-line
export const mapBaseVideoInfoForBackend = (videoFields: any) => {
  const { isHidden, priority, quality, authorId } = videoFields;

  return {
    isHidden,
    priority,
    quality,
    authorId: authorId ? authorId.value : undefined,
  };
};

// eslint-disable-next-line
export const mapRolesVideoInfoForBackend = (videoFields: any) => {
  const { rolesIds } = videoFields;

  return {
    // eslint-disable-next-line
    rolesIds: rolesIds.map((item: any) => item.key),
  };
};

// eslint-disable-next-line
export const mapAreasVideoInfoForBackend = (videoFields: any) => {
  const { areasIds } = videoFields;

  return {
    // @ts-ignore: temp
    areasIds: areasIds.some((item) => item.label) ? areasIds.map((item) => item.key) : areasIds,
  };
};
