import { Button, Form, Switch } from 'antd';
import { FormHeader } from 'components/FormHeader';
import React, { useState } from 'react';
import styles from './styles.module.css';
import { StyledInput } from '../StyledInputs/StyledInput';

type AddNewSocialFormProps = {
  onSave: (name: string, link: string) => void;
};

export const AddNewSocialForm: React.FC<AddNewSocialFormProps> = ({ onSave }) => {
  const [showNewPlatform, setShowNewPlatform] = useState(false);
  const [name, setName] = useState('');
  const [link, setLink] = useState('');

  const handleNewPlatformButtonClick = () => {
    setShowNewPlatform(true);
  };

  const clearValues = () => {
    setName('');
    setLink('');
  };

  const onSaveButtonClick = async () => {
    if (name.length > 0 && link.length > 0) {
      onSave(name, link);
      setShowNewPlatform(false);
      clearValues();
    }
  };

  return (
    <>
      {showNewPlatform ? (
        <div className="rounded-8 bg-gray-9 ph-16 pv-8">
          <Form.Item
            {...layout}
            label="Название"
            rules={[{ required: true, min: 1 }]}
            wrapperCol={{ className: 'bg-gray-9 pv-8 ph-16 rounded-8' }}
          >
            <StyledInput value={name} onChange={(e) => setName(e.target.value)} />
          </Form.Item>
          <Form.Item {...layout} label="Ссылка на платформу (Канал)" rules={[{ required: true, min: 1 }]}>
            <StyledInput value={link} onChange={(e) => setLink(e.target.value)} />
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 8 }}>
            <Button className="mr-8" type="primary" size="small" onClick={onSaveButtonClick}>
              Сохранить
            </Button>
          </Form.Item>
        </div>
      ) : (
        <Form.Item wrapperCol={{ offset: 8 }}>
          <Button type="primary" onClick={handleNewPlatformButtonClick}>
            Добавить стороннюю
          </Button>
        </Form.Item>
      )}
    </>
  );
};

export type TSocialRole = { name: string; url?: string };

type SocialFormProps = {
  socials?: TSocialRole[];
};

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

export const SocialForm: React.FC<SocialFormProps> = ({ socials }) => {
  const [socialForm] = Form.useForm();

  return (
    <Form form={socialForm} name="social-form">
      <FormHeader level={4} title="Социальные сети" className="mb-24" />
      <Form.List name="socials" initialValue={socials}>
        {(fields, { add }) => {
          return (
            <div>
              {fields.map((field) => {
                return (
                  <div key={field.key}>
                    <Form.Item shouldUpdate className="m-0">
                      {(form) => {
                        const item = form.getFieldsValue()?.socials?.[field.key] || {};
                        const label = item.name;
                        const value = item.url;
                        const hasInputValue = value !== undefined;

                        const handleSwithChange = (checked: boolean) => {
                          const socials = form.getFieldsValue()?.socials;
                          Object.assign(socials[field.key], {
                            name: label,
                            url: checked ? '' : undefined,
                          });

                          form.setFieldsValue({ socials });
                        };

                        return (
                          <Form.Item
                            className="m-0"
                            {...layout}
                            label={label}
                            wrapperCol={{ className: `bg-gray-9 pv-8 ph-16 rounded-8 ${styles.itemContainer}` }}
                          >
                            <div>
                              <div className="mb-16 mt-4 d-flex ph-16">
                                <Switch checked={hasInputValue} onChange={handleSwithChange} />{' '}
                                <p className="text-gray-8 m-reset ml-16">Ведётся</p>
                              </div>
                              {hasInputValue && (
                                <Form.Item
                                  rules={[
                                    {
                                      type: 'url',
                                      required: true,
                                      message: 'Введите коректный url',
                                    },
                                  ]}
                                  name={[field.name, 'url']}
                                  labelCol={{ span: 24 }}
                                  wrapperCol={{ span: 24 }}
                                  label="Ссылка"
                                  className={styles.url}
                                >
                                  <StyledInput />
                                </Form.Item>
                              )}
                            </div>
                          </Form.Item>
                        );
                      }}
                    </Form.Item>
                  </div>
                );
              })}
              <AddNewSocialForm onSave={(name, url) => add({ name, url })} />
            </div>
          );
        }}
      </Form.List>
    </Form>
  );
};
