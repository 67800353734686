import { Form, Layout, notification } from 'antd';
import { RcFile } from 'antd/es/upload';
import { FormProviderProps } from 'antd/lib/form/context';
import { Content } from 'antd/lib/layout/layout';
import { AuthorBaseInfoForm } from 'components/AuthorBaseInfoForm';
import { AuthorBindings } from 'components/AuthorBindings';
import { baseBindingMapper, bindingVideosMapper } from 'pages/Author/mappers';
import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { getTextEditorValidationError } from 'utils/getTextEditorValidationError';
import { AuthorRoles } from '../../components/AuthorRoles';
import { EntityHeader } from '../../components/EntityHeader';
import { FormHeader } from '../../components/FormHeader';
import { TabsWithButtons } from '../../components/TabsWithButtons/TabsWithButtons';
import { AUTHOR_DESCRIPTION_MAX_LENGTH } from '../../constants';
import { authorsTabKeys, authorsTabs, TAB_PREFIX, useTabs } from '../../hooks/useTabs';
import { showFormErrorMessages } from '../../utils/showFormErrorMessages';
import { AuthorImage } from './AuthorImage';
import { FrontendAuthorItem } from './types';

type Props = {
  newData: FrontendAuthorItem;
  onDeleteButtonClick: () => void;
  onSave: (newDataForSave: FrontendAuthorItem, file?: string | Blob | RcFile) => void;
  isCreationMode?: boolean;
};

export const AuthorContent: React.FC<Props> = ({ newData, onSave, onDeleteButtonClick, isCreationMode }) => {
  const [searchParams] = useSearchParams();
  const initialTab = searchParams.get(TAB_PREFIX)?.toString();
  const { activeTab, tabs, setActiveTab, goToNextTab, goToPrevTab } = useTabs(authorsTabs, initialTab);
  const [imageFile, setImageFile] = useState<string | Blob | RcFile | null>(null);

  const newInitialValues = { ...newData };

  const tabContent = {
    [authorsTabKeys.common]: <AuthorBaseInfoForm initialValues={newInitialValues} />,
    [authorsTabKeys.gallery]: (
      <AuthorImage
        image={newData.image}
        newId={newData.id}
        isCreationMode={isCreationMode}
        setImageFile={setImageFile}
      />
    ),
    [authorsTabKeys.roles]: <AuthorRoles initialValues={{ socialRoles: newData.roles ?? undefined }} />,
    [authorsTabKeys.bindings]: (
      <AuthorBindings
        id={newData.id}
        initialValues={{
          news: baseBindingMapper(newData.news),
          events: baseBindingMapper(newData.events),
          videos: bindingVideosMapper(newData.videos),
          services: baseBindingMapper(newData.services),
          recommendedEvents: baseBindingMapper(newData.recommendedEvents),
          places: baseBindingMapper(newData.places),
        }}
      />
    ),
  };

  const onFormFinish: FormProviderProps['onFormFinish'] = async (_, { forms }) => {
    const errorMessage = () => notification.error({ message: 'Необходимо заполнить все поля корректно' });
    try {
      const validation = await Promise.allSettled(Object.values(forms).map((f) => f.validateFields()));
      const descriptionTextLengthError = getTextEditorValidationError(forms, AUTHOR_DESCRIPTION_MAX_LENGTH);

      const hasInvalidValues = validation.find((v) => v.status === 'rejected') || descriptionTextLengthError;

      // @ts-ignore: later
      const socialRoles = validation.find((v) => v?.value?.socialRoles);

      // @ts-ignore: later
      if (socialRoles && socialRoles?.value?.socialRoles?.length === 0)
        return notification.error({
          message: 'Не все поля заполнены корректно:',
          description: (
            <>
              • Связанные роли
              <br />
            </>
          ),
        });

      if (!hasInvalidValues) {
        const data = {};
        Object.entries(forms).forEach(([_, form]) => {
          Object.assign(data, form.getFieldsValue());
        });
        onSave(data as FrontendAuthorItem, imageFile as string | Blob | RcFile);
      } else {
        showFormErrorMessages(
          validation,
          descriptionTextLengthError
            ? `Описание должно быть менее ${AUTHOR_DESCRIPTION_MAX_LENGTH} символов`
            : undefined,
        );
      }
    } catch (error) {
      errorMessage();
    }
  };

  return (
    <Form.Provider onFormFinish={onFormFinish}>
      <Layout>
        <EntityHeader
          title={newData.name || 'Создать Автора'}
          onArchiveButtonClick={!isCreationMode ? onDeleteButtonClick : undefined}
        />
        <TabsWithButtons
          filteredTabs={tabs}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          onLeftButtonClick={goToPrevTab}
          onRightButtonClick={goToNextTab}
        />
        <Content className="mt-40 p-16 rounded-16 bg-neutral">
          {tabs.map(({ key, label, contentClassName, formHeaderType }) => {
            return (
              <div key={key} className={contentClassName}>
                <FormHeader title={label} type={formHeaderType} />
                <div>{tabContent[key]}</div>
              </div>
            );
          })}
        </Content>
      </Layout>
    </Form.Provider>
  );
};
