import { Form, Select } from 'antd';
import React, { FC } from 'react';
import { FieldProps } from 'rc-field-form/lib/Field';
import { StyledSelectComponent } from '../StyledSelect';

const { Option } = Select;

type AgeFormItemProps = {
  formItemProps: FieldProps;
  label?: string;
};

const ageGroups = [
  { label: '0+', id: 0 },
  { label: '3+', id: 3 },
  { label: '6+', id: 6 },
  { label: '12+', id: 12 },
  { label: '16+', id: 16 },
  { label: '18+', id: 18 },
];

export const AgeFormItem: FC<AgeFormItemProps> = ({ formItemProps, label = 'Возрастная группа' }) => {
  return (
    <Form.Item {...formItemProps} label={label}>
      <StyledSelectComponent>
        {ageGroups.map((item) => {
          return (
            <Option key={item.id} value={item.id}>
              {item.label}
            </Option>
          );
        })}
      </StyledSelectComponent>
    </Form.Item>
  );
};
