import { ServiceImage } from '../../models';

// Функция, получающая на вход image и возвращает отмапленную версию либо undefined.
export const getLastUploadedImageData = (
  image: Partial<ServiceImage> | undefined,
): { src: string; id: string } | undefined => {
  if (image === undefined || image.id === undefined || image.fullUrl === undefined) {
    return undefined;
  } else {
    return {
      src: image.fullUrl,
      id: image.id.toString(),
    };
  }
};
