import { Input, InputProps } from 'antd';
import React from 'react';
import styles from './styles.module.css';
import { TextAreaProps } from 'antd/lib/input/TextArea';

export const StyledInput: React.FC<InputProps> & { TextArea: React.FC<TextAreaProps> } = (props) => {
  return <Input className={styles.input} {...props} />;
};

StyledInput.TextArea = (props: TextAreaProps) => {
  return <Input.TextArea className={styles.input} {...props} />;
};
