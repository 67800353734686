import { axios } from 'client';
import { Index } from 'client/endpoints';
import { UserContext } from 'contexts/UserContext';
import { TagDto } from 'models';
import { useContext } from 'react';
import { useQuery } from 'react-query';

export const useEventTags = () => {
  const isAuthorized = typeof useContext(UserContext).user !== 'undefined';

  const fetchData = async () => {
    if (!isAuthorized) return;
    const { data } = await axios.get(`${Index.adminEvents}/tags?limit=20`);
    return data.data;
  };

  return useQuery<TagDto[]>(['getEventTags', isAuthorized], fetchData, { initialData: [] });
};
