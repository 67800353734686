import React from 'react';
import { Button, Spin } from 'antd';
import { DeleteOutlined, StarFilled, StarOutlined } from '@ant-design/icons';
import styles from './styles.module.css';

type Props = {
  url?: string;
  thumbUrl?: string;
  favoriteImage?: string;
  initialFavoriteImage?: React.MutableRefObject<string | undefined>;
  name: string;
  remove: () => void;
  onStarClick?: (imageUrl: string) => void;
};

export const GalleryImage: React.FC<Props> = ({
  url,
  thumbUrl,
  favoriteImage,
  initialFavoriteImage,
  name,
  remove,
  onStarClick,
}) => {
  const finalUrl = url || thumbUrl || '';
  const isFavoriteImage = favoriteImage === finalUrl;
  const isRemoveButtonDisabledForFavouriteImage = initialFavoriteImage?.current === finalUrl;

  return (
    <div className="d-flex flex-column">
      <div className={`preview-image-wrapper relative`}>
        {!finalUrl && (
          <div className={styles.loaderContainer}>
            <Spin />
          </div>
        )}
        <img
          src={finalUrl}
          alt={name}
          onError={(e) => {
            e.currentTarget.src = finalUrl;
          }}
        />
      </div>
      <div className="mb-4 w-150px text-overflow" title={name}>
        {name}
      </div>
      <div className="d-flex gap-4">
        <Button
          size="small"
          onClick={remove}
          className="flex-grow-1"
          disabled={isRemoveButtonDisabledForFavouriteImage || !finalUrl}
        >
          <DeleteOutlined />
        </Button>
        {onStarClick && (
          <Button
            size="small"
            className="flex-grow-1"
            onClick={() => onStarClick(finalUrl === favoriteImage ? '' : finalUrl)}
            disabled={!finalUrl}
          >
            {isFavoriteImage ? <StarFilled /> : <StarOutlined />}
          </Button>
        )}
      </div>
    </div>
  );
};
