/**
 * Функция возвращает номер телефона без символов маски
 */
export const toPhoneWithoutMask = (phone?: string): string => {
  if (phone === undefined) {
    return '';
  }

  return phone.replace(/[^0-9]/g, '');
};
