import React, { FC, useState } from 'react';
import { DebouncedSelect } from 'components/DebouncedSelect';
import useAsyncSelectOptions from 'hooks/useAsyncSelectOptions';
import { DADATA_BASE_SEARCH_URL } from 'utils';
import { Form } from 'antd';
import { AddressSuggestionResponse } from 'models';
import { CitySelect } from '../CitySelect';

type Props = {
  enableCitySelect?: boolean;
};

export const DadataSelect: FC<Props> = ({ enableCitySelect = true }) => {
  const form = Form.useFormInstance();
  const mapDadataResponse = (data: AddressSuggestionResponse[]) => {
    return data.map(({ value }) => {
      return { label: value, value, key: value };
    });
  };

  const [selectedCityName, setSelectedCityName] = useState(form.getFieldValue('areaName'));
  const [resetOptions, setResetOptions] = useState(false);

  const onCitySelect = () => {
    form.setFieldValue('address', '');
    setSelectedCityName(form.getFieldValue('areaName'));
    setResetOptions(true);
    setTimeout(() => setResetOptions(false), 500);
  };

  const { fetcher } = useAsyncSelectOptions(`${DADATA_BASE_SEARCH_URL}${selectedCityName ?? ''} `, mapDadataResponse);

  return (
    <>
      {enableCitySelect && <CitySelect onCitySelect={onCitySelect} isMultiple={false} />}
      <Form.Item rules={[{ min: 5, required: true }]} name="address" label="Адрес">
        <DebouncedSelect
          fetcher={fetcher}
          disabled={!selectedCityName && enableCitySelect}
          resetOptions={resetOptions}
        />
      </Form.Item>
    </>
  );
};
