import { Form } from 'antd';
import { FrontendVideoItem } from 'pages/Video/types';
import React from 'react';
import { DebouncedSelect } from '../DebouncedSelect';
import { useSocialRolesList } from '../../hooks/useSocialRolesList';
import useAsyncSelectOptions from '../../hooks/useAsyncSelectOptions';
import { RolesResponseDto } from '../../models';
import { BASE_ROLES_SEARCH_URL } from '../../utils';
import { baseAuthorBindingMapper, mapCities, rolesMapper } from '../../pages/Author/mappers';
import { useCitiesEntity } from '../../hooks/useCitiesEntity';
import { getCitiesEndpointWithSearchUrl } from '../../client/endpoints/cities';
import { useWatch } from 'antd/es/form/Form';
import { BASE_AUTHORS_SEARCH_URL } from '../../utils/constants';
import { useAuthors } from '../../hooks/useAuthors';

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

type TPlaceBaseInfoFormProps = { initialValues: FrontendVideoItem };
export const VideoConnectionsForm: React.FC<TPlaceBaseInfoFormProps> = ({ initialValues }) => {
  const [form] = Form.useForm();

  const { roles } = useSocialRolesList();

  const { fetcher: rolesFetcher } = useAsyncSelectOptions<RolesResponseDto>(BASE_ROLES_SEARCH_URL, rolesMapper);
  const mappedRoles = rolesMapper(roles);

  const { data: authors } = useAuthors();

  const { fetcher: authorsFetcher } = useAsyncSelectOptions<RolesResponseDto>(
    BASE_AUTHORS_SEARCH_URL,
    baseAuthorBindingMapper,
  );
  const mappedAuthors = baseAuthorBindingMapper(authors);

  const { data: cities } = useCitiesEntity();

  const mappedCities = mapCities(cities);

  const { fetcher: citiesFetcher } = useAsyncSelectOptions(getCitiesEndpointWithSearchUrl(), mapCities);

  const selectedAreas = useWatch('areasIds', form);

  return (
    <Form form={form} initialValues={initialValues} {...layout} name="connections">
      <Form.Item name="areasNames" label="Город" rules={[{ required: true }]}>
        <DebouncedSelect
          fetcher={citiesFetcher}
          mode="multiple"
          initialOptions={mappedCities}
          onSelect={(_, option) => {
            form.setFieldValue('areasIds', [...selectedAreas, option.key]);
          }}
          onDeselect={(option) => {
            // @ts-ignore: temp
            const foundItem = initialValues?.areasNames?.find((item) => item.label === option);

            if (foundItem)
              form.setFieldValue(
                'areasIds',
                // @ts-ignore: temp
                selectedAreas.filter((item) => item !== foundItem.key),
              );
          }}
        />
      </Form.Item>
      <Form.Item name="areasIds" label="Города" style={{ display: 'none' }} />
      <Form.Item name="rolesIds" label="Связанные роли" rules={[{ required: true }]}>
        <DebouncedSelect fetcher={rolesFetcher} initialOptions={mappedRoles} labelInValue mode="multiple" />
      </Form.Item>
      <Form.Item name="authorId" label="Связанный автор">
        <DebouncedSelect fetcher={authorsFetcher} initialOptions={mappedAuthors} labelInValue allowClear />
      </Form.Item>
    </Form>
  );
};
