import React from 'react';
import { Pagination, Typography } from 'antd';
import { FormHeader } from 'components/FormHeader';
import { useListing } from 'hooks';
import { Index } from 'client/endpoints';
import { ListCard } from 'components/ListCard';
import { NAVIGATION } from 'paths';
import { useNavigate } from 'react-router-dom';
import { Place } from 'models';
import { placeTabKeys, TAB_PREFIX } from 'hooks/useTabs';

const initialPagination = { current: 1, pageSize: 4 };

export const RelatedPlaces: React.FC<{ id: number }> = ({ id }) => {
  const push = useNavigate();
  const { data, paginationProps } = useListing<Place>(`${Index.places}/${id}/linked?`, initialPagination);

  return (
    <div>
      <FormHeader
        title={
          <Typography.Title className="m-0 d-flex flex-items-end" level={3}>
            Размещеннные места
          </Typography.Title>
        }
        type="fullwidth"
      />
      <div className="d-flex gap-8">
        {data?.data.length === 0 ? (
          <div className="d-flex flex-justify-center ">Отсутствуют связанные места</div>
        ) : null}
        {data?.data.map(({ id, image, type, title }) => (
          <ListCard
            key={id}
            title={title}
            imageUrl={image}
            footerDescription={type}
            onClick={() => push(`${NAVIGATION.places}/${id}?${TAB_PREFIX}=${placeTabKeys.connections}`)}
          />
        ))}
      </div>
      {data?.meta.total ? (
        <div className="d-flex flex-justify-end">
          <Pagination {...paginationProps} total={data.meta.total} defaultCurrent={paginationProps.current} />
        </div>
      ) : null}
    </div>
  );
};
