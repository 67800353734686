import React, { useState } from 'react';
import { Form, Radio } from 'antd';
import { AsyncParentPlaceSelect } from 'components/AsyncPlaceSelect';
import { DadataSelect } from 'components/DadataSelect';
import { PlaceFrontend } from 'pages/Place/mappers';

const radioValues = {
  address: 'address',
  place: 'place',
} as const;

const radioButtons = [
  { value: radioValues.address, title: 'Адрес' },
  { value: radioValues.place, title: 'Место' },
];

type Tab = (typeof radioValues)[keyof typeof radioValues];

type PlaceSwitcherProps = {
  initialTab: Tab;
  initialParent: PlaceFrontend['parent'];
  selectFormItemName?: string;
  enableCitySelect?: boolean;
  currentPlaceId?: number;
};

export const PlaceSwitcher: React.FC<PlaceSwitcherProps> = ({
  initialTab,
  initialParent,
  selectFormItemName = 'parent',
  enableCitySelect = true,
  currentPlaceId,
}) => {
  const [radio, setRadio] = useState<Tab>(initialTab);
  return (
    <>
      <Form.Item label="Расположение">
        <Radio.Group value={radio} className="d-flex flex-grow-1" onChange={(e) => setRadio(e.target.value)}>
          {radioButtons.map(({ value, title }) => (
            <Radio.Button key={value} className="flex-grow-1 text-align-center" value={value}>
              {title}
            </Radio.Button>
          ))}
        </Radio.Group>
      </Form.Item>
      {radio === radioValues.address ? (
        <DadataSelect enableCitySelect={enableCitySelect} />
      ) : (
        <AsyncParentPlaceSelect
          initialParent={initialParent}
          formItemName={selectFormItemName}
          currentPlaceId={currentPlaceId}
        />
      )}
    </>
  );
};
