import { Layout, Table, TableProps } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import { useFeedback } from 'hooks/useFeedback';
import React, { useMemo } from 'react';
import { formatDateToFull } from 'utils/dateHandlers/formatDateToFull';
import { PageTitle } from '../../components/PageTitle';
import { AdminGetListFeedbacksResponseDto } from '../../models';

export const Feedback = () => {
  const { feedbacks, updatePaginationInfo, updateSortInfo, totalCount } = useFeedback();

  const columns = useMemo(
    () => [
      {
        title: 'Имя автора',
        dataIndex: 'name',
      },
      {
        title: 'Обратная связь',
        dataIndex: 'body',
      },
      {
        title: 'Дата',
        dataIndex: 'date',
        sorter: true,
        render: (date: string) => {
          return formatDateToFull(date);
        },
      },
      {
        title: 'Оценка',
        dataIndex: 'rating',
        align: 'right',
        sorter: true,
      },
    ],
    [],
  );

  // @ts-ignore: sorter мдаа
  const onChange: TableProps<AdminGetListFeedbacksResponseDto>['onChange'] = (
    { current, pageSize = 10 },
    _,
    sorter: { field: string; order?: string },
  ) => {
    updateSortInfo({
      field: sorter.field,
      order: sorter.order !== undefined ? (sorter.order === 'descend' ? 'DESC' : 'ASC') : undefined,
    });
    if (current !== undefined && pageSize !== undefined) {
      const offset = current === 1 ? '0' : (current - 1) * pageSize;
      updatePaginationInfo({ offset: +offset, limit: pageSize });
    }
  };

  return (
    <Layout>
      <PageTitle title="Обратная связь" wrapperClassName="mb-24" titleClassName={'mb-24'} />

      <Content>
        <Table
          pagination={{
            total: totalCount,
            pageSizeOptions: ['10', '20'],
            defaultPageSize: 10,
          }}
          // @ts-ignore: later
          columns={columns}
          dataSource={feedbacks}
          onChange={onChange}
        />
      </Content>
    </Layout>
  );
};
