import React from 'react';
import { StarFilled, StarOutlined } from '@ant-design/icons';

type StarsPros = {
  filledStars: number;
};

export const Stars: React.FC<StarsPros> = ({ filledStars }) => {
  return (
    <div className="d-flex gap-4">
      {new Array(5).fill(1).map((_, idx) => {
        const isFilled = filledStars >= idx + 1 ? true : false;

        return isFilled ? <StarFilled style={{ color: '#FAAD14' }} key={idx} /> : <StarOutlined key={idx} />;
      })}
    </div>
  );
};
