export const defaultPushItem = {
  id: NaN,
  body: '',
  date: null,
  entityId: null,
  entityType: null,
  rolesIds: [],
  time: null,
  title: null,
  areasIds: [],
};
