import React, { useMemo, useState } from 'react';
import { axios } from 'client';
import { Index } from 'client/endpoints';
import { PageTitle } from 'components/PageTitle';
import { Content } from 'antd/lib/layout/layout';
import { Button, Layout, List, Modal, ModalProps, notification, Pagination, PaginationProps, Tabs } from 'antd';
import { StyledInput } from '../../components/StyledInputs/StyledInput';
import { StyledSelectComponent } from '../../components/StyledSelect';
import { useListing } from '../../hooks';
import { TagDto } from '../../models';

type TTagCategory = 'formats' | 'thematics';

type NewTagModalProps = Omit<ModalProps, 'onOk'> & {
  onOk: (category: TTagCategory, tagName: string) => void;
};

const tagCategoryOptions: { value: TTagCategory; label: string }[] = [
  { value: 'formats', label: 'Форматы' },
  { value: 'thematics', label: 'Тематики' },
];

const NewTagModal: React.FC<NewTagModalProps> = ({ onOk, ...modalProps }) => {
  const [tagFor, setTagFor] = useState<Optional<TTagCategory>>();
  const [tagName, setTagName] = useState<string>('');

  const handleOkButtonClick = () => {
    if (tagFor) {
      onOk(tagFor, tagName);
    }
  };

  const isOnButtonDisabled = typeof tagFor === 'undefined' || tagName.trim().length === 0;

  return (
    <Modal
      {...modalProps}
      title="Добавить формат или тематику"
      onOk={handleOkButtonClick}
      okButtonProps={{ disabled: isOnButtonDisabled }}
    >
      <StyledSelectComponent
        value={tagFor}
        className="w-100"
        onChange={setTagFor}
        placeholder="Категория"
        options={tagCategoryOptions}
      />
      <StyledInput
        className="mt-24"
        value={tagName}
        placeholder="Название"
        onChange={(e) => setTagName(e.target.value)}
      />
    </Modal>
  );
};

export const FormatsAndThematics = () => {
  const [activeTab, setActiveTab] = useState<TTagCategory>('formats');
  const [isModalOpen, setIsModalOpen] = useState(false);

  const {
    data: thematicsData,
    paginationProps: thematicsPaginationProps,
    meta: thematicsMeta,
    refetch: refetchThematics,
  } = useListing<TagDto>(Index.adminEvents + '/info/thematics?');
  const thematics = thematicsData?.data;

  const {
    data: formatsData,
    paginationProps: formatsPaginationProps,
    meta: formatsMeta,
    refetch: refetchFormats,
  } = useListing<TagDto>(Index.adminEvents + '/info/formats?');
  const formats = formatsData?.data;

  const tagTabs: {
    tab: string;
    key: TTagCategory;
    paginationData: { props: PaginationProps; total?: number; defaultCurrent?: number };
  }[] = useMemo(
    () => [
      {
        tab: 'Форматы',
        key: 'formats',
        paginationData: {
          props: formatsPaginationProps,
          total: formatsMeta?.total,
          defaultCurrent: formatsPaginationProps.current,
        },
      },
      {
        tab: 'Тематики',
        key: 'thematics',
        paginationData: {
          props: thematicsPaginationProps,
          total: thematicsMeta?.total,
          defaultCurrent: thematicsPaginationProps.current,
        },
      },
    ],
    [formatsMeta?.total, formatsPaginationProps, thematicsMeta?.total, thematicsPaginationProps],
  );

  const getRefetchAndBaseUrl = (category: TTagCategory) => {
    switch (category) {
      case 'formats':
        return { baseUrl: Index.adminEvents + '/info/format', refetch: refetchFormats };
      case 'thematics':
        return { baseUrl: Index.adminEvents + '/info/thematic', refetch: refetchThematics };
      default:
        return {};
    }
  };

  const handleDeleteTag = async (category: TTagCategory, id: number) => {
    const { baseUrl, refetch } = getRefetchAndBaseUrl(category);

    try {
      await axios.delete(baseUrl + `/${id}`);
      notification.success({ message: `Запись удален` });
      refetch?.();
    } catch (error) {
      notification.error({ message: 'Ошибка при удалени записи' });
    } finally {
      setIsModalOpen(false);
    }
  };

  const handleSaveNewTag = async (category: TTagCategory, name: string) => {
    const { baseUrl, refetch } = getRefetchAndBaseUrl(category);

    try {
      await axios.post(baseUrl as string, { name });
      notification.success({ message: `Запись '${name}' сохранена` });
      refetch?.();
    } catch (error) {
      notification.error({ message: 'Ошибка при создании записи' });
    } finally {
      setIsModalOpen(false);
    }
  };

  const tagsData = useMemo(() => {
    if (activeTab === 'formats') {
      return formats;
    } else if (activeTab === 'thematics') {
      return thematics;
    } else {
      return [];
    }
  }, [activeTab, formats, thematics]);

  return (
    <div>
      <PageTitle
        title="Форматы и тематики"
        titleClassName="mb-24"
        wrapperClassName="mb-24"
        onAddButtonClick={() => setIsModalOpen(true)}
      />
      <Layout>
        <Content className="mt-40 p-16 rounded-16 bg-neutral">
          <Tabs onChange={setActiveTab as (activeKey: string) => void} activeKey={activeTab}>
            {tagTabs.map((t) => (
              <Tabs.TabPane {...t} key={t.key}>
                <List
                  itemLayout="horizontal"
                  dataSource={tagsData}
                  renderItem={(item) => (
                    <List.Item
                      actions={[
                        <Button onClick={() => handleDeleteTag(t.key, item.id)} type="link" danger key="delete">
                          Удалить
                        </Button>,
                      ]}
                    >
                      {item.name}
                    </List.Item>
                  )}
                />
                <Pagination
                  {...t.paginationData.props}
                  total={t.paginationData.total}
                  defaultCurrent={t.paginationData.defaultCurrent}
                />
              </Tabs.TabPane>
            ))}
          </Tabs>
        </Content>
      </Layout>
      <NewTagModal
        destroyOnClose
        open={isModalOpen}
        okText="Сохранить"
        onOk={handleSaveNewTag}
        onCancel={() => setIsModalOpen(false)}
      />
    </div>
  );
};
