import { useCallback, useState } from 'react';

export const useSocialRoles = (initialNeeds: number[]) => {
  const [needs, setNeeds] = useState<number[]>(initialNeeds);

  const onNeedClick = useCallback((needId: number) => {
    setNeeds((pV) => {
      const isNeedActive = pV.includes(needId);

      return isNeedActive ? pV.filter((n) => n !== needId) : [...pV, needId];
    });
  }, []);

  return { needs, onNeedClick };
};
