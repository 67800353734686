import { notification } from 'antd';

export type EventCreationQueryStringParams = {
  status: string;
  shortTitle: string;
  relatedGlobalEvent?: { id: number; title: string };
};
export const getEventCreationQueryStringValues = ({
  status,
  relatedGlobalEvent,
  shortTitle,
}: EventCreationQueryStringParams): string | boolean => {
  if (shortTitle.length < 1) {
    notification.error({ message: 'Пожалуйста, заполните название' });
    return false;
  }

  let baseSearchString = `?status=${status}&shortTitle=${shortTitle}`;

  if ((relatedGlobalEvent && status === 'once') || status === 'regular') {
    baseSearchString += `${relatedGlobalEvent?.title ? `&relatedGlobalEvent=${relatedGlobalEvent.title}` : ''}${
      relatedGlobalEvent?.id ? `&parentId=${relatedGlobalEvent?.id}` : ''
    }${relatedGlobalEvent?.title ? `&parentTitle=${relatedGlobalEvent?.title}` : ''}`;
    return baseSearchString;
  }
  return baseSearchString;
};
