export function formatPhoneNumber(phoneNumber: string) {
  // Check if the phone number starts with "+"
  let formattedNumber = phoneNumber.startsWith('+') ? '+' : '';

  // Remove all non-digit characters from the phone number
  const digitsOnly = phoneNumber.replace(/\D/g, '');

  // Append the remaining digits to the formatted number
  formattedNumber += digitsOnly;

  return formattedNumber;
}
