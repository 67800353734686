import React, { useContext } from 'react';
import logo from './logo.png';
import { Avatar, Divider, Layout, Menu, Typography } from 'antd';
import { UserContext } from 'contexts/UserContext';
import { Link, useLocation } from 'react-router-dom';
import { NAVIGATION } from 'paths';

import {
  AimOutlined,
  AppleOutlined,
  AppstoreAddOutlined,
  CalendarOutlined,
  FlagOutlined,
  HomeOutlined,
  ReadOutlined,
  TagsOutlined,
  UserOutlined,
  ProfileOutlined,
  MailOutlined,
  SyncOutlined,
  VideoCameraOutlined,
} from '@ant-design/icons';
import { LogoutButton } from '../LogoutButton';

const routes = [
  { name: 'Новости и статьи', to: NAVIGATION.news, icon: <ReadOutlined /> },
  { name: 'Сервисы', to: NAVIGATION.services, icon: <AppstoreAddOutlined /> },
  { name: 'Места', to: NAVIGATION.places, icon: <FlagOutlined /> },
  { name: 'События', to: NAVIGATION.events, icon: <CalendarOutlined /> },
  { name: 'Авторы и персоны', to: NAVIGATION.authors, icon: <UserOutlined /> },
  { name: 'Социальные роли', to: NAVIGATION.socialRoles, icon: <AimOutlined /> },
  { name: 'Потребности', to: NAVIGATION.needs, icon: <AppleOutlined /> },
  { name: 'Города', to: NAVIGATION.cities, icon: <HomeOutlined /> },
  { name: 'Теги', to: NAVIGATION.tags, icon: <TagsOutlined /> },
  { name: 'Форматы и Тематики', to: NAVIGATION.formatsAndThematics, icon: <ProfileOutlined /> },
  { name: 'Пуши', to: NAVIGATION.pushes, icon: <MailOutlined /> },
  { name: 'Обратная связь', to: NAVIGATION.feedback, icon: <SyncOutlined /> },
  { name: 'Видео', to: NAVIGATION.video, icon: <VideoCameraOutlined /> },
];

export const Header = () => {
  const { pathname } = useLocation();

  const { user, logout } = useContext(UserContext);

  const activeKey = routes.find(({ to }) => pathname.includes(to))?.to;

  return (
    <Layout className="bg-neutral pv-16 ph-24">
      <div className="d-flex flex-items-center">
        <div className="d-flex flex-items-center gap-8 user-select-none">
          <img src={logo} width="40px" height="40px" alt="logo" />
          <Typography.Text className="line-height-1" strong>
            Книжные Маяки
          </Typography.Text>
        </div>
        {user && (
          <div className="d-flex flex-grow-1">
            <Divider type="vertical" style={{ height: 32 }} />
            <Menu
              items={routes.map(({ to, name, icon }) => ({
                icon,
                key: to,
                label: <Link to={to}>{name}</Link>,
              }))}
              mode="horizontal"
              activeKey={activeKey}
              className="bg-neutral flex-grow-1 header-menu"
            />
            <div className="d-flex flex-items-center gap-8">
              <Avatar size={40} icon={<UserOutlined />} />
              <Typography.Text strong>{user.name}</Typography.Text>
              <LogoutButton onLogout={logout} />
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
};
