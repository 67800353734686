import React, { CSSProperties, useCallback, useState } from 'react';
import { PageTitle } from 'components/PageTitle';
import { NAVIGATION } from 'paths';
import { useNavigate } from 'react-router-dom';
import { Button, Form, InputProps, Layout, Pagination, Row, Spin } from 'antd';
import { ListCard } from 'components/ListCard';
import { Index } from 'client/endpoints';
import { Content } from 'antd/lib/layout/layout';
import { useDebounce, useListing } from 'hooks';
import { CloseOutlined, EnvironmentOutlined, FlagOutlined } from '@ant-design/icons';
import { NotFoundSearchResults } from '../../components/NotFoundSearchResults';
import { AdminPlaceListDto } from 'models';
import { PlaceTypeSelect } from 'components/PlaceTypeSelect';
import { StyledInput } from '../../components/StyledInputs/StyledInput';

const iconStyles: CSSProperties = { color: '#8C8C8C', marginRight: 6 };

export const Places = () => {
  const push = useNavigate();
  const [searchType, setSearchType] = useState<Optional<string>>(undefined);
  const [searchName, setSearchName] = useState('');
  const [searchAddress, setSearchAddress] = useState('');
  const debouncedSearchName = useDebounce(searchName, 1000);
  const debouncedSearchAddress = useDebounce(searchAddress, 1000);

  const handleSearchInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const searchName = e.target.value;
    setSearchName(searchName);
  }, []);

  const handleAddressInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const searchName = e.target.value;
    setSearchAddress(searchName);
  }, []);

  const { data, paginationProps, loading, error } = useListing<AdminPlaceListDto>(
    `${Index.places}?${debouncedSearchName.length > 2 ? `title=${debouncedSearchName}` : ''}${
      debouncedSearchAddress.length > 2 ? `&address=${debouncedSearchAddress}` : ''
    }${searchType ? `&type=${searchType}` : ''}`,
  );

  const searchInputs: (InputProps & { label: string })[] = [
    {
      value: searchName,
      label: 'Название места',
      onChange: handleSearchInputChange,
      prefix: <FlagOutlined style={iconStyles} />,
      placeholder: 'Введите название места (поиск от 3 символов)',
    },
    {
      label: 'Адрес места',
      value: searchAddress,
      onChange: handleAddressInputChange,
      prefix: <EnvironmentOutlined style={iconStyles} />,
      placeholder: 'Введите адрес места (поиск от 3 символов)',
    },
  ];

  const handleClearButtonClick = () => {
    setSearchName('');
    setSearchAddress('');
    setSearchType(undefined);
  };

  return (
    <Layout>
      <PageTitle title="Места" wrapperClassName="mb-24" onAddButtonClick={() => push(`${NAVIGATION.places}/new`)} />
      <Content>
        <div className="p-24 mt-40 bg-neutral mb-24 rounded-8">
          <Form className="d-flex gap-24" layout="vertical">
            {searchInputs.map(({ label, ...inputProps }) => (
              <Form.Item key={label} className="flex-grow-1 m-0" label={label}>
                <StyledInput {...inputProps} />
              </Form.Item>
            ))}
            <Form.Item label="Тип места" className="flex-grow-1 m-0">
              <PlaceTypeSelect
                showSearch
                value={searchType}
                onChange={setSearchType}
                placeholder="Выберите тип места"
              />
            </Form.Item>
          </Form>
          <div className="d-flex flex-justify-end mt-8">
            <Button onClick={handleClearButtonClick} danger type="link" icon={<CloseOutlined />}>
              Очистить всё
            </Button>
          </div>
        </div>
        <Row className="gap-16" justify={loading ? 'center' : undefined}>
          {data && data.data.length < 1 && !loading && <NotFoundSearchResults />}
          {loading ? (
            <Spin className="flex-justify-center" size="large" />
          ) : error ? (
            'Произошла ошибка загрузки мест'
          ) : data?.data === undefined ? (
            <div>Нет мест</div>
          ) : (
            data.data.map(({ id, type, title, image, address }) => (
              <ListCard
                key={id}
                title={title}
                imageUrl={image?.toString()}
                description={address}
                footerDescription={type?.toString()}
                onClick={() => push(`${NAVIGATION.places}/${id}`)}
              />
            ))
          )}
        </Row>
        {data?.meta.total ? (
          <div className="d-flex flex-justify-end">
            <Pagination
              {...paginationProps}
              total={data.meta.total}
              pageSizeOptions={[8, 12, 16, 20]}
              defaultCurrent={paginationProps.current}
            />
          </div>
        ) : null}
      </Content>
    </Layout>
  );
};
