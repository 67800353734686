import { Button, Form, notification } from 'antd';
import { useGetRoleReports } from 'hooks/useGetRoleReports';
import React, { FC } from 'react';
import { StyledInput } from '../StyledInputs/StyledInput';
import { StyledInputNumber } from '../StyledInputs/StyledInputNumber';
import { DownloadOutlined } from '@ant-design/icons';

export type SocialRoleBaseData = {
  title: string;
  priority: string;
  roleId?: string;
};

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
export const BaseSocialRoleForm: FC<SocialRoleBaseData> = ({ title, priority, roleId }) => {
  const [form] = Form.useForm<SocialRoleBaseData>();

  const { handleDownloadReports, isLoading } = useGetRoleReports(roleId);

  const downloadExcel = async () => {
    const link = await handleDownloadReports();
    if (link !== undefined) {
      const anchor: HTMLAnchorElement = document.createElement('a');
      anchor.href = link;

      anchor.setAttribute('download', '');

      document.body.appendChild(anchor);

      anchor.click();

      document.body.removeChild(anchor);
    } else {
      notification.error({ message: `Извините, при получении ссылки произошла ошибка` });
    }
  };

  return (
    <Form name={'base'} form={form} {...layout} initialValues={{ title, priority }}>
      <Form.Item
        label="Заголовок (наименование)"
        name="title"
        rules={[{ required: true, message: 'Пожалуйста, введите Заголовок (наименование)' }]}
      >
        <StyledInput />
      </Form.Item>
      <Form.Item
        label="Приоритет"
        name="priority"
        rules={[{ required: true, message: 'Пожалуйста, введите Приоритет' }]}
      >
        <Form.Item name="priority">
          <StyledInputNumber max={10} min={0} maxWidth />
        </Form.Item>
        <div className="text-gray-8 mt-16">Минимальное значение – 0, максимальное – 10</div>
      </Form.Item>
      {roleId !== undefined && roleId !== 'new' && (
        <div className="d-flex flex-justify-between w-100">
          <div />
          <Button type="primary" icon={<DownloadOutlined />} onClick={downloadExcel} disabled={isLoading}>
            {isLoading ? 'Загрузка...' : 'Выгрузить в Excel'}
          </Button>
        </div>
      )}
    </Form>
  );
};
