export const NAVIGATION = {
  wiki: 'wiki',
  tags: '/tags',
  needs: '/needs',
  places: '/places',
  events: '/events',
  services: '/services',
  socialRoles: '/social-roles',
  authorization: '/authorization',
  news: '/news',
  cities: '/cities',
  formatsAndThematics: '/formatsAndThematics',
  authors: '/authors',
  pushes: '/pushes',
  feedback: '/feedback',
  video: '/video',
};
