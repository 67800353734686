import { notification } from 'antd';
import useAxios from 'axios-hooks';
import { useSocialRolesList } from 'hooks/useSocialRolesList';
import React, { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Index } from '../../client/endpoints';
import { ClientErrorException } from '../../components/ClientErrorException';
import { AdminCreateRoleDataResponseDto, ServiceImage } from '../../models';
import { NAVIGATION } from '../../paths';
import { getLastUploadedImageData } from '../../utils/fileHandlers';
import { SocialRoleContent } from './SocialRoleContent';
import { SocialRoleForm } from './types';

export const SocialRole = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { addRole, removeRole, changeRole } = useSocialRolesList();
  const isCreationMode = id === 'new';

  const [{ data: roleData, loading: isRoleLoading, error }] = useAxios<
    BaseResponse<AdminCreateRoleDataResponseDto['data'] & { id: number; images: Array<ServiceImage> }>
  >({ url: `${Index.roles}/${id}`, method: 'GET' }, { manual: isCreationMode });

  const deleteRole = useAxios<BaseResponse<void>>(
    { url: `${Index.roles}/${id}`, method: 'DELETE' },
    { manual: true },
  )[1];

  const saveNewRole = useAxios<AdminCreateRoleDataResponseDto>(
    { url: Index.roles, method: 'POST' },
    { manual: true },
  )[1];

  const changeExistsRole = useAxios<{ name: string; needs: [] }>(
    { url: `${Index.roles}/${id}`, method: 'PATCH' },
    { manual: true },
  )[1];

  const submitForm = async (values: SocialRoleForm) => {
    try {
      const { title, priority } = values.baseData;
      const needs = values.needs.needs;
      const mappedNeeds = needs.map((item) => {
        return item.key;
      });
      if (isCreationMode) {
        const role = await saveNewRole({
          data: {
            name: title,
            needs: mappedNeeds,
            priority: priority,
          },
        });
        notification.success({
          message: `Роль успешно создана`,
        });
        addRole(role.data.data);
      }
      if (id !== undefined && !isCreationMode) {
        await changeExistsRole({
          data: {
            name: title,
            needs: mappedNeeds,
            priority: priority,
          },
        });
        changeRole({
          id: +id,
          name: title,
          needs: needs.map((item) => {
            return { name: item.label, id: item.key };
          }),
        });
        notification.success({
          message: `Роль успешно изменена`,
        });
      }

      navigate(NAVIGATION.socialRoles);
    } catch (e) {
      if (values.baseData.title.length < 5) {
        notification.error({ message: 'Название должно состоять как минимум из 5 символов' });
        return;
      }
      notification.error({ message: 'Произошла непредвиденная ошибка' });
    }
  };

  const preparedData = useMemo(() => {
    if (!isRoleLoading && roleData?.data) {
      const lastRoleIcon = roleData.data?.images[roleData.data.images?.length - 1];
      const image = getLastUploadedImageData(lastRoleIcon);
      return {
        iconForm: image,
        baseData: { title: roleData?.data.name, priority: roleData?.data.priority },
        needs:
          roleData.data.needs?.map((item) => {
            return { label: item.name, value: item.id, key: item.id };
          }) ?? [],
      };
    } else {
      return {
        baseData: { title: 'Новая роль', priority: '' },
        needs: [],
      };
    }
  }, [roleData?.data, isRoleLoading]);

  const archiveRole = async () => {
    try {
      await deleteRole();
      notification.success({
        message: `Социальная роль удалена`,
      });
      if (id) {
        removeRole(+id);
      }

      navigate(NAVIGATION.socialRoles);
    } catch (e) {
      notification.error({ message: `Извините, произошла ошибка` });
    }
  };

  if (error) {
    return <ClientErrorException errorCode={error.request.status} />;
  }

  return (
    <SocialRoleContent
      id={id}
      isLoading={isRoleLoading}
      onSubmit={submitForm}
      onDelete={archiveRole}
      initialValues={preparedData}
    />
  );
};
