import { Checkbox, Form } from 'antd';
import { AgeFormItem } from 'components/AgeFormItem';
import { PlaceSwitcher } from 'components/PlaceSwitcher';
import { PlaceTypeSelect } from 'components/PlaceTypeSelect';
import { TextEditor } from 'components/TextEditor';
import { useCategories, usePlaceTags, useTargetGroups } from 'hooks';
import { PlaceFrontend } from 'pages/Place/mappers';
import React from 'react';
import { Index } from '../../client/endpoints';
import useAsyncSelectOptions from '../../hooks/useAsyncSelectOptions';
import { RolesResponseDto } from '../../models';
import { mapDadataResponse } from '../../utils/mapDadataResponse';
import { DebouncedSelect } from '../DebouncedSelect';
import { StyledInput } from '../StyledInputs/StyledInput';
import { StyledInputNumber } from '../StyledInputs/StyledInputNumber';

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

export type PlaceBaseInfoFormValues = Pick<
  PlaceFrontend,
  | 'lat'
  | 'lon'
  | 'type'
  | 'tags'
  | 'title'
  | 'parent'
  | 'history'
  | 'address'
  | 'altTitle'
  | 'fullTitle'
  | 'categories'
  | 'visitingAge'
  | 'description'
  | 'targetGroups'
  | 'clarification'
  | 'shortDescription'
  | 'visitTimeAverage'
  | 'areaName'
>;

type TPlaceBaseInfoFormProps = { initialValues: PlaceBaseInfoFormValues; currentPlaceId: number };

export const PlaceBaseInfoForm: React.FC<TPlaceBaseInfoFormProps> = ({ initialValues, currentPlaceId }) => {
  const { data: categories } = useCategories();
  const { data: targetGroups } = useTargetGroups();

  const { data: placeTags } = usePlaceTags();
  const [form] = Form.useForm();

  const { fetcher } = useAsyncSelectOptions<RolesResponseDto>(Index.places + '/tags/search?query=', mapDadataResponse);

  return (
    <Form form={form} initialValues={initialValues} {...layout} name="base-info">
      <Form.Item name="title" label="Заголовок (наименование)" rules={[{ required: true }]}>
        <StyledInput />
      </Form.Item>
      <Form.Item
        label="Приоритет"
        name="priority"
        rules={[{ required: true, message: 'Пожалуйста, введите Приоритет' }]}
      >
        <Form.Item name="priority">
          <StyledInputNumber max={30} min={1} maxWidth />
        </Form.Item>
        <div className="text-gray-8 mt-16">Минимальное значение – 1, максимальное – 30</div>
      </Form.Item>
      <Form.Item name="fullTitle" label="Полное наименование" rules={[{ required: true }]}>
        <StyledInput.TextArea />
      </Form.Item>
      <Form.Item name="altTitle" label="Альтернативное наименование">
        <StyledInput />
      </Form.Item>
      <PlaceSwitcher
        initialParent={initialValues.parent}
        initialTab={initialValues.parent ? 'place' : 'address'}
        currentPlaceId={currentPlaceId}
      />
      <AgeFormItem label="Возрастной ценз" formItemProps={{ name: 'visitingAge', rules: [{ required: true }] }} />
      <Form.Item name="type" label="Тип места" rules={[{ required: true }]}>
        <PlaceTypeSelect />
      </Form.Item>
      <Form.Item name="tags" label="Теги">
        <DebouncedSelect
          fetcher={fetcher}
          initialOptions={mapDadataResponse(placeTags)}
          labelInValue={true}
          mode="multiple"
        />
        {/* <StyledSelectComponent options={mapDadataResponse(placeTags)} mode="multiple" onClick={refetchPlaceTags} /> */}
      </Form.Item>
      <Form.Item name="targetGroups" label="Целевые группы">
        <Checkbox.Group className="bg-gray-9 d-flex pv-8 ph-16 d-flex flex-wrap">
          {targetGroups?.map((target, idx) => (
            <Checkbox className={`w-1/2  m-0 ${idx < 2 ? '' : 'pt-8'}`} key={target} value={target}>
              {target}
            </Checkbox>
          ))}
        </Checkbox.Group>
      </Form.Item>
      <Form.Item name="categories" label="Категории">
        <Checkbox.Group className="bg-gray-9 d-flex pv-8 ph-16 d-flex flex-wrap">
          {categories?.map((category, idx) => (
            <Checkbox className={`w-1/2  m-0 ${idx < 2 ? '' : 'pt-8'}`} key={category} value={category}>
              {category}
            </Checkbox>
          ))}
        </Checkbox.Group>
      </Form.Item>
      <Form.Item
        name="visitTimeAverage"
        label="Среднее время посещения (мин)"
        rules={[
          {
            required: true,
            min: 1,
            type: 'number',
            message: 'Пожалуйста, введите Среднее время посещения (мин), значение должно быть больше 0',
          },
        ]}
      >
        <StyledInputNumber />
      </Form.Item>
      <Form.Item
        name="shortDescription"
        label="Короткое описание"
        rules={[{ required: true }, { max: 128, message: 'Максимальное количество символов не должно превышать 128' }]}
      >
        <StyledInput.TextArea />
      </Form.Item>
      <Form.Item name="description" label="Описание места" rules={[{ required: true }]}>
        <TextEditor />
      </Form.Item>
      <Form.Item name="history" label="История места">
        <TextEditor />
      </Form.Item>
    </Form>
  );
};
