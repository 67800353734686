import { Form, Switch } from 'antd';
import { Rule } from 'antd/lib/form';
import { TextEditor } from 'components/TextEditor';
import { useServiceTags } from 'hooks';
import { FrontendService } from 'pages/Service/mappers';
import React from 'react';
import { Index } from '../../../client/endpoints';
import { SERVICE_DESCRIPTION_MAX_LENGTH, SERVICE_SUITABLE_FOR_MAX_LENGTH } from '../../../constants';
import useAsyncSelectOptions from '../../../hooks/useAsyncSelectOptions';
import { RolesResponseDto } from '../../../models';
import { mapDadataResponse } from '../../../utils/mapDadataResponse';
import { AgeFormItem } from '../../AgeFormItem';
import { DebouncedSelect } from '../../DebouncedSelect/DebouncedSelect';
import { StyledInput } from '../../StyledInputs/StyledInput';

export type BaseInfoFormInitialValues = Pick<
  FrontendService,
  'title' | 'isBeta' | 'isTrusted' | 'functional' | 'description' | 'suitableFor' | 'ageRequirement' | 'tags'
>;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const baseTextEditorRules: Rule[] = [
  {
    required: true,
    validator: (_, value) => {
      const markdown = value;
      if (markdown === '') {
        return Promise.reject('  Пожалуйста, введите описание сервиса ');
      } else {
        return Promise.resolve();
      }
    },
  },
];

export const BaseInfoForm: React.FC<{ initialValues?: BaseInfoFormInitialValues }> = ({ initialValues }) => {
  const [form] = Form.useForm<BaseInfoFormInitialValues>();

  const { data: serviceTags } = useServiceTags();

  const { fetcher } = useAsyncSelectOptions<RolesResponseDto>(
    Index.services + '/tags/search?query=',
    mapDadataResponse,
  );

  return (
    <Form form={form} {...layout} name="base-ifno" initialValues={initialValues}>
      <Form.Item name="title" label="Название сервиса" rules={[{ required: true }]}>
        <StyledInput />
      </Form.Item>
      <AgeFormItem formItemProps={{ name: 'ageRequirement', rules: [{ required: true }] }} label={'Возрастной ценз'} />
      <Form.Item label="Параметры сервиса">
        <div className="d-flex flex-items-center flex-justify-between bg-gray-9 pv-4 ph-16 rounded-4 mb-8">
          Режим Beta тестирования
          <Form.Item className="m-0" valuePropName="checked" name="isBeta">
            <Switch />
          </Form.Item>
        </div>
        <div className="d-flex flex-items-center flex-justify-between bg-gray-9 pv-4 ph-16 rounded-4">
          Доверенный сервис
          <Form.Item className="m-0" valuePropName="checked" name="isTrusted">
            <Switch />
          </Form.Item>
        </div>
      </Form.Item>
      <Form.Item name="tags" label="Теги">
        {/*<StyledSelectComponent options={mapDadataResponse(serviceTags)} mode="multiple" onClick={refetchServiceTags} />*/}
        <DebouncedSelect
          fetcher={fetcher}
          initialOptions={mapDadataResponse(serviceTags)}
          labelInValue
          mode="multiple"
        />
      </Form.Item>
      <Form.Item name="description" label="Описание сервиса" rules={baseTextEditorRules}>
        <TextEditor maxSymbols={SERVICE_DESCRIPTION_MAX_LENGTH} />
      </Form.Item>
      <Form.Item name="suitableFor" label="Подходит для">
        <TextEditor maxSymbols={SERVICE_SUITABLE_FOR_MAX_LENGTH} />
      </Form.Item>
      <Form.Item name="functional" label="Функционал сервиса">
        <TextEditor maxSymbols={SERVICE_SUITABLE_FOR_MAX_LENGTH} />
      </Form.Item>
    </Form>
  );
};
