import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Layout, Pagination, Row, Spin } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import { PageTitle } from 'components/PageTitle';
import { Index } from 'client/endpoints';
import { ServiceDto } from 'models';
import { NAVIGATION } from 'paths';
import { ListCard } from 'components/ListCard';
import { useDebounce, useListing } from 'hooks';
import { NotFoundSearchResults } from '../../components/NotFoundSearchResults';
import { StyledInput } from '../../components/StyledInputs/StyledInput';

const getServicesUrl = (query = '') => {
  return query.length > 2 ? `${Index.services}/search?query=${query}` : `${Index.services}?`;
};

export const Services = () => {
  const push = useNavigate();
  const [searchValue, setSearchValue] = useState('');
  const debouncedSearchValue = useDebounce(searchValue, 1000);

  const { data, paginationProps, loading } = useListing<ServiceDto>(getServicesUrl(debouncedSearchValue));

  const handleSearchInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const searchValue = e.target.value;
    setSearchValue(searchValue);
  }, []);

  return (
    <div>
      <PageTitle title="Сервисы" onAddButtonClick={() => push(`${NAVIGATION.services}/new`)} />
      <Layout>
        <Content>
          <div className="p-24 mt-40 bg-neutral mb-24 rounded-8">
            <StyledInput value={searchValue} placeholder="Название сервиса" onChange={handleSearchInputChange} />
          </div>
          <Row className="gap-16" justify={loading ? 'center' : undefined}>
            {data && data.data.length < 1 && !loading && <NotFoundSearchResults />}
            {data === undefined || loading ? (
              <Spin className="flex-justify-center" size="large" />
            ) : (
              data.data.map(({ id, title, developer, description, image }) => (
                <ListCard
                  key={id}
                  imageUrl={image}
                  title={title || ''}
                  description={description}
                  footerDescription={developer?.toString()}
                  onClick={() => push(`${NAVIGATION.services}/${id}`)}
                />
              ))
            )}
          </Row>
          {data?.meta.total ? (
            <div className="d-flex flex-justify-end">
              <Pagination
                {...paginationProps}
                total={data.meta.total}
                pageSizeOptions={[8, 12, 16, 20]}
                defaultCurrent={paginationProps.current}
              />
            </div>
          ) : null}
        </Content>
      </Layout>
    </div>
  );
};
