import { ClientRole } from 'hooks/useSocialRolesList';
import {
  AdminCitiesAreasResponseDto,
  AuthorVideoResponse,
  CityResponseDto,
  Role,
  RolesListResponse,
} from '../../models';
import { FrontendAuthorItem } from './types';

// eslint-disable-next-line
export const mapAuthorForFrontend = (data: any): any => {
  const resultData = { ...data };
  const areas = resultData.areas as CityResponseDto[];
  if (resultData.areas?.length === 1) {
    resultData.areaId = areas[0].id;
    resultData.areaName = areas[0].name;
  }
  delete resultData.areas;
  return resultData;
};

export const mapAuthorForBackend = (formFields: FrontendAuthorItem) => {
  // eslint-disable-next-line
  const resultObject = { ...formFields, isAllAreas: false } as any;

  if (formFields.areaId) {
    resultObject.areas = [formFields.areaId];
    delete resultObject.areaName;
    delete resultObject.areaId;
  } else resultObject.isAllAreas = true;

  if (formFields.socialRoles) {
    // eslint-disable-next-line
    resultObject.roles = formFields.socialRoles.map((role: any) => role.key);
    delete resultObject.socialRoles;
  }

  if (formFields.news) {
    // eslint-disable-next-line
    resultObject.news = formFields.news.map((role: any) => role.value);

    if (formFields.news.length === 0) delete resultObject.news;
  }

  if (formFields.events) {
    // eslint-disable-next-line
    resultObject.events = formFields.events.map((role: any) => role.value);

    if (formFields.events.length === 0) delete resultObject.events;
  }

  if (formFields.services) {
    // eslint-disable-next-line
    resultObject.services = formFields.services.map((role: any) => role.value);

    if (formFields.services.length === 0) delete resultObject.services;
  }

  if (formFields.recommendedEvents) {
    // eslint-disable-next-line
    resultObject.recommendedEvents = formFields.recommendedEvents.map((role: any) => role.value);

    if (formFields.recommendedEvents.length === 0) delete resultObject.recommendedEvents;
  }

  if (formFields.places) {
    // eslint-disable-next-line
    resultObject.places = formFields.places.map((role: any) => role.value);

    if (formFields.places.length === 0) delete resultObject.places;
  }

  if (formFields.videos) {
    // eslint-disable-next-line
    resultObject.videos = formFields.videos.map((video: any) => video.value);
  }

  return resultObject;
};

// eslint-disable-next-line
export const baseBindingMapper = (data?: any[], selectedIds?: number[]) => {
  const filteredData =
    selectedIds && selectedIds?.length > 0
      ? // @ts-ignore: later
        data.filter((item) => !selectedIds.includes(item.id))
      : data;
  return filteredData?.map((item) => {
    return {
      label: item.title.length > 50 ? `${item.title.slice(0, 50)}...` : item.title,
      value: item.id,
    };
  });
};

// eslint-disable-next-line
export const baseBindingMapperToId = (data?: any[], mapByKey?: boolean) => {
  return data?.map((item) => {
    return mapByKey ? item.key : item.value;
  });
};

export const bindingVideosMapper = (data?: AuthorVideoResponse[], selectedIds?: number[]) => {
  const filteredData =
    selectedIds && selectedIds?.length > 0
      ? // @ts-ignore: later
        data.filter((item) => !selectedIds.includes(item.id))
      : data;
  return filteredData?.map((item) => {
    return {
      label: item.title.length > 50 ? `${item.title.slice(0, 50)}...` : item.title,
      value: item.url,
      key: item.id,
    };
  });
};

// eslint-disable-next-line
export const baseAuthorBindingMapper = (data?: any[], selectedIds?: number[]) => {
  const filteredData =
    selectedIds && selectedIds?.length > 0
      ? // @ts-ignore: later
        data.filter((item) => !selectedIds.includes(item.id))
      : data;
  return filteredData?.map((item) => {
    return {
      label: item.name.length > 50 ? `${item.name.slice(0, 50)}...` : item.name,
      value: item.id,
    };
  });
};

export const rolesMapper = (data?: RolesListResponse['data'] | ClientRole[] | Role[]) => {
  return data?.map((item) => {
    return {
      label: item.name,
      key: item.id,
      value: item.name,
    };
  });
};

export const mapCities = (data?: AdminCitiesAreasResponseDto[]) => {
  return data?.map(({ name, id }) => {
    return { label: name, key: id, value: name };
  });
};
