import React, { ReactNode } from 'react';
import { Button, Form } from 'antd';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Rule } from 'antd/lib/form';
import { StyledInput } from '../StyledInputs/StyledInput';

type FormListProps<T> = {
  label: string;
  name: string;
  rules?: Rule[];
  customController?: ReactNode;
  defaultValue?: T;
  required?: boolean;
  initialFirstElement?: boolean;
};

export const FormList = <T,>({
  name,
  rules,
  label,
  defaultValue,
  customController,
  required,
  initialFirstElement,
}: FormListProps<T>) => {
  return (
    <Form.Item label={label} required={required}>
      <Form.List
        name={name}
        initialValue={
          initialFirstElement
            ? [
                {
                  name: 'test2',
                },
              ]
            : []
        }
      >
        {(fields, { add, remove }) => {
          if (initialFirstElement && fields.length === 0) add('');
          return (
            <div>
              {fields.map((field, index) => (
                <div key={field.name} className="d-flex gap-8">
                  <Form.Item className="flex-grow-1 form-list-item" {...field} rules={rules}>
                    {customController ?? <StyledInput />}
                  </Form.Item>

                  <Button
                    shape="circle"
                    onClick={() => {
                      if (initialFirstElement) {
                        if (index !== 0) {
                          remove(field.name);
                        }
                      } else remove(field.name);
                    }}
                    disabled={initialFirstElement && index === 0}
                  >
                    <MinusOutlined />
                  </Button>
                </div>
              ))}
              <Button onClick={() => add(defaultValue ?? '')}>
                <PlusOutlined />
              </Button>
            </div>
          );
        }}
      </Form.List>
    </Form.Item>
  );
};
