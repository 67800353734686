import { Layout, Pagination, Row, Spin } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import { getVideosEndpointUrl } from 'client/endpoints/videos';
import { ListCard } from 'components/ListCard';
import { NotFoundSearchResults } from 'components/NotFoundSearchResults';
import { PageTitle } from 'components/PageTitle';
import { VIDEOS_PLATFORMS } from 'constants/index';
import { useListing } from 'hooks';
import { AdminGetListVideosResponse } from 'models';
import { NAVIGATION } from 'paths';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './styles.module.css';

export const Videos = () => {
  const push = useNavigate();

  const { data, paginationProps, loading } = useListing<AdminGetListVideosResponse>(getVideosEndpointUrl());

  return (
    <div>
      <PageTitle title="Видео" wrapperClassName="mb-24" />
      <Layout>
        <Content>
          <Row className="gap-16" justify={loading ? 'center' : undefined}>
            {data && data.data.length < 1 && !loading && <NotFoundSearchResults />}
            {data === undefined || loading ? (
              <Spin className="flex-justify-center" size="large" />
            ) : (
              data.data.map(({ id, title, image, platform, isHidden }) => (
                <ListCard
                  key={id}
                  imageUrl={image ?? undefined}
                  title={title || ''}
                  onClick={() => push(`${NAVIGATION.video}/${id}`)}
                  footerDescription={
                    <div className={styles.wrapper}>
                      <p>{VIDEOS_PLATFORMS[platform] || platform}</p>
                      <div className={`${styles.status} ${isHidden ? styles.offline : ''}`} />
                    </div>
                  }
                  withFooterSeparator
                />
              ))
            )}
          </Row>
          {data?.meta.total ? (
            <div className="d-flex flex-justify-end">
              <Pagination
                {...paginationProps}
                total={data.meta.total}
                pageSizeOptions={[8, 12, 16, 20]}
                defaultCurrent={paginationProps.current}
              />
            </div>
          ) : null}
        </Content>
      </Layout>
    </div>
  );
};
