import { FormHeaderProps } from 'components/FormHeader/FormHeader';
import React, { useState } from 'react';

export type Tab = {
  size?: 's';
  key: string;
  label: string;
  children?: React.ReactElement;
  formHeaderType?: FormHeaderProps['type'];
};

export const useTabs = (tabs: Tab[], initialTab = '1') => {
  const [activeTab, setActiveTab] = useState(initialTab);

  const goToNextTab = () => {
    const currentIdx = tabs.findIndex(({ key }) => key === activeTab);
    const nextItemIdx = currentIdx + 1;
    if (tabs.length === nextItemIdx) {
      setActiveTab(tabs[0].key);
    } else {
      setActiveTab(tabs[nextItemIdx].key);
    }
  };

  const goToPrevTab = () => {
    const currentIdx = tabs.findIndex(({ key }) => key === activeTab);
    if (activeTab === tabs[0].key) {
      setActiveTab(tabs[tabs.length - 1].key);
    } else {
      setActiveTab(tabs[currentIdx - 1].key);
    }
  };

  const tabsWithProps = tabs.map((t) => ({
    ...t,
    contentClassName: `${t.size === 's' ? 'small-container' : ''} ${activeTab !== t.key ? 'd-none' : ''}`,
  }));

  return { activeTab, tabs: tabsWithProps, setActiveTab, goToNextTab, goToPrevTab };
};

export const serviceTabKeys = {
  common: '1',
  tech: '2',
  images: '3',
  roles: '4',
  review: '5',
};

export const socialRoleKeys = {
  common: '1',
  icon: '2',
  linkedNeeds: '3',
};

export const eventTabKeys = {
  common: '1',
  siteData: '2',
  datesAndTickets: '3',
  tickets: '4',
  contacts: '5',
  gallery: '6',
  review: '7',
  roles: '8',
  relatedPlacesAndEvents: '9',
};

export const needTabKeys = {
  common: '1',
  linkedRoles: '2',
};

export const needTabs: Tab[] = [
  { label: 'Общие сведения', key: needTabKeys.common },
  { label: 'Связанные роли', key: needTabKeys.linkedRoles },
];

export const socialRoleTabs: Tab[] = [
  { label: 'Общие сведения', key: socialRoleKeys.common, size: 's' },
  { label: 'Иконка', key: socialRoleKeys.icon },
  { label: 'Связанные потребности', key: socialRoleKeys.linkedNeeds },
];

export const eventTabs: Tab[] = [
  {
    label: 'Общие сведения',
    key: eventTabKeys.common,
    size: 's',
    formHeaderType: 'form',
  },
  {
    label: 'Сведения для сайта',
    key: eventTabKeys.siteData,
    size: 's',
    formHeaderType: 'form',
  },
  {
    label: 'Расписание',
    key: eventTabKeys.datesAndTickets,
    formHeaderType: 'fullwidth',
  },
  { label: 'Билеты', key: eventTabKeys.tickets, formHeaderType: 'fullwidth' },
  { label: 'Контакты', key: eventTabKeys.contacts, formHeaderType: 'form', size: 's' },
  { label: 'Галерея', key: eventTabKeys.gallery, formHeaderType: 'fullwidth' },
  {
    label: 'Наш обзор',
    key: eventTabKeys.review,
    formHeaderType: 'fullwidth',
    size: 's',
  },
  {
    label: 'Связанные роли и потребности',
    key: eventTabKeys.roles,
    formHeaderType: 'fullwidth',
  },
  {
    label: 'Связанные места и события',
    key: eventTabKeys.relatedPlacesAndEvents,
    formHeaderType: 'fullwidth',
  },
];

export const serviceTabs: Tab[] = [
  {
    size: 's',
    label: 'Общие сведения',
    formHeaderType: 'form',
    key: serviceTabKeys.common,
  },
  {
    size: 's',
    formHeaderType: 'form',
    label: 'Тех. информация',
    key: serviceTabKeys.tech,
  },
  { label: 'Галерея изображений', formHeaderType: 'fullwidth', key: serviceTabKeys.images },
  { label: 'Роли и потребности', formHeaderType: 'fullwidth', key: serviceTabKeys.roles },
  {
    size: 's',
    label: 'Наш обзор',
    key: serviceTabKeys.review,
    formHeaderType: 'fullwidth',
  },
];

export const placeTabKeys = {
  common: '1',
  workTime: '2',
  contacts: '3',
  images: '4',
  review: '5',
  roles: '6',
  connections: '7',
};

export const TAB_PREFIX = 'tab';

export const placeTabs: Tab[] = [
  {
    size: 's',
    formHeaderType: 'form',
    label: 'Общие сведения',
    key: placeTabKeys.common,
  },
  {
    label: 'График работы',
    key: placeTabKeys.workTime,
    formHeaderType: 'fullwidth',
  },
  {
    size: 's',
    label: 'Контакты',
    formHeaderType: 'form',
    key: placeTabKeys.contacts,
  },
  { label: 'Галерея', key: placeTabKeys.images, formHeaderType: 'fullwidth' },
  {
    size: 's',
    label: 'Наш обзор',
    key: placeTabKeys.review,
    formHeaderType: 'fullwidth',
  },
  { label: 'Связанные роли и потребности', key: placeTabKeys.roles, formHeaderType: 'fullwidth' },
  { label: 'Связи', key: placeTabKeys.connections, formHeaderType: 'fullwidth' },
];

export const newsTabKeys = {
  common: '1',
  gallery: '2',
  roles: '3',
};

export const newsTabs: Tab[] = [
  { label: 'Общие сведения', key: newsTabKeys.common, size: 's' },
  { label: 'Обложка новости', key: newsTabKeys.gallery, formHeaderType: 'fullwidth', size: 's' },
  { label: 'Связанные роли', key: newsTabKeys.roles, formHeaderType: 'fullwidth', size: 's' },
];

export const cityTabKeys = {
  common: '1',
  gallery: '2',
};

export const cityTabs: Tab[] = [{ label: 'Общие сведения', key: cityTabKeys.common, size: 's' }];

export const authorsTabKeys = {
  common: '1',
  gallery: '2',
  roles: '3',
  bindings: '4',
};

export const authorsTabs: Tab[] = [
  { label: 'Общие сведения', key: authorsTabKeys.common, size: 's' },
  { label: 'Обложка автора', key: authorsTabKeys.gallery, formHeaderType: 'fullwidth', size: 's' },
  { label: 'Связанные роли', key: authorsTabKeys.roles, formHeaderType: 'fullwidth', size: 's' },
  { label: 'Привязки', key: authorsTabKeys.bindings, formHeaderType: 'fullwidth', size: 's' },
];

export const pushTabs: Tab[] = [{ label: 'Общие сведения', key: authorsTabKeys.common, size: 's' }];

export const videosTabKeys = {
  common: '1',
  connections: '2',
};

export const videosTabs: Tab[] = [
  { label: 'Общие сведения', key: videosTabKeys.common, size: 's' },
  { label: 'Привязки', key: videosTabKeys.connections, formHeaderType: 'fullwidth', size: 's' },
];
