import { Button, Form, Layout, Modal } from 'antd';
import React, { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getEventCreationQueryStringValues } from '../../pages/Event';
import { AsyncGlobalEventsSelect } from '../AsyncGlobalEventsSelect';
import { BaseInfoEventFormValues } from '../EventForm/EventForm.typedef';
import { StyledInput } from '../StyledInputs/StyledInput';

export type SelectOption = { relatedGlobalEvent: { label: string; key: number; value: string } };

type CreateEventModalProps = {
  isOpen: boolean;
  onClose: () => void;
};
export type CreateEventModalFormValues = Pick<
  BaseInfoEventFormValues,
  'relatedGlobalEvent' | 'shortTitle' | 'fullTitle' | 'status'
>;
const defaultValues: CreateEventModalFormValues = {
  relatedGlobalEvent: null,
  shortTitle: '',
  fullTitle: '',
  status: 'global',
};

export const CreateEventModal: FC<CreateEventModalProps> = ({ isOpen, onClose }) => {
  const [status, setStatus] = useState<string>('global');
  const navigate = useNavigate();
  const [form] = Form.useForm<CreateEventModalFormValues>();
  const fillBaseFields = (values: CreateEventModalFormValues, status: string) => {
    const query = getEventCreationQueryStringValues({
      status: status,
      relatedGlobalEvent: values.relatedGlobalEvent
        ? { id: values.relatedGlobalEvent.key, title: values.relatedGlobalEvent.label }
        : undefined,
      shortTitle: values.shortTitle,
    });
    if (typeof query === 'string') {
      navigate({
        pathname: '/events/new',
        search: query,
      });
    }
  };
  const changeStatus = (status: BaseInfoEventFormValues['status']) => {
    setStatus(status);
    form.setFieldValue('status', status);
  };

  const selectOption = (option: SelectOption) => {
    form.setFieldValue('relatedGlobalEvent', option);
  };

  const submit = () => {
    fillBaseFields(form.getFieldsValue(), status);
  };
  const statusesWithGlobalEvent = ['once', 'regular'];
  return (
    <Modal
      title={'Добавление нового события'}
      open={isOpen}
      onOk={() => {
        return submit();
      }}
      onCancel={onClose}
    >
      <Layout className={'d-flex flex-column'}>
        <Form name={'createEventModalForm'} form={form} initialValues={defaultValues}>
          <Form.Item className={'d-inline-flex w-100'}>
            <Button
              onClick={() => {
                return changeStatus('global');
              }}
              type={status === 'global' ? 'primary' : 'default'}
            >
              Глобальное
            </Button>
            <Button
              onClick={() => {
                return changeStatus('once');
              }}
              type={status === 'once' ? 'primary' : 'default'}
            >
              Разовое
            </Button>
          </Form.Item>
          {statusesWithGlobalEvent.includes(status) && (
            <div className={'mt-16'}>
              <p>
                Локальное событие может выступать в качестве связанного (зависимого события), так и в качестве
                самостоятельного. Если мероприятие относится к некоторому глобальному событию – укажите его в поле ниже
              </p>
              <AsyncGlobalEventsSelect onSelect={selectOption} />
              <Form.Item
                name="shortTitle"
                label={'Название события'}
                rules={[{ required: true, message: 'Заполните поле названия события' }]}
              >
                <StyledInput placeholder="Не указано" />
              </Form.Item>
            </div>
          )}
          {status === 'global' && (
            <div className={'mt-16'}>
              <Form.Item
                name="shortTitle"
                label={'Название события'}
                rules={[{ required: true, message: 'Заполните поле названия события' }]}
              >
                <StyledInput placeholder="Не указано" />
              </Form.Item>
            </div>
          )}
        </Form>
      </Layout>
    </Modal>
  );
};
