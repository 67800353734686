import { ArrowLeftOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Form, Modal, Typography } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';

type EntityHeaderProps = {
  title: string;
  onSaveButtonClick?: () => void;
  onArchiveButtonClick?: () => void;
};

const { confirm } = Modal;

export const EntityHeader: React.FC<EntityHeaderProps> = ({ title, onSaveButtonClick, onArchiveButtonClick }) => {
  const navigate = useNavigate();

  const openConfirmModal = () => {
    confirm({
      title: 'Вы действительно хотите удалить?',
      icon: <ExclamationCircleOutlined />,
      content: 'После удаления данные нельзя будет восстановить',
      okText: 'Удалить',
      cancelText: 'Отмена',
      onOk() {
        if (onArchiveButtonClick) onArchiveButtonClick();
      },
    });
  };

  return (
    <div className="pv-16 ph-24 d-flex flex-justify-between bg-neutral">
      <div className="d-flex gap-8 flex-items-center">
        <ArrowLeftOutlined onClick={() => navigate(-1)} />
        {/* Я эту грязь делать не хотел, честно, но это Ant ;( */}
        <Typography.Title level={3} className="text-gray-5 m-0 mr-16-important">
          {title}
        </Typography.Title>
      </div>
      <div className="d-flex gap-8">
        {onArchiveButtonClick && (
          <Button className="bg-gray-9" onClick={openConfirmModal}>
            Удалить
          </Button>
        )}
        <Form name="submit-form">
          <Form.Item className="m-0">
            <Button htmlType="submit" type="primary" onClick={onSaveButtonClick}>
              Сохранить
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};
