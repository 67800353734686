import { DatePicker, Form, TimePicker } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import { getCitiesEndpointWithSearchUrl } from 'client/endpoints/cities';
import { DebouncedSelect } from 'components/DebouncedSelect';
import { mappedPushEntities } from 'components/PushBaseInfoForm/constants';
import { useGetBusyTime, usePushMapping } from 'components/PushBaseInfoForm/hooks';
import { StyledInput } from 'components/StyledInputs/StyledInput';
import { StyledTextArea } from 'components/StyledInputs/StyledTextArea';
import { StyledSelectComponent } from 'components/StyledSelect';
import useAsyncSelectOptions from 'hooks/useAsyncSelectOptions';
import { useCitiesEntity } from 'hooks/useCitiesEntity';
import { useSocialRolesList } from 'hooks/useSocialRolesList';
import { RolesResponseDto } from 'models';
import moment, { Moment } from 'moment';
import 'moment/locale/ru';
import { mapCities, rolesMapper } from 'pages/Author/mappers';
import { FrontendAuthorItem } from 'pages/Author/types';
import { convertToYYYYMMDDWithMoment } from 'pages/Push/mappers';
import React, { useEffect } from 'react';
import { BASE_ROLES_SEARCH_URL } from 'utils';
import { whitespaceValidator } from 'utils/whitespaceValidator';
import styles from './styles.module.css';

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

type TPlaceBaseInfoFormProps = { initialValues: FrontendAuthorItem };

export const PushBaseInfoForm: React.FC<TPlaceBaseInfoFormProps> = ({ initialValues }) => {
  const [form] = Form.useForm();

  const disabledDate = (current: Moment) => {
    const today = new Date();
    today.setDate(today.getDate() - 1);
    today.setHours(23, 59, 59, 999);

    const todayPlusThreeYears = new Date(new Date().setFullYear(new Date().getFullYear() + 3));

    return current && (current.toDate() < today || current.toDate() > todayPlusThreeYears);
  };

  const selectedDate = useWatch('date', form);
  const { data: busyTimeData } = useGetBusyTime(selectedDate && convertToYYYYMMDDWithMoment(selectedDate));

  const disabledTime = () => {
    const currentTime = moment();
    const currentHour = currentTime.hour();
    const currentMinute = currentTime.minute();

    if (busyTimeData) {
      const busyHours = busyTimeData.times.map((time: string) => moment(time, 'HH:mm').hour());

      return {
        // eslint-disable-next-line
        disabledHours: () => {
          let hoursToDisable: number[] = [];

          if (selectedDate && selectedDate.isSame(moment(), 'day')) {
            hoursToDisable = Array.from({ length: 24 }, (_, i) => i).filter(
              (hour) => hour < currentHour || (hour === currentHour && currentMinute !== 0),
            );
          }

          const disabledHoursSet = new Set([...hoursToDisable, ...busyHours]);
          return Array.from(disabledHoursSet);
        },
        // eslint-disable-next-line
        disabledMinutes: () => Array.from({ length: 60 }, (_, i) => i).filter((minute) => minute !== 0),
      };
    }
    return {
      disabledHours: () => [],
      disabledMinutes: () => [],
    };
  };

  const selectedMaterial = Form.useWatch('entityType', form);

  const { mappedData, fetcher } = usePushMapping(selectedMaterial);

  useEffect(() => {
    if (!initialValues) form.setFieldValue('entityId', null);
  }, [form, selectedMaterial, initialValues]);

  const { roles } = useSocialRolesList();

  const { fetcher: rolesFetcher } = useAsyncSelectOptions<RolesResponseDto>(BASE_ROLES_SEARCH_URL, rolesMapper);
  const mappedRoles = rolesMapper(roles);

  const { data: cities } = useCitiesEntity();

  const mappedCities = mapCities(cities);

  const { fetcher: citiesFetcher } = useAsyncSelectOptions(getCitiesEndpointWithSearchUrl(), mapCities);

  const selectedAreas = useWatch('areasIds', form);

  return (
    <Form form={form} {...layout} name="base-info" initialValues={initialValues}>
      <Form.Item name="date" label="Дата" rules={[{ required: true }]}>
        <DatePicker disabledDate={disabledDate} className={styles.datePicker} format={'DD MMMM YYYY'} />
      </Form.Item>
      <Form.Item name="time" label="Время" rules={[{ required: true }]}>
        <TimePicker
          className={`${styles.datePicker} custom-timepicker`}
          format={'HH:mm'}
          disabledTime={disabledTime}
          showNow={false}
          defaultValue={moment().minute(0)}
          disabled={!selectedDate}
        />
      </Form.Item>
      <Form.Item
        name="title"
        label="Заголовок"
        rules={[
          { required: true },
          { max: 19, message: 'Максимальное количество символов не должно превышать 19' },
          { validator: whitespaceValidator },
        ]}
      >
        <StyledInput />
      </Form.Item>
      <Form.Item
        name="body"
        label="Описание"
        rules={[
          { required: true },
          { max: 49, message: 'Максимальное количество символов не должно превышать 49' },
          { validator: whitespaceValidator },
        ]}
      >
        <StyledTextArea />
      </Form.Item>
      <Form.Item name="areasNames" label="Город" rules={[{ required: true }]}>
        <DebouncedSelect
          fetcher={citiesFetcher}
          mode="multiple"
          initialOptions={mappedCities}
          onSelect={(_, option) => {
            form.setFieldValue('areasIds', [...selectedAreas, option.key]);
          }}
          onDeselect={(option) => {
            // @ts-ignore: temp
            const foundItem = initialValues?.areasNames?.find((item) => item.label === option);

            if (foundItem)
              form.setFieldValue(
                'areasIds',
                // @ts-ignore: temp
                selectedAreas.filter((item) => item !== foundItem.key),
              );
          }}
        />
      </Form.Item>
      <Form.Item name="areasIds" label="Города" style={{ display: 'none' }} />
      <Form.Item name="rolesIds" label="Связанные роли" rules={[{ required: true }]}>
        <DebouncedSelect fetcher={rolesFetcher} initialOptions={mappedRoles} labelInValue mode="multiple" />
      </Form.Item>
      <Form.Item
        name="entityType"
        label="Тип материала"
        rules={[{ max: 100, message: 'Максимальное количество символов не должно превышать 100' }]}
      >
        <StyledSelectComponent options={mappedPushEntities} />
      </Form.Item>
      <Form.Item name="entityId" label="Материал">
        <DebouncedSelect fetcher={fetcher} initialOptions={mappedData} labelInValue disabled={!selectedMaterial} />
      </Form.Item>
    </Form>
  );
};
