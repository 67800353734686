import { PageTitle } from '../../components/PageTitle';
import { NAVIGATION } from '../../paths';
import { Button, Layout, Table, TableProps } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { ColumnsType } from 'antd/es/table';
import { useCitiesBackendActions } from '../../hooks/useCitiesBackendActions';
import { CloseOutlined } from '@ant-design/icons';
import { useCities } from '../../hooks/useCities';
import { AdminCitiesAreasResponseDto } from '../../models';

interface CityDataType {
  key: React.Key;
  title: string;
}

const DeleteButton: React.FC<{ record: CityDataType; removeCity: (id: AdminCitiesAreasResponseDto['id']) => void }> = ({
  record,
  removeCity,
}) => {
  const {
    actions: { handleDeleteCity },
  } = useCitiesBackendActions({ isCreationMode: true, id: record?.key.toString(), name: record?.title });

  const deleteCity = async () => {
    await handleDeleteCity();
    await removeCity(record?.key as number);
  };

  return (
    <Button type="link" danger className="delete-button" onClick={deleteCity} icon={<CloseOutlined />}>
      Удалить
    </Button>
  );
};

export const Cities = () => {
  const push = useNavigate();
  const { cities: needs, updatePaginationInfo, totalCount, removeCity } = useCities();

  const tableData: Array<CityDataType> = needs.map((item) => {
    return {
      key: item.id,
      title: item.name,
    };
  });
  const onChange: TableProps<CityDataType>['onChange'] = ({ current, pageSize = 10 }) => {
    if (current !== undefined && pageSize !== undefined) {
      const offset = current === 1 ? '0' : (current - 1) * pageSize;
      updatePaginationInfo({ offset: +offset, limit: pageSize });
    }
  };

  const columns: ColumnsType<CityDataType> = useMemo(
    () => [
      {
        title: 'Список городов',
        dataIndex: 'title',
        sortDirections: ['descend', 'ascend'],
        render: (title: string) => {
          return (
            <p title={title} className={'m-0 text-overflow w-50 overflow-hidden'}>
              {title}
            </p>
          );
        },
      },
      {
        dataIndex: 'action',
        align: 'right',
        render: (_, record) => {
          return <DeleteButton record={record} removeCity={removeCity} />;
        },
      },
    ],
    [removeCity],
  );

  return (
    <Layout>
      <PageTitle
        title="Города"
        wrapperClassName="mb-24"
        titleClassName={'mb-24'}
        onAddButtonClick={() => push(`${NAVIGATION.cities}/new`)}
      />

      <Content>
        <Table
          pagination={{
            total: totalCount,
            pageSizeOptions: ['10', '20'],
            defaultPageSize: 10,
          }}
          columns={columns}
          dataSource={tableData}
          onChange={onChange}
        />
      </Content>
    </Layout>
  );
};
