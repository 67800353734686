export type Periodicity = 'regular' | 'one-time' | 'global';
export const translatePeriodicityToServer = (periodicity: string): 'regular' | 'one-time' | 'global' => {
  switch (periodicity) {
    case 'regular':
      return 'regular';
    case 'once':
      return 'one-time';
    case 'global':
      return 'global';
    default:
      return periodicity as Periodicity;
  }
};
