import { getFormErrorMessages } from './formatters/getFormErrorMessages';
import { notification } from 'antd';
import React from 'react';

// eslint-disable-next-line
export const showFormErrorMessages = (validation: any, additionalErrorText?: string) => {
  const finalErrors = getFormErrorMessages(validation);
  if (additionalErrorText) {
    finalErrors.push(additionalErrorText);
  }
  return notification.error({
    message: 'Не все поля заполнены корректно:',
    description: finalErrors.map((item) => (
      <>
        • {item.trim()}
        <br />
      </>
    )),
  });
};
