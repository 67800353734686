import { notification } from 'antd';
import useAxios from 'axios-hooks';
import { getRoleReports } from 'client/endpoints/roles';
import { ReportRoleLinkResponse } from 'models';
import { useCallback, useState } from 'react';

export const useGetRoleReports = (roleId: string | undefined) => {
  const [isLoading, setIsLoading] = useState(false);

  const downloadReport = useAxios<BaseResponse<ReportRoleLinkResponse>>(getRoleReports(roleId), {
    manual: true,
  })[1];

  const handleDownloadReports = useCallback(async () => {
    setIsLoading(true);
    try {
      const result = await downloadReport();
      notification.success({
        message: `Роль выгружена в Excel`,
      });
      setIsLoading(false);
      return result.data.data.link;
    } catch (error) {
      notification.error({ message: `Извините, произошла ошибка` });
      setIsLoading(false);
    }
  }, [downloadReport]);

  return {
    handleDownloadReports,
    isLoading,
  };
};
