export const translatePeriodicity = (periodicity: string) => {
  switch (periodicity) {
    case 'regular':
      return 'Регулярное';
    case 'one-time':
      return 'Разовое';
    case 'global':
      return 'Глобальное';
    default:
      return 'Не указано';
  }
};
