const MAIN_BASE_PATH = '/v1/';
const ADMIN_BASE_PATH = '/admin/v1/';

export const Index = {
  wiki: ADMIN_BASE_PATH + 'wiki',
  roles: ADMIN_BASE_PATH + 'roles',
  events: MAIN_BASE_PATH + 'events',
  places: ADMIN_BASE_PATH + 'places',
  baseRoles: MAIN_BASE_PATH + 'roles',
  needs: ADMIN_BASE_PATH + 'roles/needs',
  services: ADMIN_BASE_PATH + 'services',
  adminEvents: ADMIN_BASE_PATH + 'events',
  placeTypes: ADMIN_BASE_PATH + 'places/types',
  eventsTypes: ADMIN_BASE_PATH + 'events/types',
  parentPlaces: ADMIN_BASE_PATH + 'places/search-parents',
  addressSuggestions: MAIN_BASE_PATH + 'address_suggestions',
  news: ADMIN_BASE_PATH + 'news',
  cities: ADMIN_BASE_PATH + 'cities',
  authors: ADMIN_BASE_PATH + 'authors',
  videos: MAIN_BASE_PATH + 'video-integration/videos',
  reports: ADMIN_BASE_PATH + 'reports/role',
  pushes: ADMIN_BASE_PATH + 'notifications',
  feedback: ADMIN_BASE_PATH + 'feedback',
  videosAdmin: ADMIN_BASE_PATH + 'videos',
};
