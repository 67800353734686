import { RcFile } from 'antd/es/upload';
import { ImageEntity, useEntityUploadSingleImage } from '../../hooks/useUploadImage';
import { Avatar, Button, Spin, Typography, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import React, { useState } from 'react';
import useAxios from 'axios-hooks';
import { UploadRequestOption } from 'rc-upload/lib/interface';
import styles from './styles.module.css';
import { getAuthorByIdEndpointUrl } from '../../client/endpoints/authors';

type Props = {
  image: string | null;
  newId: number;
  isCreationMode?: boolean;
  setImageFile: (file: string | Blob | RcFile) => void;
};

export const AuthorImage: React.FC<Props> = ({ image: logo, newId, isCreationMode, setImageFile }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [logoImage, setLogoImage] = useState<{ src?: string }>({
    src: logo ?? undefined,
  });

  const getNew = useAxios(getAuthorByIdEndpointUrl(newId.toString()), { manual: true })[1];

  const customRequest = useEntityUploadSingleImage(ImageEntity.Authors, async () => {
    const newItem = await getNew();
    setLogoImage({ src: newItem.data.data.image });
    setIsLoading(false);
  });

  const customRequestHandler = (params: UploadRequestOption) => {
    if (isCreationMode) {
      setImageFile(params.file);
      setLogoImage({ src: URL.createObjectURL(params.file as Blob) });
    } else {
      setIsLoading(true);
      customRequest(params);
    }
  };

  return (
    <div>
      <div className="d-flex gap-8 flex-items-center">
        <div className={styles.loadingWrapper}>
          <div className={`${styles.spinContainer} ${!isLoading ? styles.hiddenSpinner : ''}`}>
            <Spin />
          </div>
          <div className={`${styles.loadingComponent} ${!isLoading ? styles.hiddenContainer : ''}`}>
            <Avatar
              size={88}
              shape="square"
              src={logoImage.src}
              style={{ border: '1px solid #434343', backgroundColor: 'transparent' }}
            />
          </div>
        </div>

        <div className="d-flex flex-column gap-8">
          <Typography.Text className="text-gray-7">Используйте изображение в формате JPG или PNG</Typography.Text>
          <div className={'d-flex gap-16'}>
            <Upload accept=".jpeg,.jpg,.png" customRequest={customRequestHandler} showUploadList={false}>
              <Button type="primary" icon={<UploadOutlined />}>
                Загрузить новую
              </Button>
            </Upload>
          </div>
        </div>
      </div>
    </div>
  );
};
