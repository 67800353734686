import React, { useState } from 'react';
import { Badge, Button, Pagination, Spin, Tag, Typography } from 'antd';
import { StyledInput } from '../StyledInputs/StyledInput';
import { useDebounce, useListing } from '../../hooks';
import { RolesResponseDto } from '../../models';
import { Index } from '../../client/endpoints';

export type Need = {
  id: string;
  name: string;
  isActive: boolean;
};

export type SocialRolesProps = {
  needs: number[];
  onNeedClick: (needId: number) => void;
};

const initialPagination = { current: 1, pageSize: 10 };

export const SocialRoles: React.FC<SocialRolesProps> = ({ needs, onNeedClick }) => {
  // const { roles } = useSocialRolesList();

  const [searchValue, setSearchValue] = useState('');

  // const { roles: rolesData, totalCount } = useSocialRolesList();

  const debouncedQuery = useDebounce(searchValue, 500);
  const { data, paginationProps, loading } = useListing<RolesResponseDto>(
    `${Index.roles}?`,
    initialPagination,
    debouncedQuery,
  );
  const [activeRole, setActiveRole] = useState<Optional<number>>(data?.data[0]?.id);

  const currentRole = data?.data.find((r) => r.id === activeRole);

  const showNeeds = currentRole?.name.toLowerCase().includes(searchValue.toLowerCase());

  return (
    <div className="d-flex gap-40">
      <div className="d-flex flex-column gap-8">
        <StyledInput
          className="mb-16"
          value={searchValue}
          placeholder="Поиск по ролям"
          onChange={(e) => setSearchValue(e.target.value)}
        />
        {loading ? (
          <Spin />
        ) : (
          data?.data?.map(({ id, name, needs: roleNeeds }) => {
            const isActive = activeRole === id;
            const allNeedsIds = roleNeeds?.map(({ id }) => id);
            const selectedNeeds = allNeedsIds?.filter((n) => needs.includes(n)).length;
            const className = `d-flex flex-justify-between gap-8 ${
              selectedNeeds !== undefined && selectedNeeds > 0 ? 'bg-gray-8' : ''
            } ${isActive ? 'active-border' : ''}`;
            return name.toLowerCase().includes(searchValue.toLowerCase()) ? (
              <Button key={id} type="ghost" onClick={() => setActiveRole(id)} className={className}>
                {name}
                <Badge
                  showZero
                  style={{ backgroundColor: '#FF4D4F' }}
                  count={`${selectedNeeds}/${allNeedsIds?.length}`}
                />
              </Button>
            ) : null;
          })
        )}
        {!loading && data?.data && data?.data.length > 0 && (
          <Pagination {...paginationProps} total={data?.meta.total} />
        )}
        {!loading && (!data?.data || data?.data.length === 0) && <div>Ничего не найдено</div>}
      </div>
      <div className="d-flex flex-column gap-24">
        <Typography.Title level={3}>Связанные потребности</Typography.Title>
        {showNeeds && (
          <div>
            {currentRole?.needs?.map(({ id, name }) => (
              <Tag
                key={id}
                className="cursor-pointer"
                onClick={() => onNeedClick(id)}
                color={needs.includes(id) ? '#1890FF' : undefined}
              >
                {name}
              </Tag>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
