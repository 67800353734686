import React, { isValidElement, ReactElement } from 'react';
import { Card, Image } from 'antd';
import Meta from 'antd/lib/card/Meta';
import NoImage from '../../images/noimage.png';
import { markdownIt } from 'utils';
import styles from './styles.module.css';

export type ListCardProps = {
  title: string;
  imageUrl?: string;
  description?: string | ReactElement;
  footerDescription?: string | ReactElement;

  onClick?: () => void;
  noImage?: boolean;
  withFooterSeparator?: boolean;
};

export const ListCard: React.FC<ListCardProps> = ({
  title,
  onClick,
  imageUrl,
  description = '',
  footerDescription,
  noImage,
  withFooterSeparator,
}) => {
  return (
    <Card
      bordered={false}
      onClick={onClick}
      cover={noImage ? undefined : <Image preview={false} alt={title} src={imageUrl ?? NoImage} fallback={NoImage} />}
      className="rounded-8 overflow-hidden service-card mb-24 cursor-pointer"
    >
      <Meta
        title={title}
        className="mb-8"
        description={
          description && isValidElement(description) ? (
            description
          ) : (
            <div
              className={`text-gray-7 ${styles.description}`}
              dangerouslySetInnerHTML={{
                __html: markdownIt.render(description),
              }}
            />
          )
        }
      />
      {footerDescription && (
        <div className="mt-auto text-gray-8" style={{ position: 'relative' }}>
          {withFooterSeparator && <div className={styles.separator} />}
          {footerDescription}
        </div>
      )}
    </Card>
  );
};
