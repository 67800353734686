import React, { useState } from 'react';
import { Checkbox, Form, Switch } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { ServiceApp } from 'models';
import { URL_VALIDATOR } from '../../utils/constants';
import { StyledInput } from '../StyledInputs/StyledInput';

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

export const ApplicationForm: React.FC<ServiceApp & { checkedItem?: string }> = (application) => {
  const { name, link, checkedItem } = application;
  const [form] = useForm<Omit<ServiceApp, 'name'>>();
  const [showAllInfo, setShowAllInfo] = useState(!!link);

  const handleSwitchChange = (checked: boolean) => {
    setShowAllInfo(checked);
    if (!checked) {
      form.setFieldsValue({ isBeta: false, link: '', version: '' });
    }
  };

  const formName = `application-info-${name}`;

  return (
    <Form form={form} {...layout} initialValues={application} name={formName}>
      <Form.Item className="d-none" name="type">
        <StyledInput />
      </Form.Item>
      <Form.Item wrapperCol={{ className: 'bg-gray-9 pv-8 ph-16 rounded-8' }} label={name}>
        <div className="d-flex gap-8">
          <div className="d-flex gap-8 flex-items-center">
            <Switch checked={showAllInfo} onChange={handleSwitchChange} /> <span>Поддерживается</span>
          </div>
          {showAllInfo && (
            <div className="d-flex gap-8 flex-items-center">
              <Form.Item className="m-0" label="Текущая версия" rules={[{ required: true }]} name="version">
                <StyledInput />
              </Form.Item>
            </div>
          )}
        </div>
        {showAllInfo && (
          <div className="mt-8">
            <Form.Item className="mb-8" name="link" label="Ссылка" rules={[URL_VALIDATOR]}>
              <StyledInput />
            </Form.Item>
            <div className="d-flex">
              {!(checkedItem !== undefined && checkedItem !== formName) && <div className={'required'} />}
              <Form.Item name="isBeta" className="m-0" valuePropName="checked">
                <Checkbox disabled={checkedItem !== undefined && checkedItem !== formName}>
                  Основное приложение
                </Checkbox>
              </Form.Item>
            </div>
          </div>
        )}
      </Form.Item>
    </Form>
  );
};
