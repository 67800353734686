import { EntityHeader } from '../../components/EntityHeader';
import React from 'react';
import { Form, Layout, notification, Tabs } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import { cityTabs, newsTabKeys, TAB_PREFIX, useTabs } from '../../hooks/useTabs';
import { useSearchParams } from 'react-router-dom';
import { AdminCitiesAreasResponseDto, AdminNewsResponseDto } from '../../models';
import { FormHeader } from '../../components/FormHeader';
import { FormProviderProps } from 'antd/lib/form/context';
import { StyledInput } from '../../components/StyledInputs/StyledInput';
import { showFormErrorMessages } from '../../utils/showFormErrorMessages';

type Props = {
  cityData: AdminCitiesAreasResponseDto;
  onSave: (newDataForSave: AdminNewsResponseDto) => void;
};

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
export const CityBaseInfoForm: React.FC<{ initialValues: Exclude<AdminCitiesAreasResponseDto, 'id'> }> = ({
  initialValues,
}) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} initialValues={initialValues} {...layout} name="base-info">
      <Form.Item name="name" label="Город" rules={[{ required: true }]}>
        <StyledInput />
      </Form.Item>
    </Form>
  );
};

export const CityContent: React.FC<Props> = ({ cityData, onSave }) => {
  const [searchParams] = useSearchParams();
  const initialTab = searchParams.get(TAB_PREFIX)?.toString();
  const { activeTab, tabs, setActiveTab } = useTabs(cityTabs, initialTab);

  const newInitialValues = { ...cityData };

  const tabContent = {
    [newsTabKeys.common]: <CityBaseInfoForm initialValues={newInitialValues} />,
  };

  const onFormFinish: FormProviderProps['onFormFinish'] = async (_, { forms }) => {
    const errorMessage = () => notification.error({ message: 'Необходимо заполнить все поля корректно' });
    try {
      const validation = await Promise.allSettled(Object.values(forms).map((f) => f.validateFields()));

      const hasInvalidValues = validation.find((v) => v.status === 'rejected');

      if (!hasInvalidValues) {
        const data = {};
        Object.entries(forms).forEach(([_, form]) => {
          Object.assign(data, form.getFieldsValue());
        });
        onSave(data as AdminNewsResponseDto);
      } else {
        showFormErrorMessages(validation);
      }
    } catch (error) {
      errorMessage();
    }
  };

  return (
    <Form.Provider onFormFinish={onFormFinish}>
      <Layout>
        <EntityHeader title={cityData.name || 'Новый город'} />
        <Tabs items={tabs} activeKey={activeTab} onChange={setActiveTab} className="bg-neutral service-tabs pl-24" />
        <Content className="mt-40 p-16 rounded-16 bg-neutral">
          {tabs.map(({ key, label, contentClassName, formHeaderType }) => {
            return (
              <div key={key} className={contentClassName}>
                <FormHeader title={label} type={formHeaderType} />
                <div>{tabContent[key]}</div>
              </div>
            );
          })}
        </Content>
      </Layout>
    </Form.Provider>
  );
};
