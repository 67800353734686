import { getFrontendReview, getReviewForBackend, getReviewForFrontend } from 'components/Review/helpers';
import { TOurReview } from 'components/Review/OurReview';
import { BaseInfoFormInitialValues } from 'components/ServiceForms/BaseInfoForm/BaseInfoForm';
import { TechInfoFormInitialValues } from 'components/ServiceForms/TechInfoForm/TechInfoForm';
import { TSocialRole } from 'components/SocialForm/SocialForm';
import { GalleryItem, getGalleryItem, Image, ImageType } from 'hooks/useUploadImage';
import { AdminServiceDto, ServiceApp, ServiceListDto } from 'models';
import moment, { Moment } from 'moment';
import { AdminGetRolesAndNeedsForService } from 'pages/Service/typedef';
import { getMergedSocials, socialUrls } from 'socialUrls';
import mapSocialNetworksToApiSocials from '../../client/mappers/mapSocialNetworksToApiSocials';
import { getLastUploadedImageData } from '../../utils/fileHandlers';
import { formatPhoneNumber } from '../../utils/formatters/formatPhoneNumber';
import { parseArrayValuesForMappers } from '../../utils/parseArrayValuesForMappers';

export type FrontendService = {
  id?: number;
  age?: number;
  title: string;
  tags: Array<number>;
  needs: number[];
  sites: string[];
  priority: number;
  phones: string[];
  emails: string[];
  isBeta?: boolean;
  developer: string;
  functional: string;
  isTrusted?: boolean;
  releaseDate: Moment;
  review?: TOurReview;
  description: string;
  suitableFor: string;
  legalAddress: string;
  compatibility: string;
  screens: GalleryItem[];
  gallery: GalleryItem[];
  ageRequirement: number;
  socials: TSocialRole[];
  applications: ServiceApp[];
  logo?: { src?: string; id?: string };
} & { apps?: ServiceListDto['apps'] };

export const getFrontendService = ({
  id,
  apps,
  tags,
  title,
  isBeta,
  review,
  images,
  socials,
  contacts,
  priority,
  isTrusted,
  developer,
  functional,
  releaseDate,
  suitableFor,
  description,
  compatibility,
  ageRequirement,
  rolesData,
}: AdminServiceDto & {
  rolesData: AdminGetRolesAndNeedsForService[] | undefined;
}): FrontendService => {
  const sites = contacts.sites || [];
  const phones = contacts.phones || [];
  const emails = contacts.emails || [];
  const legalAddress = contacts.legalAddress?.[0] || '';
  const preparedImages: GalleryItem[] = ((images || []) as Image[]).map(getGalleryItem);

  const preparedNeeds =
    rolesData !== undefined
      ? rolesData.reduce((acc: { id: number; name: string }[], item) => {
          return [...acc, ...item.needs];
        }, [])
      : [];

  const icons = preparedImages.filter((i) => i.name.includes(ImageType.Icon));
  const screens = preparedImages.filter((i) => i.name.includes(ImageType.Screenshot));
  const galleryItems = preparedImages.filter((i) => i.name.includes(ImageType.Gallery));
  // TODO: Здесь используется первый элемент массива из-за того, что бэк так сортирует изображения у себя. В социальных ролях DESC, здесь ASC.
  const firstIcon = icons[0];
  const preparedLastIcon = { fullUrl: firstIcon?.url, id: +firstIcon?.uid };
  const preparedLogo = getLastUploadedImageData(preparedLastIcon);
  const preparedSocial = getMergedSocials(socials);

  const formattedApps = apps?.map((app) => {
    const foundAppByType = allApplications.find((appByType) => appByType.type === app.type);
    if (foundAppByType) return { ...app, name: foundAppByType.name };
    return app;
  });

  return {
    id,
    title,
    sites,
    isBeta,
    emails,
    phones,
    screens,
    isTrusted,
    legalAddress,
    ageRequirement,
    logo: preparedLogo,
    gallery: galleryItems,
    socials: preparedSocial,
    priority: priority || 10,
    developer: developer || '',
    tags: (tags || []).map((t) => t.id) || [],
    compatibility: compatibility || '',
    review: getReviewForFrontend(review),
    needs: (preparedNeeds || []).map((n) => n.id),
    functional: functional || '',
    description: description || '',
    suitableFor: suitableFor || '',
    releaseDate: releaseDate ? moment(releaseDate) : moment(),
    applications: [
      ...(formattedApps || []),
      ...allApplications.filter((a) => !(formattedApps || []).some((app) => app.type === a.type)),
    ],
  };
};

const allApplications: ServiceApp[] = [
  { name: 'iOS', version: '', isBeta: false, link: '', type: 'appStore' },
  { name: 'Android', version: '', isBeta: false, link: '', type: 'googlePlay' },
  { name: 'WEB Version', version: '', isBeta: false, link: '', type: 'homepage' },
  { name: 'Yandex', version: '', isBeta: false, link: '', type: 'yandex' },
  { name: 'VK mini App', version: '', isBeta: false, link: '', type: 'vkMiniApp' },
  { name: 'Чат-бот', version: '', isBeta: false, link: '', type: 'chatbot' },
];

export const getNewFrontendService = (): FrontendService => {
  const newFrontentService: FrontendService = {
    id: NaN,
    tags: [],
    needs: [],
    sites: [],
    phones: [],
    emails: [],
    gallery: [],
    screens: [],
    priority: 10,
    developer: '',
    functional: '',
    suitableFor: '',
    description: '',
    legalAddress: '',
    compatibility: '',
    ageRequirement: 0,
    socials: socialUrls,
    title: 'Новый сервис',
    review: getFrontendReview(),
    applications: allApplications,
    releaseDate: moment(new Date()),
  };
  return newFrontentService;
};

export const getTechInfoFormInitialValues = (
  serviceData: Optional<FrontendService>,
): Optional<TechInfoFormInitialValues> => {
  return serviceData
    ? {
        sites: serviceData.sites,
        phones: serviceData.phones,
        emails: serviceData.emails,
        socials: serviceData.socials,
        priority: serviceData.priority,
        developer: serviceData.developer,
        releaseDate: serviceData.releaseDate,
        applications: serviceData.applications,
        legalAddress: serviceData.legalAddress,
        compatibility: serviceData.compatibility,
      }
    : undefined;
};

export const getBaseInfoFormInitialValues = (
  serviceData: Optional<FrontendService>,
): Optional<BaseInfoFormInitialValues> => {
  return serviceData
    ? {
        title: serviceData.title,
        tags: serviceData.tags,
        isBeta: serviceData.isBeta,
        isTrusted: serviceData.isTrusted,
        functional: serviceData.functional,
        suitableFor: serviceData.suitableFor,
        description: serviceData.description,
        ageRequirement: serviceData.ageRequirement,
      }
    : undefined;
};

export const prepareServiceForBackend = (formFields: FrontendService) => {
  const {
    id,
    tags,
    apps,
    title,
    sites,
    needs,
    emails,
    phones,
    review,
    isBeta,
    socials,
    priority,
    isTrusted,
    developer,
    functional,
    releaseDate,
    legalAddress,
    description,
    suitableFor,
    compatibility,
    ageRequirement,
  } = formFields;

  const contacts = {
    sites: parseArrayValuesForMappers(sites),
    emails: parseArrayValuesForMappers(emails),
    phones: parseArrayValuesForMappers(phones.map((phone) => formatPhoneNumber(phone))),
    legalAddress: parseArrayValuesForMappers([legalAddress]),
  };

  const turndownFunctional = functional;
  const turndownSuitableFor = suitableFor;
  const turndownDescription = description;

  // apps, review, socials
  const returnObject = {
    tags: tags.every((item) => isNaN(item))
      ? tags?.map((item) => {
          // @ts-ignore: fix types later
          return +item.value;
        })
      : tags,
    // apps,
    needs,
    title,
    isBeta,
    contacts,
    priority: Number(priority),
    isTrusted,
    developer: developer !== '' ? developer : undefined,
    compatibility: compatibility.length >= 1 ? compatibility : undefined,
    ageRequirement,
    id: id === undefined || isNaN(id) ? undefined : id,
    releaseDate: moment(releaseDate).isValid() ? releaseDate.toDate() : undefined,
    // review: getReviewForBackend(review),
    functional: turndownFunctional.length >= 1 ? turndownFunctional : null,
    description: turndownDescription,
    suitableFor: turndownSuitableFor.length >= 1 ? turndownSuitableFor : null,
    // socials: mapSocialNetworksToApiSocials(socials),
  };

  // @ts-ignore: add types later
  if (getReviewForBackend(review) !== null) returnObject.review = getReviewForBackend(review);
  // @ts-ignore: add types later
  if (apps?.length > 0) returnObject.apps = apps;
  // @ts-ignore: add types later
  if (mapSocialNetworksToApiSocials(socials).length > 0) returnObject.socials = mapSocialNetworksToApiSocials(socials);

  return returnObject;
};
