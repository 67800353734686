import { Form } from 'antd';
import React, { FC } from 'react';
import useAsyncSelectOptions from '../../hooks/useAsyncSelectOptions';
import { ClientRole, useSocialRolesList } from '../../hooks/useSocialRolesList';
import { RolesListResponse, RolesResponseDto } from '../../models';
import { NeedRole } from '../../pages/Need/Need';
import { BASE_ROLES_SEARCH_URL } from '../../utils';
import { DebouncedSelect } from '../DebouncedSelect';
import { FormList } from '../FormList';

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

export type LinkedRolesFormProps = {
  initialValues: { socialRoles: Array<NeedRole> };
};

const rolesMapper = (data: RolesListResponse['data'] | ClientRole[]) => {
  return data.map((item) => {
    return {
      label: item.name,
      key: item.id,
      value: item.name,
    };
  });
};
export const LinkedRolesForm: FC<LinkedRolesFormProps> = ({ initialValues }) => {
  const [form] = Form.useForm<LinkedRolesFormProps['initialValues']>();

  const { roles } = useSocialRolesList();

  const { fetcher } = useAsyncSelectOptions<RolesResponseDto>(BASE_ROLES_SEARCH_URL, rolesMapper);
  const defaultValue = { value: '', label: '' };
  const mappedRoles = rolesMapper(roles);
  return (
    <Form name="socialRoles" {...layout} form={form} initialValues={initialValues}>
      <FormList
        label="Выбранные"
        name="socialRoles"
        defaultValue={defaultValue}
        customController={<DebouncedSelect fetcher={fetcher} initialOptions={mappedRoles} labelInValue={true} />}
      />
    </Form>
  );
};
