import React from 'react';

type NotFoundErrorProps = {
  errorCode: 400 | 404 | 500 | 401;
};

const getErrorTextByErrorCode = (errorCode: NotFoundErrorProps['errorCode']) => {
  if (errorCode === 400) {
    return `Ошибка 400. Некорректный запрос`;
  }
  if (errorCode === 404) {
    return `Ошибка 404. Страница не найдена`;
  }
  if (errorCode === 500) {
    return `Ошибка 500. Произошла ошибка на сервере. Попробуйте позднее.`;
  }
  if (errorCode === 401) {
    return 'Ошибка 401. Вы не авторизованы.';
  } else {
    return 'Произошла непредвиденная ошибка';
  }
};

export const ClientErrorException = ({ errorCode }: NotFoundErrorProps) => {
  return (
    <h2 style={{ height: '50vh' }} className={' mt-40 d-flex text-align-center flex-justify-center flex-items-center '}>
      {getErrorTextByErrorCode(errorCode)}
    </h2>
  );
};
