import { Checkbox, Form, Radio } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { StyledInputNumber } from 'components/StyledInputs/StyledInputNumber';
import { TextEditor } from 'components/TextEditor';
import { NEW_BODY_MAX_LENGTH } from '../../constants';
import React, { useState } from 'react';
import { FrontendNewItem } from '../../pages/NewItem/types';
import { URL_VALIDATOR } from '../../utils/constants';
import { CitySelect } from '../CitySelect';
import { StyledInput } from '../StyledInputs/StyledInput';
import styles from './styles.module.css';

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

type TPlaceBaseInfoFormProps = { initialValues: FrontendNewItem };

type Tab = (typeof radioValues)[keyof typeof radioValues];

const radioValues = {
  city: 'city',
  allCities: 'allCities',
} as const;

const radioButtons = [
  { value: radioValues.allCities, title: 'Все города' },
  { value: radioValues.city, title: 'Один город' },
];

export const NewItemBaseInfoForm: React.FC<TPlaceBaseInfoFormProps> = ({ initialValues }) => {
  const [form] = Form.useForm();
  const [radio, setRadio] = useState<Tab>(initialValues?.areaId ? radioValues.city : radioValues.allCities);

  const handleCheckboxChange = (e: CheckboxChangeEvent) => {
    form.setFieldValue('isPinned', e.target.checked);
  };

  return (
    <Form form={form} initialValues={initialValues} {...layout} name="base-info">
      <Form.Item name="title" label="Заголовок новости" rules={[{ required: true }]}>
        <StyledInput />
      </Form.Item>
      <Form.Item
        label="Приоритет"
        name="priority"
        rules={[{ required: true, message: 'Пожалуйста, введите Приоритет' }]}
      >
        <Form.Item name="priority">
          <StyledInputNumber max={30} min={1} maxWidth />
        </Form.Item>
        <div className="text-gray-8 mt-16">Минимальное значение – 1, максимальное – 30</div>
      </Form.Item>
      <Form.Item name="announce" label="Анонс новости" rules={[{ required: true }]}>
        <StyledInput.TextArea />
      </Form.Item>
      <Form.Item name="body" label="Тело новости" rules={[{ required: true }]}>
        <TextEditor maxSymbols={NEW_BODY_MAX_LENGTH} />
      </Form.Item>
      <Form.Item name="sourceUrl" label="Источник (URL)" rules={[URL_VALIDATOR]}>
        <StyledInput />
      </Form.Item>
      <Form.Item name="isPinned" label="Прикреплённая" rules={[{ required: false }]}>
        <Checkbox
          defaultChecked={initialValues.isPinned !== undefined ? initialValues.isPinned : true}
          onChange={handleCheckboxChange}
        />
      </Form.Item>

      <Form.Item className={styles.cityContainer}>
        <Radio.Group value={radio} className="d-flex flex-grow-1" onChange={(e) => setRadio(e.target.value)}>
          {radioButtons.map(({ value, title }) => (
            <Radio.Button key={value} className="flex-grow-1 text-align-center" value={value}>
              {title}
            </Radio.Button>
          ))}
        </Radio.Group>
        {radio === radioValues.city ? <CitySelect isMultiple={false} /> : null}
      </Form.Item>
    </Form>
  );
};
