import { AdminGetOneNotificationResponse } from 'models';
import moment from 'moment';
import { mapCities } from 'pages/Author/mappers';

export function convertToYYYYMMDDWithMoment(isoDateString: string) {
  const date = moment(isoDateString);

  return date.format('YYYY-MM-DD');
}

function convertToHHMMWithMoment(timestamp: string) {
  const time = moment(timestamp);

  return time.format('HH:mm');
}

// eslint-disable-next-line
export const preparePushForBackend = (pushFields: any) => {
  const { body, areasIds, date, entityId, entityType, rolesIds, time, title } = pushFields;

  return {
    body,
    entityType,
    title,
    // eslint-disable-next-line
    rolesIds: rolesIds.map((item: any) => item.key),
    entityId: entityId.key,
    date: convertToYYYYMMDDWithMoment(date),
    time: convertToHHMMWithMoment(time),
    // @ts-ignore: temp
    areasIds: areasIds.some((item) => item.label) ? areasIds.map((item) => item.key) : areasIds,
  };
};

export const mapPushForFrontend = (pushItem: AdminGetOneNotificationResponse) => {
  const { id, body, date, entity, entityType, roles, time, title, areas } = pushItem;

  const formattedDate = moment(date);

  const formattedTime = moment(`${date} ${time}`, 'YYYY-MM-DD HH:mm');

  return {
    id,
    body,
    date: formattedDate,
    // @ts-ignore: temp
    entityId: { value: entity.id, label: entity.name, key: entity.id },
    entityType,
    rolesIds: mapCities(roles),
    time: formattedTime,
    title,
    areasIds: areas.map((item) => item.id),
    areasNames: mapCities(areas),
  };
};
