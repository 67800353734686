import { Form } from 'antd';
import { rolesMapper } from 'pages/Author/mappers';
import React, { FC } from 'react';
import useAsyncSelectOptions from '../../hooks/useAsyncSelectOptions';
import { useSocialRolesList } from '../../hooks/useSocialRolesList';
import { RolesInServiceResponseDto, RolesResponseDto } from '../../models';
import { BASE_ROLES_SEARCH_URL } from '../../utils';
import { DebouncedSelect } from '../DebouncedSelect';
import { FormList } from '../FormList';

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

export type LinkedRolesFormProps = {
  initialValues: { socialRoles?: Array<RolesInServiceResponseDto> };
};
export const AuthorRoles: FC<LinkedRolesFormProps> = ({ initialValues }) => {
  const [form] = Form.useForm<LinkedRolesFormProps['initialValues']>();

  const { roles } = useSocialRolesList();

  const { fetcher } = useAsyncSelectOptions<RolesResponseDto>(BASE_ROLES_SEARCH_URL, rolesMapper);
  const defaultValue = { value: '', label: '' };
  const mappedRoles = rolesMapper(roles);

  const initialMappedValues = { socialRoles: rolesMapper(initialValues.socialRoles) };

  return (
    <Form name="socialRoles" {...layout} form={form} initialValues={initialMappedValues}>
      <FormList
        label="Выбранные"
        name="socialRoles"
        defaultValue={defaultValue}
        customController={<DebouncedSelect fetcher={fetcher} initialOptions={mappedRoles} labelInValue={true} />}
      />
    </Form>
  );
};
