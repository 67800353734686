import React, { useMemo, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { htmlToMarkdown, markdownToHtml } from '../../utils/markdownParser';
import styles from './styles.module.css';

const modules = {
  toolbar: [[{ header: [1, 2, false] }, 'bold', 'italic', { list: 'ordered' }, { list: 'bullet' }, 'link']],
};

const formats = [
  'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'code',
];

interface OnChangeHandler {
  (value: string): void;
}

type Props = {
  value?: string;
  placeholder?: string;
  onChange?: OnChangeHandler;
  maxSymbols?: number;
  // eslint-disable-next-line
  modules?: any;
};

export const TextEditor: React.FC<Props> = ({ value, onChange, placeholder, maxSymbols, modules: customModules }) => {
  const initialLimitState = useMemo(() => {
    if (value && maxSymbols) {
      const descriptionText = value;
      return descriptionText.length > maxSymbols;
    }
    return false;
  }, [maxSymbols, value]);

  const [maxSymbolLimit, setMaxSymbolLimit] = useState(initialLimitState);

  return (
    <>
      <ReactQuill
        theme="snow"
        value={value ? markdownToHtml(value) : ''}
        modules={customModules ?? modules}
        formats={formats}
        onChange={(value: string) => {
          if (onChange) onChange(htmlToMarkdown(value));
        }}
        onKeyUp={(event) => {
          const value = htmlToMarkdown(event.target.innerHTML);

          if (maxSymbols) {
            if (value.length > maxSymbols) {
              setMaxSymbolLimit(true);
            } else setMaxSymbolLimit(false);
          }
        }}
        className={`quill-custom ${maxSymbolLimit ? styles.error : ''}`}
        placeholder={placeholder}
      />
      {maxSymbolLimit && (
        <div className="ant-form-item-explain-error">
          Максимальное количество символов не должно превышать {maxSymbols}
        </div>
      )}
    </>
  );
};
