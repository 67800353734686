import { useContext } from 'react';
import { UserContext } from '../contexts/UserContext';
import useAxios from 'axios-hooks';
import { TagDto } from '../models';
import { Index } from '../client/endpoints';

export type TFormats = { formats: TagDto[]; refetchFormats: () => void };
export const useFormats = (): TFormats => {
  const isAuthorized = typeof useContext(UserContext).user !== 'undefined';
  const [{ data }, refetchFormats] = useAxios<BaseResponse<TagDto[]>>(
    { url: Index.adminEvents + '/info/formats?limit=20', method: 'GET' },
    { manual: !isAuthorized },
  );

  return { formats: data?.data || [], refetchFormats };
};
