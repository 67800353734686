import { Checkbox, Form } from 'antd';
import { StyledInput } from '../../StyledInputs/StyledInput';
import React from 'react';
import { URL_VALIDATOR } from '../../../utils/constants';
import { DebouncedSelect } from '../../DebouncedSelect';
import { RolesResponseDto } from '../../../models';
import useAsyncSelectOptions from '../../../hooks/useAsyncSelectOptions';
import { Index } from '../../../client/endpoints';
import { mapDadataResponse } from '../../../utils/mapDadataResponse';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { SiteFormValues } from '../../../pages/Event/Event.types';
import { useThematics } from '../../../hooks/useThematics';
import { useFormats } from '../../../hooks/useFormats';

const layout = {
  labelCol: { span: 12 },
  wrapperCol: { span: 14 },
};
export const InfoForSiteForm: React.FC<{ initialValues: SiteFormValues }> = ({ initialValues }) => {
  const [form] = Form.useForm();

  const { thematics } = useThematics();
  const { formats } = useFormats();

  const { fetcher: thematicsFetcher } = useAsyncSelectOptions<RolesResponseDto>(
    Index.adminEvents + '/info/thematics?query=',
    mapDadataResponse,
  );
  const { fetcher: formatsFetcher } = useAsyncSelectOptions<RolesResponseDto>(
    Index.adminEvents + '/info/thematics?query=',
    mapDadataResponse,
  );

  const handleCheckboxChange = (e: CheckboxChangeEvent) => {
    form.setFieldValue('showVideoInNewWindow', e.target.checked);
  };

  return (
    <Form form={form} {...layout} name="info-for-site" initialValues={initialValues}>
      <Form.Item name="thematics" label="Тематики">
        <DebouncedSelect
          fetcher={thematicsFetcher}
          initialOptions={mapDadataResponse(thematics)}
          labelInValue={true}
          mode="multiple"
        />
      </Form.Item>
      <Form.Item name="formats" label="Форматы">
        <DebouncedSelect
          fetcher={formatsFetcher}
          initialOptions={mapDadataResponse(formats)}
          labelInValue={true}
          mode="multiple"
        />
      </Form.Item>
      <Form.Item name="organizer" label="Организатор">
        <StyledInput />
      </Form.Item>
      <Form.Item name="organizerUrl" label="Ссылка на организатора" rules={[{ ...URL_VALIDATOR, required: false }]}>
        <StyledInput />
      </Form.Item>
      <Form.Item name="streamUrl" label="Ссылка на трансляцию" rules={[{ ...URL_VALIDATOR, required: false }]}>
        <StyledInput />
      </Form.Item>
      <Form.Item
        name="registerUrl"
        label="Ссылка на регистрацию на мероприятие"
        rules={[{ ...URL_VALIDATOR, required: false }]}
      >
        <StyledInput />
      </Form.Item>
      <Form.Item
        name="showVideoInNewWindow"
        label="Воспроизводить трансляцию в новом окне"
        rules={[{ required: false }]}
      >
        <Checkbox
          defaultChecked={initialValues.showVideoInNewWindow !== undefined ? initialValues.showVideoInNewWindow : true}
          onChange={handleCheckboxChange}
        />
      </Form.Item>
    </Form>
  );
};
