import { Layout, Pagination, Row, Spin } from 'antd';
import { NAVIGATION } from 'paths';
import { PageTitle } from 'components/PageTitle';
import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Content } from 'antd/lib/layout/layout';
import { NotFoundSearchResults } from 'components/NotFoundSearchResults';
import { ListCard } from 'components/ListCard';
import { useDebounce, useListing } from 'hooks';
import { NewsListDto } from 'models';
import { getNewsEndpointUrl } from 'client/endpoints/news';
import { StyledInput } from 'components/StyledInputs/StyledInput';

export const News = () => {
  const push = useNavigate();
  const [searchValue, setSearchValue] = useState('');

  const handleSearchInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const searchName = e.target.value;
    setSearchValue(searchName);
  }, []);

  const debouncedSearchValue = useDebounce(searchValue, 1000);

  const { data, paginationProps, loading } = useListing<NewsListDto>(getNewsEndpointUrl(debouncedSearchValue));

  return (
    <div>
      <PageTitle title="Новости" wrapperClassName="mb-24" onAddButtonClick={() => push(`${NAVIGATION.news}/new`)} />
      <Layout>
        <Content>
          <div className="p-24 mt-40 bg-neutral mb-24 rounded-8">
            <StyledInput value={searchValue} onChange={handleSearchInputChange} placeholder="Название новости" />
          </div>
          <Row className="gap-16" justify={loading ? 'center' : undefined}>
            {data && data.data.length < 1 && !loading && <NotFoundSearchResults />}
            {data === undefined || loading ? (
              <Spin className="flex-justify-center" size="large" />
            ) : (
              data.data.map(({ id, title, announce, image }) => (
                <ListCard
                  key={id}
                  imageUrl={image ?? undefined}
                  title={title || ''}
                  description={announce}
                  onClick={() => push(`${NAVIGATION.news}/${id}`)}
                />
              ))
            )}
          </Row>
          {data?.meta.total ? (
            <div className="d-flex flex-justify-end">
              <Pagination
                {...paginationProps}
                total={data.meta.total}
                pageSizeOptions={[8, 12, 16, 20]}
                defaultCurrent={paginationProps.current}
              />
            </div>
          ) : null}
        </Content>
      </Layout>
    </div>
  );
};
