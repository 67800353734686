import { PlaceScheduleDays } from '../../../models';
import { ScheduleDay } from '../../../components/Schedule';
import { timeFormat } from 'components/Schedule/ScheduleItem';
import { getDateFromHoursAndMinutes, getShortDateWithEnglishLocale } from '../../../utils';

export const serverPlaceScheduleToClientPlaceSchedule = (schedule: Array<PlaceScheduleDays>): ScheduleDay => {
  return schedule.reduce((obj, item) => {
    return {
      ...obj,
      [item.dayTitle]: {
        enabled: item.isActive,
        break: [
          getDateFromHoursAndMinutes(item.breakTimeStartAt) ?? undefined,
          getDateFromHoursAndMinutes(item.breakTimeEndAt) ?? undefined,
        ],
        hours: [
          getDateFromHoursAndMinutes(item.workTimeStartAt) ?? undefined,
          getDateFromHoursAndMinutes(item.workTimeEndAt) ?? undefined,
        ],
      },
    };
  }, {});
};

const getFormatedTime = (moment?: moment.Moment) => {
  return moment?.format(timeFormat);
};

export const clientPlaceScheduleToServerPlaceSchedule = (clientSchedule: ScheduleDay): Array<PlaceScheduleDays> => {
  const mappedDataArray = [];
  for (const dayTitle in clientSchedule) {
    const scheduleByKey = clientSchedule[dayTitle];

    const isActive = scheduleByKey.enabled;

    const workTimeEndAt = getFormatedTime(scheduleByKey.hours?.[1]);
    const breakTimeEndAt = getFormatedTime(scheduleByKey.break?.[1]);
    const workTimeStartAt = getFormatedTime(scheduleByKey.hours?.[0]);
    const breakTimeStartAt = getFormatedTime(scheduleByKey.break?.[0]);

    const mappedData: PlaceScheduleDays = {
      isActive,
      dayTitle,
      workTimeEndAt,
      breakTimeEndAt,
      workTimeStartAt,
      breakTimeStartAt,
    };

    mappedDataArray.push(mappedData);
  }

  return mappedDataArray;
};

export const clientEventScheduleScheduleToServerEventSchedule = (
  clientSchedule: ScheduleDay,
): Array<PlaceScheduleDays> => {
  const mappedDataArray = [];
  for (const dayTitle in clientSchedule) {
    const scheduleByKey = clientSchedule[dayTitle];
    const parsedDate = new Date(dayTitle);
    parsedDate.setUTCHours(0, 0, 0, 0);
    parsedDate.setDate(parsedDate.getUTCDate() + 1);
    const parsedToISOStringDate = parsedDate.toISOString();
    const isActive = scheduleByKey.enabled;

    const workTimeEndAt = getFormatedTime(scheduleByKey.hours?.[1]);
    const breakTimeEndAt = getFormatedTime(scheduleByKey.break?.[1]);
    const workTimeStartAt = getFormatedTime(scheduleByKey.hours?.[0]);
    const breakTimeStartAt = getFormatedTime(scheduleByKey.break?.[0]);

    const mappedData: PlaceScheduleDays = {
      isActive,
      dayTitle: parsedToISOStringDate,
      workTimeEndAt,
      breakTimeEndAt,
      workTimeStartAt,
      breakTimeStartAt,
    };

    mappedDataArray.push(mappedData);
  }

  return mappedDataArray;
};

export const serverEventScheduleToClientEventSchedule = (schedule: Array<PlaceScheduleDays>): ScheduleDay => {
  return schedule.reduce((obj, item) => {
    const date = new Date(item.dayTitle);
    return {
      ...obj,
      [getShortDateWithEnglishLocale(date)]: {
        enabled: item.isActive,
        break: [
          getDateFromHoursAndMinutes(item.breakTimeStartAt) ?? undefined,
          getDateFromHoursAndMinutes(item.breakTimeEndAt) ?? undefined,
        ],
        hours: [
          getDateFromHoursAndMinutes(item.workTimeStartAt) ?? undefined,
          getDateFromHoursAndMinutes(item.workTimeEndAt) ?? undefined,
        ],
      },
    };
  }, {});
};
