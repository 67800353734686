import { Spin } from 'antd';
import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { SelectProps } from 'antd/lib/select';
import debounce from 'lodash.debounce';
import { StyledSelectComponent } from '../StyledSelect';

export type SelectOptions = SelectProps['options'];

export type DebouncedSelectProps = {
  debounceTimeout?: number;
  initialOptions?: SelectOptions;
  fetcher: (value: string) => Promise<SelectOptions>;
  resetOptions?: boolean;
  currentPlaceId?: number;
} & SelectProps;

export const DebouncedSelect: FC<DebouncedSelectProps> = ({
  fetcher,
  initialOptions = [],
  debounceTimeout = 800,
  labelInValue = false,
  resetOptions = false,
  currentPlaceId,
  ...props
}) => {
  const parsedInitialOptions = currentPlaceId
    ? initialOptions.filter((item) => item.value !== currentPlaceId)
    : initialOptions;
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState<SelectOptions>(parsedInitialOptions);
  const [searchQuery, setSearchQuery] = useState('');
  const fetchRef = useRef(0);

  useEffect(() => {
    // Reset options when resetOptions prop changes
    if (resetOptions) {
      setOptions(parsedInitialOptions);
    }
  }, [resetOptions, parsedInitialOptions]);

  const debounceFetcher = useMemo(() => {
    const loadOptions = async (value: string) => {
      setSearchQuery(value);
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);
      if (value.length < 1) {
        if (currentPlaceId) setOptions(parsedInitialOptions);
        else setOptions(parsedInitialOptions);
        setFetching(false);
        return;
      }
      const newOptions = await fetcher(value);
      if (fetchId !== fetchRef.current) {
        // for fetch callback order
        return;
      }

      if (newOptions !== undefined) {
        if (currentPlaceId) setOptions(newOptions.filter((item) => item.value !== currentPlaceId));
        else setOptions(newOptions);
      }

      setFetching(false);
    };

    return debounce(loadOptions, debounceTimeout);
  }, [debounceTimeout, fetcher, parsedInitialOptions, currentPlaceId]);

  const initialFetcher = () => {
    setOptions(parsedInitialOptions);
  };

  useEffect(() => {
    if (searchQuery === '') setOptions(parsedInitialOptions);
  }, [parsedInitialOptions, searchQuery]);

  return (
    <StyledSelectComponent
      maxLength={100}
      onSearch={debounceFetcher}
      labelInValue={labelInValue}
      filterOption={false}
      onClick={initialFetcher}
      showSearch
      optionLabelProp="label"
      notFoundContent={
        fetching ? <Spin size="small" /> : <p className="p-0 m-0">По вашему запросу ничего не найдено</p>
      }
      options={options}
      {...props}
    />
  );
};
