import { Spin } from 'antd';
import { useVideosBackendActions } from 'hooks/useVideosBackendActions';
import { VideoContent } from 'pages/Video/VideoContent';
import React from 'react';
import { useParams } from 'react-router-dom';
import { ClientErrorException } from '../../components/ClientErrorException';
import { mapVideoForFrontend } from './mappers';

export const VideoItem = () => {
  const { id } = useParams<{ id: string }>();

  const {
    info: { videoData, error, loading, isLoading },
    actions: { onSave },
  } = useVideosBackendActions({ id });

  if (error) {
    return <ClientErrorException errorCode={error.request.status} />;
  }

  return (
    <Spin spinning={isLoading || loading}>
      {videoData && !loading && <VideoContent videoData={mapVideoForFrontend(videoData)} onSave={onSave} />}
    </Spin>
  );
};
