export const getTextEditorValidationError = (
  // eslint-disable-next-line
  forms: any,
  constantValue: number,
  fieldValue = 'description',
  formName = 'base-info',
) => {
  const descriptionText = forms[formName].getFieldValue(fieldValue);
  return descriptionText.length > constantValue;
};
