import Axios, { AxiosRequestHeaders } from 'axios';
import { configure } from 'axios-hooks';
export const axios = Axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

configure({ axios, cache: false });

export const setAxiosRequestHeaders = (headers: AxiosRequestHeaders) => {
  axios.interceptors.request.use((config) => {
    config.headers = {
      ...config.headers,
      ...headers,
    };
    return config;
  });

  configure({ axios });
};
