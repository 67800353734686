import { Form, Layout, notification } from 'antd';
import { RcFile } from 'antd/es/upload';
import { FormProviderProps } from 'antd/lib/form/context';
import { Content } from 'antd/lib/layout/layout';
import { PushBaseInfoForm } from 'components/PushBaseInfoForm';
import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { EntityHeader } from '../../components/EntityHeader';
import { FormHeader } from '../../components/FormHeader';
import { TabsWithButtons } from '../../components/TabsWithButtons/TabsWithButtons';
import { authorsTabKeys, pushTabs, TAB_PREFIX, useTabs } from '../../hooks/useTabs';

type Props = {
  // eslint-disable-next-line
  pushData: any;
  // eslint-disable-next-line
  onSave: (newDataForSave: any, file?: string | Blob | RcFile) => void;
};

export const PushContent: React.FC<Props> = ({ pushData, onSave }) => {
  const [searchParams] = useSearchParams();
  const initialTab = searchParams.get(TAB_PREFIX)?.toString();
  const { activeTab, tabs, setActiveTab } = useTabs(pushTabs, initialTab);

  const newInitialValues = { ...pushData };

  const tabContent = {
    [authorsTabKeys.common]: <PushBaseInfoForm initialValues={newInitialValues} />,
  };

  const onFormFinish: FormProviderProps['onFormFinish'] = async (_, { forms }) => {
    const errorMessage = () => notification.error({ message: 'Необходимо заполнить все поля корректно' });
    try {
      const validation = await Promise.allSettled(Object.values(forms).map((f) => f.validateFields()));

      const hasInvalidValues = validation.find((v) => v.status === 'rejected');

      // @ts-ignore: later
      const socialRoles = validation.find((v) => v?.value?.socialRoles);

      // @ts-ignore: later
      if (socialRoles && socialRoles?.value?.socialRoles?.length === 0)
        return notification.error({
          message: 'Не все поля заполнены корректно:',
          description: (
            <>
              • Связанные роли
              <br />
            </>
          ),
        });

      if (!hasInvalidValues) {
        const data = {};
        Object.entries(forms).forEach(([_, form]) => {
          Object.assign(data, form.getFieldsValue());
        });
        // eslint-disable-next-line
        onSave(data as any);
      }
    } catch (error) {
      errorMessage();
    }
  };

  return (
    <Form.Provider onFormFinish={onFormFinish}>
      <Layout>
        <EntityHeader title={pushData.title || 'Создать пуш'} />
        <TabsWithButtons filteredTabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />
        <Content className="mt-40 p-16 rounded-16 bg-neutral">
          {tabs.map(({ key, label, contentClassName, formHeaderType }) => {
            return (
              <div key={key} className={contentClassName}>
                <FormHeader title={label} type={formHeaderType} />
                <div>{tabContent[key]}</div>
              </div>
            );
          })}
        </Content>
      </Layout>
    </Form.Provider>
  );
};
