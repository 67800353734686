import { Index } from './index';

export const getNewsEndpointUrl = (query = '') => {
  return query.length > 2 ? `${Index.news}/?query=${query}` : `${Index.news}?`;
};

export const getNewDataByIdEndpointUrl = (id?: string) => ({ url: `${Index.news}/${id}`, method: 'GET' });
export const deleteNewEndpointUrl = (id?: string) => ({ url: `${Index.news}/${id}`, method: 'DELETE' });
export const updateNewEndpointUrl = (id?: string) => ({ url: `${Index.news}/${id}`, method: 'PATCH' });
export const createNewEndpointUrl = () => ({ url: Index.news, method: 'POST' });
