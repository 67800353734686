import React, { useContext } from 'react';
import { Button, Form, Input, Typography } from 'antd';
import { UserContext } from 'contexts/UserContext';
import { FORM_PHONE_MASKED_INPUT_PROPS, PHONE_VALIDATOR } from '../../utils/constants';
import { PatternFormat } from 'react-number-format';
import styles from './styles.module.css';

export const AuthorizeForm = () => {
  const { authorize } = useContext(UserContext);
  return (
    <div className="w-100vh h-100vh d-flex flex-items-center flex-justify-center">
      <Form
        className="authorization-form"
        layout="vertical"
        onFinish={(values) => {
          authorize(values.login, values.password);
        }}
      >
        <Typography.Title level={2} className="text-white">
          Авторизация
        </Typography.Title>
        <Form.Item label="Номер телефона" name="login" rules={[PHONE_VALIDATOR]} className={styles.colorSecondary}>
          <PatternFormat {...FORM_PHONE_MASKED_INPUT_PROPS} customInput={Input} size="middle" />
        </Form.Item>
        <Form.Item label="Пароль" name="password" rules={[{ required: true }]} className={styles.colorSecondary}>
          <Input.Password allowClear />
        </Form.Item>
        <Form.Item className="d-flex flex-justify-end">
          <Button type="primary" htmlType="submit">
            Войти
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};
