import { notification } from 'antd';
import { RcFile } from 'antd/es/upload';
import useAxios from 'axios-hooks';
import { axios } from 'client';
import { ImageEntity } from 'hooks/useUploadImage';
import { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  createNewEndpointUrl,
  deleteNewEndpointUrl,
  getNewDataByIdEndpointUrl,
  updateNewEndpointUrl,
} from '../client/endpoints/news';
import { AdminNewsResponseDto } from '../models';
import { getNewData, mapNewForBackend } from '../pages/NewItem/mappers';
import { FrontendNewItem } from '../pages/NewItem/types';
import { NAVIGATION } from '../paths';

type Props = {
  isCreationMode: boolean;
  id?: string;
};

export const useNewsBackendActions = ({ isCreationMode, id }: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const [{ data, error, loading }] = useAxios<BaseResponse<AdminNewsResponseDto>>(getNewDataByIdEndpointUrl(id), {
    manual: isCreationMode,
  });

  const deleteNew = useAxios<BaseResponse<AdminNewsResponseDto>>(deleteNewEndpointUrl(id), { manual: true })[1];

  const updateNewData = useAxios(updateNewEndpointUrl(id), { manual: true })[1];

  const saveNewData = useAxios(createNewEndpointUrl(), { manual: true })[1];

  const newData = useMemo(() => {
    if (isCreationMode) {
      return getNewData();
    } else if (data !== undefined) {
      // eslint-disable-next-line
      return data?.data as any;
    }
  }, [isCreationMode, data]);

  const handleDeleteNew = useCallback(async () => {
    setIsLoading(true);
    try {
      await deleteNew();
      notification.success({
        message: `Новость "${newData?.title}" удалена`,
      });
      navigate(NAVIGATION.news);
    } catch (error) {
      notification.error({ message: `Извините, произошла ошибка` });
      setIsLoading(false);
    }
  }, [deleteNew, navigate, newData?.title]);

  const onSave = useCallback(
    async (formFields: FrontendNewItem, file?: string | Blob | RcFile) => {
      try {
        setIsLoading(true);
        const query = isCreationMode ? saveNewData : updateNewData;

        const mappedFormFields = mapNewForBackend(formFields);

        const queryResult = await query({ data: mappedFormFields });

        if (isCreationMode && file) {
          const newId = queryResult.data.data.id;

          const data = new FormData();
          data.append('image', file as string);

          await axios.patch(`/admin/v1/${ImageEntity.News}/${newId}/image`, data, {
            headers: { 'Content-Type': 'multipart/form-data' },
          });
        }

        notification.success({ message: isCreationMode ? 'Новость создана' : 'Новость обновлена' });
        navigate(NAVIGATION.news);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        notification.error({ message: 'Извините, при сохранении новости произошла ошибка' });
      }
    },
    [isCreationMode, navigate, saveNewData, updateNewData],
  );

  return useMemo(
    () => ({
      info: {
        data,
        error,
        loading,
        isLoading,
        newData,
      },
      actions: {
        handleDeleteNew: handleDeleteNew,
        onSave,
      },
    }),
    [data, error, handleDeleteNew, isLoading, loading, newData, onSave],
  );
};
