import { Tabs } from 'antd';
import { FormHeaderButtons } from '../FormHeaderButtons';
import React from 'react';
import { Tab } from '../../hooks/useTabs';
import styles from './styles.module.css';

type Props = {
  filteredTabs: Tab[];
  activeTab: string;
  setActiveTab: (tab: string) => void;
  onLeftButtonClick?: () => void;
  onRightButtonClick?: () => void;
};

export const TabsWithButtons: React.FC<Props> = ({
  filteredTabs,
  activeTab,
  setActiveTab,
  onLeftButtonClick,
  onRightButtonClick,
}) => {
  return (
    <div className={`d-flex flex-justify-between ${styles.container}`}>
      <Tabs
        items={filteredTabs}
        activeKey={activeTab}
        onChange={setActiveTab}
        className="bg-neutral service-tabs pl-24 w-100"
      />
      <FormHeaderButtons onLeftButtonClick={onLeftButtonClick} onRightButtonClick={onRightButtonClick} />
    </div>
  );
};
