import { FrontendNewItem } from './types';
import { CityResponseDto } from '../../models';

export const getNewData = (): FrontendNewItem => ({
  id: NaN,
  category: null,
  title: '',
  announce: '',
  image: null,
  sourceUrl: null,
  body: '',
  isFavorite: false,
  createdAt: '',
  roles: null,
  areaId: NaN,
  isPinned: false,
  priority: 1,
});

// eslint-disable-next-line
export const mapNewForFrontend = (data: any): any => {
  const resultData = { ...data };
  const areas = resultData.areas as CityResponseDto[];
  if (resultData.areas?.length === 1) {
    resultData.areaId = areas[0].id;
    resultData.areaName = areas[0].name;
  }
  // if (resultData.roles) {
  //   resultData.socialRoles = resultData.roles;
  // }
  delete resultData.areas;
  return resultData;
};

export const mapNewForBackend = (formFields: FrontendNewItem) => {
  // eslint-disable-next-line
  const resultObject = { ...formFields, isAllAreas: false } as any;

  if (formFields.areaId) {
    resultObject.areas = [formFields.areaId];
    delete resultObject.areaName;
    delete resultObject.areaId;
  } else resultObject.isAllAreas = true;

  if (formFields.socialRoles) {
    // eslint-disable-next-line
    resultObject.roles = formFields.socialRoles.map((role: any) => role.key);
    delete resultObject.socialRoles;
  }

  return resultObject;
};
