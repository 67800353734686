import React, { useMemo, useState } from 'react';
import { notification, Spin } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { getNewPlace, PlaceFrontend, preparePlaceForBackend, preparePlaceForFrontend } from './mappers';
import { PlaceContent } from './PlaceContent';
import useAxios from 'axios-hooks';
import { Index } from 'client/endpoints';
import { NAVIGATION } from 'paths';
import { PlaceResponseDto } from 'models';
import { ClientErrorException } from '../../components/ClientErrorException';
import { DatesAndTicketsValues } from '../../components/EventForm/DatesAndTicketsForm/DatesAndTickets';

export const Place = () => {
  const { id } = useParams<{ id: string }>();
  const isCreationMode = id === 'new';
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  const [{ data, error, loading }] = useAxios<BaseResponse<PlaceResponseDto>>(
    { url: `${Index.places}/${id}`, method: 'GET' },
    { manual: isCreationMode },
  );

  const updatePlace = useAxios({ url: `${Index.places}/${id}`, method: 'PUT' }, { manual: true })[1];

  const deletePlace = useAxios<BaseResponse<PlaceResponseDto>>(
    { url: `${Index.places}/${id}`, method: 'DELETE' },
    { manual: true },
  )[1];

  const saveNewPlace = useAxios({ url: Index.places, method: 'POST' }, { manual: true })[1];

  const place = useMemo<Optional<PlaceFrontend>>(() => {
    if (isCreationMode) {
      return getNewPlace();
    } else if (data !== undefined) {
      return preparePlaceForFrontend(data.data);
    }
  }, [isCreationMode, data]);

  const handleDeletePlace = async () => {
    setIsLoading(true);
    try {
      await deletePlace();
      notification.success({
        message: `Место "${place?.title}" удалено`,
      });
      navigate(NAVIGATION.places);
    } catch (error) {
      notification.error({ message: `Извините, произошла ошибка` });
      setIsLoading(false);
    }
  };

  const onSave = async (formFields: PlaceFrontend & DatesAndTicketsValues) => {
    const placeForSave = preparePlaceForBackend(formFields);

    try {
      const query = isCreationMode ? saveNewPlace : updatePlace;

      await query({ data: placeForSave });

      notification.success({ message: isCreationMode ? 'Место создано' : 'Место обновлено' });
      navigate(NAVIGATION.places);
    } catch (error) {
      notification.error({ message: 'Извините, при сохранении места произошла ошибка' });
    }
  };

  if (error) {
    return <ClientErrorException errorCode={error.request.status} />;
  }

  return (
    <Spin spinning={isLoading || loading}>
      {place && !loading && <PlaceContent place={place} onDeleteButtonClick={handleDeletePlace} onSave={onSave} />}
    </Spin>
  );
};
