import { Form } from 'antd';
import React, { FC } from 'react';
import useAsyncSelectOptions from '../../hooks/useAsyncSelectOptions';
import useSocialNeeds, { ClientNeed } from '../../hooks/useSocialNeeds';
import { AdminCreateNeedDataResponseDto, AdminCreateNeedResponseDto } from '../../models';
import { BASE_NEEDS_SEARCH_URL } from '../../utils';
import { DebouncedSelect } from '../DebouncedSelect';
import { FormList } from '../FormList';

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

export type NeedsFormInitialValues = {
  initialValues?: { needs?: Array<{ label: string; value: number; key: number }> };
};

const needsMapper = (data: Array<AdminCreateNeedDataResponseDto['data'] | ClientNeed>) => {
  return data.map((item) => {
    return {
      label: item.name,
      key: item.id,
      value: item.name,
    };
  });
};
export const NeedsForm: FC<NeedsFormInitialValues> = ({ initialValues }) => {
  const [form] = Form.useForm<NeedsFormInitialValues['initialValues']>();
  const { needs: defaultNeeds } = useSocialNeeds();

  const { fetcher } = useAsyncSelectOptions<AdminCreateNeedResponseDto>(BASE_NEEDS_SEARCH_URL, needsMapper);
  const defaultValue = { value: '', label: '' };

  const options = needsMapper(defaultNeeds);

  return (
    <Form name="needsForm" {...layout} form={form} initialValues={initialValues}>
      <FormList
        label="Выбранные"
        name="needs"
        defaultValue={defaultValue}
        customController={<DebouncedSelect fetcher={fetcher} initialOptions={options} labelInValue={true} />}
      />
    </Form>
  );
};
