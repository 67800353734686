import { getMergedSocials, socialUrls } from 'socialUrls';
import { AROUND_THE_CLOCK, getDateFromHoursAndMinutes, NO_SCHEDULE } from 'utils';
import { ScheduleDay } from '../../components/Schedule';
import { clientPlaceScheduleToServerPlaceSchedule } from '../../client/mappers';
import { Place, PlaceResponseDto, PlaceScheduleDays } from 'models';
import { TSocialRole } from 'components/SocialForm/SocialForm';
import { getFrontendReview, getReviewForBackend, getReviewForFrontend } from 'components/Review/helpers';
import { GalleryItem, getGalleryItem, ImageType } from 'hooks/useUploadImage';
import { TOurReview } from 'components/Review/OurReview';
import { DatesAndTicketsValues } from '../../components/EventForm/DatesAndTicketsForm/DatesAndTickets';
import { formatPhoneNumber } from '../../utils/formatters/formatPhoneNumber';
import { parseArrayValuesForMappers } from '../../utils/parseArrayValuesForMappers';

const allDays = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

export type PlaceFrontend = {
  id: number;
  type?: string;
  title?: string;
  image?: string;
  tags: number[];
  sites: string[];
  needs: number[];
  emails: string[];
  phones: string[];
  history?: string;
  address?: string;
  altTitle?: string;
  fullTitle?: string;
  review?: TOurReview;
  description?: string;
  visitingAge?: number;
  legalAddress?: string;
  clarification?: string;
  lon?: string | number;
  lat?: string | number;
  schedule: ScheduleDay;
  gallery: GalleryItem[];
  [NO_SCHEDULE]: boolean;
  socials: TSocialRole[];
  shortDescription?: string;
  ticketsUrl?: string;
  visitTimeAverage?: number;
  [AROUND_THE_CLOCK]: boolean;
  categories: Place['categories'];
  targetGroups: Place['targetGroups'];
  parent?: { id: number; name: string; address: string; image?: string };
  areaName?: string;
  areaId?: number;
  priority: number;
};

const prepareSchedule = (backendSchedule: Array<PlaceScheduleDays> = []): ScheduleDay => {
  return backendSchedule.reduce<ScheduleDay>((acc, currentDay) => {
    const { dayTitle, isActive, workTimeStartAt, workTimeEndAt, breakTimeStartAt, breakTimeEndAt } = currentDay;

    acc[dayTitle] = {
      enabled: isActive,
      hours: [getDateFromHoursAndMinutes(workTimeStartAt), getDateFromHoursAndMinutes(workTimeEndAt)],
      break: [getDateFromHoursAndMinutes(breakTimeStartAt), getDateFromHoursAndMinutes(breakTimeEndAt)],
    };

    return acc;
  }, {});
};

export const preparePlaceForFrontend = ({
  id,
  type,
  tags,
  needs,
  title,
  image,
  parent,
  images,
  review,
  history,
  address,
  socials,
  contacts,
  altTitle,
  schedule,
  fullTitle,
  categories,
  visitingAge,
  description,
  targetGroups,
  clarification,
  shortDescription,
  visitTimeAverage,
  area,
  priority,
}: PlaceResponseDto): PlaceFrontend => {
  const { aroundTheClock, schedule: backendSchedule, scheduleDoNotStated } = schedule || {};
  const frontendSchedule = prepareSchedule(backendSchedule);
  const preparedSocial = getMergedSocials(socials);
  const preparedImages: GalleryItem[] = (images || []).map(getGalleryItem);
  const gallery = preparedImages.filter((i) => i.name.includes(ImageType.Gallery));

  return {
    id,
    // @ts-ignore: fix
    image,
    title,
    address,
    gallery,
    categories,
    visitingAge,
    // @ts-ignore: fix
    clarification,
    type: type || '',
    socials: preparedSocial,
    history: history || '',
    schedule: frontendSchedule,
    sites: contacts?.sites || [],
    description: description || '',
    phones: contacts?.phones || [],
    emails: contacts?.emails || [],
    targetGroups: targetGroups || [],
    needs: (needs || []).map((n) => n.id),
    aroundTheClock: aroundTheClock || false,
    visitTimeAverage: visitTimeAverage ?? 0,
    fullTitle: fullTitle || 'Полное название',
    tags: (tags || []).map((t) => t.id) || [],
    [NO_SCHEDULE]: scheduleDoNotStated || false,
    legalAddress: contacts?.legalAddress?.[0] || '',
    altTitle: altTitle || undefined,
    review: review ? getReviewForFrontend(review) : undefined,
    shortDescription: shortDescription || 'Короткое описани',
    parent: parent ? { id: parent.id, address: parent.address, name: parent.title, image: parent.image } : undefined,
    areaName: area?.name,
    areaId: area?.id,
    priority,
  };
};

export const getNewPlace = (): PlaceFrontend => ({
  id: NaN,
  tags: [],
  sites: [],
  needs: [],
  emails: [],
  phones: [],
  gallery: [],
  categories: [],
  targetGroups: [],
  socials: socialUrls,
  [NO_SCHEDULE]: false,
  review: getFrontendReview(),
  schedule: allDays.reduce<ScheduleDay>((acc, day) => {
    acc[day] = {
      enabled: false,
    };
    return acc;
  }, {}),
  [AROUND_THE_CLOCK]: false,
  priority: 1,
});

export const preparePlaceForBackend = (formFields: PlaceFrontend & DatesAndTicketsValues) => {
  const {
    lat,
    lon,
    tags,
    days,
    type,
    needs,
    image,
    title,
    sites,
    emails,
    phones,
    review,
    parent,
    socials,
    address,
    history,
    altTitle,
    fullTitle,
    categories,
    description,
    visitingAge,
    legalAddress,
    targetGroups,
    clarification,
    shortDescription,
    visitTimeAverage,
    [AROUND_THE_CLOCK]: aroundTheClock,
    // @ts-ignore: fix later
    areaId,
    priority,
  } = formFields;

  const contacts = {
    sites: parseArrayValuesForMappers(sites),
    emails: parseArrayValuesForMappers(emails),
    phones: parseArrayValuesForMappers(phones.map((phone) => formatPhoneNumber(phone))),
    legalAddress: [legalAddress],
  };
  const finalizedReview = getReviewForBackend(review);

  const returnObject = {
    tags: tags.every((item) => isNaN(item))
      ? tags?.map((item) => {
          // @ts-ignore: fix types later
          return +item.value;
        })
      : tags,
    type,
    image,
    needs,
    title,
    events: [],
    address,
    contacts,
    altTitle: altTitle !== '' ? altTitle : null,
    fullTitle,
    categories,
    visitingAge,
    targetGroups,
    clarification,
    shortDescription,
    lat: lat || null,
    lon: lon || null,
    parentId: parent?.id ?? null,
    visitTimeAverage: visitTimeAverage ? +visitTimeAverage : null,
    review: finalizedReview !== null ? finalizedReview : undefined,
    history: history ? history : undefined,
    description: description ? description : '',
    // socials: (socials || []).filter((i: TSocialRole) => i.url),
    schedule: {
      aroundTheClock,
      scheduleDoNotStated: false,
      schedule: clientPlaceScheduleToServerPlaceSchedule(days),
    },
    areaId: areaId,
    priority,
  };

  // TODO: add mapSocialNetworksToApiSocials here
  if ((socials || []).filter((i: TSocialRole) => i.url).length > 0)
    // @ts-ignore: add types later
    returnObject.socials = (socials || []).filter((i: TSocialRole) => i.url);

  if (parent?.id !== null && parent !== undefined) returnObject.address = parent?.address;

  return returnObject;
};
