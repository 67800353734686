import { notification, Spin } from 'antd';
import React, { useEffect, useMemo } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { EventResponseDto } from '../../models';
import { Index } from '../../client/endpoints';
import { EventContent } from './EventContent';
import {
  clientFrontendEventToServer,
  getEventBaseFormInitialValuesForCreationMode,
  getFrontendEvent,
  initialDatesAndTickets,
} from './mappers';
import useAxios from 'axios-hooks';
import { NAVIGATION } from '../../paths';
import { EventData, EventFormValues } from './Event.types';
import { ClientErrorException } from '../../components/ClientErrorException';
import { getMergedSocials } from '../../socialUrls';

export const Event = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const isCreationMode = id === 'new';
  const [searchParams] = useSearchParams();
  // eventStatus необходим для того, чтобы удостовериться в том, что пользователь создаёт страницу через интерфейс, а не прямой переход по /new.
  const eventStatus = searchParams.get('status');

  const deleteEvent = useAxios<BaseResponse<EventResponseDto>>(
    { url: `${Index.adminEvents}/${id}`, method: 'DELETE' },
    { manual: true },
  )[1];

  const createEvent = useAxios<void>({ url: `${Index.adminEvents}`, method: 'POST' }, { manual: true })[1];
  const editEvent = useAxios<void>({ url: `${Index.adminEvents}/${id}`, method: 'PATCH' }, { manual: true })[1];

  const [{ data: eventData, loading: isEventLoading, error }] = useAxios<BaseResponse<EventResponseDto>>(
    {
      url: `${Index.adminEvents}/${id}`,
      method: 'GET',
    },
    { manual: isCreationMode },
  );

  const archivePlace = async () => {
    try {
      await deleteEvent();
      notification.success({
        message: `Событие  удалено`,
      });
      navigate(NAVIGATION.events);
    } catch (e) {
      notification.error({ message: `Извините, произошла ошибка` });
    } finally {
      /* empty */
    }
  };

  const onFormFinish = async (values: EventFormValues) => {
    try {
      const data = clientFrontendEventToServer(values);
      isCreationMode
        ? await createEvent({
            data,
          })
        : await editEvent({
            data,
          });
      notification.success({
        message: `Событие успешно ${isCreationMode ? 'создано' : 'изменено'}`,
      });
      navigate('/events');
    } catch (e) {
      console.error(e);
      if (values.eventType?.length !== undefined && values.eventType?.length < 1) {
        notification.error({
          message: `У мероприятия должен быть указан его тип`,
        });
        return;
      }
      // @ts-ignore: just ignore
      if (e?.response?.data?.data?.error?.includes('schedule for event'))
        notification.error({
          message: `Необходимо заполнить расписание`,
        });
      else
        notification.error({
          message: `Произошла ошибка при ${isCreationMode ? 'создании' : 'изменении'}`,
        });
    }
  };

  const mappedServerEventData = useMemo((): EventData => {
    const data = eventData?.data;
    const isIdNew = id === undefined || id === 'new';
    if (data === undefined || isIdNew) {
      return {
        baseInfo: getEventBaseFormInitialValuesForCreationMode(),
        datesAndTickets: initialDatesAndTickets,
        id: id ?? 'new',
        image: null,
        gallery: [],
        tickets: {
          ticketsMinPrice: undefined,
          ticketsUrl: '',
        },
        contacts: {
          socials: getMergedSocials([]),
          legalAddress: '',
          sites: [],
          phones: [],
          emails: [],
        },
        needs: [],
        infoForSite: {
          showVideoInNewWindow: true,
        },
      };
    }
    return getFrontendEvent(data, id);
  }, [eventData, id]);

  useEffect(() => {
    if (eventStatus === null && isCreationMode) {
      navigate('/events');
      notification.info({ message: 'Для создания нового события, нажмите кнопку создать' });
    }
  }, [eventStatus, isCreationMode, navigate]);

  const onEventCancel = (isCanceled: boolean) => {
    navigate({ pathname: `/events/${id}`, search: `canceled=${isCanceled}` });
    notification.info({
      message: `Для изменения статуса события нажмите кнопку "Сохранить"`,
    });
  };

  if (error) {
    return <ClientErrorException errorCode={error.request.status} />;
  }

  if (isEventLoading) {
    return (
      <div className="d-flex mt-32 flex-justify-center">
        <Spin size="large" />
      </div>
    );
  }

  return (
    <EventContent
      onArchive={archivePlace}
      onSubmit={onFormFinish}
      event={mappedServerEventData}
      onCancel={onEventCancel}
    />
  );
};
