import { Index } from './index';

export const getCitiesEndpointUrl = () => {
  return `${Index.cities}/areas?`;
};

export const getCitiesEndpointWithSearchUrl = () => {
  return `${Index.cities}/areas?query=`;
};
export const cityByIdEndpointUrl = (id?: string) => ({ url: `${Index.cities}/areas/${id}`, method: 'GET' });
export const deleteCityEndpointUrl = (id?: string) => ({ url: `${Index.cities}/areas/${id}`, method: 'DELETE' });
export const updateCityEndpointUrl = (id?: string) => ({ url: `${Index.cities}/areas/${id}`, method: 'PATCH' });
export const createCityEndpointUrl = () => ({ url: `${Index.cities}/areas`, method: 'POST' });
