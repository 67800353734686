import { Button } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import React from 'react';
import styles from './styles.module.css';

type Props = {
  onLeftButtonClick?: () => void;
  onRightButtonClick?: () => void;
};

export const FormHeaderButtons: React.FC<Props> = ({ onLeftButtonClick, onRightButtonClick }) => {
  return (
    <div className={styles.container}>
      {(onLeftButtonClick || onRightButtonClick) && (
        <div className="d-flex gap-8">
          {onLeftButtonClick && (
            <Button className="border-none bg-gray-9 arrow-button" onClick={onLeftButtonClick}>
              <LeftOutlined />
            </Button>
          )}
          {onRightButtonClick && (
            <Button className="border-none bg-gray-9 arrow-button" onClick={onRightButtonClick}>
              <RightOutlined />
            </Button>
          )}
        </div>
      )}
    </div>
  );
};
