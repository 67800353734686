import { notification } from 'antd';
import useAxios from 'axios-hooks';
import {
  getVideoByIdEndpointUrl,
  updateVideoAreasEndpointUrl,
  updateVideoBaseInfoEndpointUrl,
  updateVideoRolesEndpointUrl,
} from 'client/endpoints/videos';
import { FrontendVideoItem } from 'pages/Video/types';
import { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { deleteNewEndpointUrl } from '../client/endpoints/news';
import { AdminGetOneResponse, AdminNewsResponseDto } from '../models';
import {
  mapAreasVideoInfoForBackend,
  mapBaseVideoInfoForBackend,
  mapRolesVideoInfoForBackend,
} from '../pages/Video/mappers';
import { NAVIGATION } from '../paths';

type Props = {
  id?: string;
};

export const useVideosBackendActions = ({ id }: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const [{ data, error, loading }] = useAxios<BaseResponse<AdminGetOneResponse>>(getVideoByIdEndpointUrl(id), {
    manual: false,
  });

  const deleteNew = useAxios<BaseResponse<AdminNewsResponseDto>>(deleteNewEndpointUrl(id), { manual: true })[1];

  const updateBaseVideoData = useAxios(updateVideoBaseInfoEndpointUrl(id), { manual: true })[1];
  const updateRolesVideoData = useAxios(updateVideoRolesEndpointUrl(id), { manual: true })[1];
  const updateAreasVideoData = useAxios(updateVideoAreasEndpointUrl(id), { manual: true })[1];

  const videoData = useMemo(() => {
    // eslint-disable-next-line
    return data?.data as any;
  }, [data]);

  const handleDeleteVideo = useCallback(async () => {
    setIsLoading(true);
    try {
      await deleteNew();
      notification.success({
        message: `Видео "${videoData?.title}" удалено`,
      });
      navigate(NAVIGATION.video);
    } catch (error) {
      notification.error({ message: `Извините, произошла ошибка` });
      setIsLoading(false);
    }
  }, [deleteNew, navigate, videoData?.title]);

  const onSave = useCallback(
    async (formFields: FrontendVideoItem) => {
      try {
        setIsLoading(true);
        // @ts-ignore: temp
        const mappedFormFields = mapBaseVideoInfoForBackend(formFields);
        const mappedRolesFormFields = mapRolesVideoInfoForBackend(formFields);
        const mappedAreasFormFields = mapAreasVideoInfoForBackend(formFields);

        await updateBaseVideoData({ data: mappedFormFields });

        await updateRolesVideoData({ data: mappedRolesFormFields });

        await updateAreasVideoData({ data: mappedAreasFormFields });

        notification.success({ message: 'Видео обновлено' });
        navigate(NAVIGATION.video);
        setIsLoading(false);
      } catch (error) {
        console.error(error);
        setIsLoading(false);
        notification.error({ message: 'Извините, при сохранении видео произошла ошибка' });
      }
    },
    [navigate, updateBaseVideoData, updateRolesVideoData, updateAreasVideoData],
  );

  return useMemo(
    () => ({
      info: {
        data,
        error,
        loading,
        isLoading,
        videoData,
      },
      actions: {
        handleDelete: handleDeleteVideo,
        onSave,
      },
    }),
    [data, error, handleDeleteVideo, isLoading, loading, videoData, onSave],
  );
};
