// Функция для нормализации социальных сетей перед отправкой на сервер.
import { TSocialRole } from '../../components/SocialForm/SocialForm';

const mapSocialNetworksToApiSocials = <T extends TSocialRole>(socials: Array<T>): Array<T> => {
  const isSocialRoleHasUrl = socials.some((item) => {
    return item.url !== undefined;
  });
  return isSocialRoleHasUrl ? socials.filter((i: TSocialRole) => i.url !== undefined && i.url.length >= 1) : [];
};

export default mapSocialNetworksToApiSocials;
