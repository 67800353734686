import { Form } from 'antd';
import React from 'react';
import { StyledInput } from '../StyledInputs/StyledInput';
import { StyledInputNumber } from '../StyledInputs/StyledInputNumber';

export type TicketsFormValues = {
  ticketsMinPrice?: number;
  ticketsUrl?: string;
};
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const TicketsForm = (initialValues: TicketsFormValues) => {
  const [form] = Form.useForm<TicketsFormValues>();

  return (
    <Form form={form} {...layout} name="tickets" initialValues={initialValues}>
      <Form.Item
        name={'ticketsMinPrice'}
        label={'Минимальная стоимость билета'}
        rules={[{ type: 'number', message: 'Стоимость билета должна быть не ниже нуля', min: 0 }]}
      >
        <StyledInputNumber />
      </Form.Item>
      <Form.Item
        name={'ticketsUrl'}
        label={'Ссылка на место приобретения билетов'}
        rules={[{ type: 'url', message: 'Пожалуйста, введите корректную ссылку на место приобретения билетов' }]}
      >
        <StyledInput placeholder="Ссылка на страницу покупки билетов" />
      </Form.Item>
    </Form>
  );
};

export default TicketsForm;
