import React from 'react';
import { Button, Row, Typography } from 'antd';

type PageTitleProps = {
  title: string;
  titleClassName?: string;
  wrapperClassName?: string;
  onAddButtonClick?: () => void;
  BottomElement?: JSX.Element;
};

export const PageTitle: React.FC<PageTitleProps> = ({
  title,
  wrapperClassName = '',
  titleClassName = '',
  onAddButtonClick,
  BottomElement,
}) => {
  return (
    <div className={`${wrapperClassName} bg-neutral  pv-16 ph-24`}>
      <Row align="bottom" className={`${titleClassName}`}>
        <Typography.Title className="m-0" level={2}>
          {title}
        </Typography.Title>
        {onAddButtonClick && (
          <Button type="primary" className="ml-8" onClick={onAddButtonClick}>
            + Добавить
          </Button>
        )}
      </Row>
      {BottomElement && BottomElement}
    </div>
  );
};
