import { Index } from './index';

export const getAuthorsEndpointUrl = (query = '') => {
  return query.length > 2 ? `${Index.authors}/?query=${query}` : `${Index.authors}?`;
};

export const getAuthorByIdEndpointUrl = (id?: string) => ({ url: `${Index.authors}/${id}`, method: 'GET' });
export const deleteAuthorEndpointUrl = (id?: string) => ({ url: `${Index.authors}/${id}`, method: 'DELETE' });
export const updateAuthorEndpointUrl = (id?: string) => ({ url: `${Index.authors}/${id}`, method: 'PATCH' });
export const createAuthorEndpointUrl = () => ({ url: Index.authors, method: 'POST' });

export const updateAuthorVideosEndpointUrl = (id?: string) => ({
  url: `${Index.authors}/${id}/videos`,
  method: 'PATCH',
});
