import { BaseInfoEventFormValues, Format, Frequency, Status } from '../../components/EventForm/EventForm.typedef';
import {
  EventCreateDto,
  EventGetOneResponse,
  EventResponseDto,
  EventSocialNetwork,
  PlaceScheduleDays,
} from '../../models';
import { DatesAndTicketsProps } from '../../components/EventForm/DatesAndTicketsForm/DatesAndTickets';
import moment from 'moment';
import {
  AROUND_THE_CLOCK,
  getDateFromHoursAndMinutes,
  getShortDateWithEnglishLocale,
  NO_SCHEDULE,
  translatePeriodicityToServer,
} from '../../utils';
import { EventData, EventFormValues } from './Event.types';
import { getMergedSocials } from '../../socialUrls';
import mapSocialNetworksToApiSocials from '../../client/mappers/mapSocialNetworksToApiSocials';
import { ScheduleDay } from '../../components/Schedule';
import {
  clientEventScheduleScheduleToServerEventSchedule,
  clientPlaceScheduleToServerPlaceSchedule,
  serverEventScheduleToClientEventSchedule,
} from '../../client/mappers/schedule/schedule';
import { formatPhoneNumber } from '../../utils/formatters/formatPhoneNumber';
import { parseArrayValuesForMappers } from '../../utils/parseArrayValuesForMappers';

const initialBaseInfoEventFormValuesData: BaseInfoEventFormValues = {
  status: 'global',
  mainGroups: [],
  fullDescription: '',
  shortDescription: '',
  shortTitle: '',
  fullTitle: '',
  relatedGlobalEvent: null,
  // @ts-ignore: fix type
  eventType: '',
  alternativeTitle: '',
  address: '',
  avgTime: 45,
  tags: [],
  categories: [],
  format: 'offline',
  frequency: 'one-time',
  minAge: 0,
};
const prepareScheduleForRegularEvent = (backendSchedule: Array<PlaceScheduleDays> = []): ScheduleDay => {
  return backendSchedule.reduce<ScheduleDay>((acc, currentDay) => {
    const { dayTitle, isActive, workTimeStartAt, workTimeEndAt, breakTimeStartAt, breakTimeEndAt } = currentDay;

    acc[dayTitle] = {
      enabled: isActive,
      hours: [getDateFromHoursAndMinutes(workTimeStartAt), getDateFromHoursAndMinutes(workTimeEndAt)],
      break: [getDateFromHoursAndMinutes(breakTimeStartAt), getDateFromHoursAndMinutes(breakTimeEndAt)],
    };

    return acc;
  }, {});
};

// TODO: Убрать этот блок кода на этапе интеграции из маппера данных с бэка, не убирать из маппера получения данных для creationMode
const currentMonth = new Date().getMonth();
const currentDate = new Date().getDate();
const start = moment().set({ date: currentDate, month: currentMonth });
const end = start;

export const initialDatesAndTickets: DatesAndTicketsProps = {
  date: [start, end],
  initialValues: {
    [AROUND_THE_CLOCK]: false,
    [NO_SCHEDULE]: false,
    days: {
      [getShortDateWithEnglishLocale(start.toDate())]: {
        enabled: false,
        hours: [undefined, undefined],
        break: [undefined, undefined],
      },
    },
  },
};
//
export const getEventBaseFormInitialValuesForCreationMode = () => {
  const url = new URL(document.location.href);
  const urlQuery = new URLSearchParams(url.search);
  const status = urlQuery.get('status') as Status;
  const title = urlQuery.get('shortTitle');
  const parentId = urlQuery.get('parentId');
  const parentLabel = urlQuery.get('parentTitle');
  return {
    ...initialBaseInfoEventFormValuesData,
    frequency: (status === 'regular' ? 'regular' : status === 'once' ? 'one-time' : 'global') as Frequency,
    status: status ?? 'global',
    shortTitle: title ?? '',
    fullTitle: title ?? '',
    relatedGlobalEvent:
      parentLabel && parentId ? { key: +parentId, label: parentLabel ?? '', value: parentLabel ?? '' } : null,
  };
};
// eslint-disable-next-line
export const getFrontendEvent = (event: EventResponseDto & { info?: any }, id: string): EventData => {
  const mappedEventSchedule = {
    days:
      event.schedule !== null
        ? serverEventScheduleToClientEventSchedule(event.schedule.schedule ?? [])
        : initialDatesAndTickets.initialValues.days,
    [AROUND_THE_CLOCK]: event.schedule !== null ? event.schedule.aroundTheClock : false,
    [NO_SCHEDULE]: event.schedule !== null ? event.schedule.scheduleDoNotStated : false,
  };

  const mappedRegularEventSchedule = {
    days:
      event.schedule !== null
        ? prepareScheduleForRegularEvent(event.schedule.schedule)
        : initialDatesAndTickets.initialValues.days,
    [AROUND_THE_CLOCK]: event.schedule !== null ? event.schedule.aroundTheClock : false,
    [NO_SCHEDULE]: event.schedule !== null ? event.schedule.scheduleDoNotStated : false,
  };
  const mappedGlobalSchedule = {
    days: [],
    [AROUND_THE_CLOCK]: false,
    [NO_SCHEDULE]: false,
  };
  const preparedSocial = event.social ? getMergedSocials(event.social) : getMergedSocials([]);

  const startDate =
    event.schedule !== null && event.schedule.schedule !== undefined && event.schedule.schedule?.length >= 1
      ? moment(new Date(event.schedule.schedule[0].dayTitle))
      : moment(new Date());

  const endDate =
    event.schedule !== null && event.schedule.schedule !== undefined && event.schedule.schedule?.length >= 1
      ? moment(new Date(event.schedule.schedule[event.schedule.schedule.length - 1].dayTitle))
      : moment(new Date());

  const defineScheduleTypeByPeriodicity = () => {
    switch (event.periodicity) {
      case 'regular':
        return mappedRegularEventSchedule;
      case 'one-time':
        return mappedEventSchedule;
      case 'global':
        return mappedGlobalSchedule;
      default:
        return mappedGlobalSchedule;
    }
  };

  return {
    ourReview: event.review ? { score: event.review?.score ?? 0, text: event?.review?.text ?? '' } : undefined,
    datesAndTickets: {
      date: [startDate, endDate],
      // @ts-ignore: fix later
      initialValues: defineScheduleTypeByPeriodicity(),
    },
    needs: event.needs?.map((item) => {
      return item.id;
    }),
    tickets: {
      ticketsMinPrice: event.ticketsMinPrice ?? undefined,
      ticketsUrl: event.ticketsUrl ?? undefined,
    },
    id: id,
    children: event.children
      ? event.children.map((item) => {
          return {
            id: item.id,
            // TODO: address удалили на бэкенде
            address: '',
            name: item.title,
            imageUrl: item.image,
          };
        })
      : [],
    contacts: {
      phones: event.contacts?.phones ?? [],
      sites: event.contacts?.sites ?? [],
      emails: event.contacts?.emails ?? [],
      socials: preparedSocial,
      legalAddress: event.contacts?.legalAddress ? event.contacts?.legalAddress[0] : '',
    },
    gallery: event.images?.map((item) => {
      return { uid: item.id.toString(), url: item.fullUrl, name: 'gallery-' + item.id };
    }),
    image: event.image ? event.image : null,
    baseInfo: {
      clarification: event.addressClarification ?? undefined,
      status: event.periodicity,
      place: event.place
        ? {
            id: event.place.id,
            address: event.place.address,
            name: event.place.title,
            imageUrl: event.place.image,
          }
        : undefined,
      shortTitle: event.title,
      lat: event.lat ? event.lat.toString() : undefined,
      lon: event.lon ? event.lon.toString() : undefined,
      fullTitle: event.fullTitle,
      relatedGlobalEvent: event.parent
        ? { label: event.parent?.title, value: event.parent.title, key: event.parent.id }
        : null,
      eventType: event.type as EventGetOneResponse['type'],
      isCancelled: event.isCancelled,
      parent: event.parent
        ? {
            name: event.parent?.title ?? '',
            id: event.parent?.id,
            address: '',
            imageUrl: event.parent.image,
          }
        : undefined,
      shortDescription: event.description,
      fullDescription: event.fullDescription,
      mainGroups: event.targetGroups ?? [],
      alternativeTitle: event.altTitle ?? undefined,
      address: event.address ?? undefined,
      avgTime: event.visitTime,
      tags: event.tags
        ? event.tags?.map((item) => {
            return { value: item.id, label: item.name };
          })
        : [],
      categories: event.categories ?? [],
      format: event.format as Format,
      frequency: event.periodicity as Frequency,
      minAge: event.ageRequirement,
      priority: event.priority,
    },
    infoForSite: {
      organizer: event.info?.organizer,
      organizerUrl: event.info?.organizerUrl,
      streamUrl: event.info?.streamUrl,
      registerUrl: event.info?.registerUrl,
      showVideoInNewWindow: event.info?.showVideoInNewWindow,
      // eslint-disable-next-line
      thematics: event.info?.thematics.map((item: any) => ({
        value: item.id,
        label: item.name,
      })),
      // eslint-disable-next-line
      formats: event.info?.formats.map((item: any) => ({
        value: item.id,
        label: item.name,
      })),
    },
  };
};

export const clientFrontendEventToServer = (clientEvent: EventFormValues): EventCreateDto => {
  const {
    shortTitle,
    fullTitle,
    place,
    shortDescription,
    alternativeTitle,
    eventType,
    days,
    aroundTheClock,
    noSchedule,
    minAge,
    format,
    avgTime,
    phones,
    sites,
    emails,
    fullDescription,
    socials,
    review,
    ticketsMinPrice,
    frequency,
    periodicity,
    isCancelled,
    address,
    tags,
    needs,
    lon,
    clarification,
    relatedGlobalEvent,
    lat,
    ticketsUrl,
    favoriteImage,
    legalAddress,

    thematics,
    formats,
    organizer,
    organizerUrl,
    streamUrl,
    registerUrl,
    showVideoInNewWindow,
    priority,
  } = clientEvent;

  // console.log(thematics, formats, organizer, organizerUrl, streamUrl, registrationUrl, secured);

  const normalizedPeriodicity = translatePeriodicityToServer(periodicity);
  const daysKeys = Object.keys(days ?? {});
  const firstDay = moment(daysKeys[0]).isValid() ? moment(daysKeys[0]) : moment().startOf('isoWeek');

  const lastDay = moment(daysKeys[daysKeys.length - 1]).isValid()
    ? moment(daysKeys[daysKeys.length - 1])
    : moment().endOf('isoWeek');
  const start = days !== undefined ? new Date(moment(firstDay).set({ hours: 12 }).toDate())?.toISOString() : undefined;
  const end = days !== undefined ? new Date(moment(lastDay).set({ hours: 12 }).toDate())?.toISOString() : undefined;
  const socialNetworks = socials as Array<EventSocialNetwork>;

  const requestData: EventCreateDto = {
    fullDescription,
    title: shortTitle,
    review: review?.text
      ? {
          text: review?.text || '',
          score: review !== undefined ? +review?.score : 0,
        }
      : undefined,
    fullTitle: fullTitle,
    ticketsMinPrice: ticketsMinPrice,
    ticketsUrl: ticketsUrl === '' ? undefined : ticketsUrl,
    address: place && place.id !== undefined ? undefined : address,
    addressClarification: clarification,
    endDate: end,
    startDate: start,
    tags: tags?.map((item) => {
      return +item.value;
    }),
    schedule: {
      schedule:
        normalizedPeriodicity === 'one-time'
          ? clientEventScheduleScheduleToServerEventSchedule(days)
          : clientPlaceScheduleToServerPlaceSchedule(days),
      aroundTheClock: aroundTheClock,
      scheduleDoNotStated: noSchedule,
    },
    isCancelled: isCancelled,
    parentId:
      relatedGlobalEvent !== undefined && relatedGlobalEvent !== null && frequency !== 'regular'
        ? +relatedGlobalEvent.key
        : undefined,
    placeId: place?.id,
    visitTime: avgTime ?? undefined,
    periodicity: normalizedPeriodicity,
    ageRequirement: minAge,
    image: favoriteImage ?? undefined,
    contacts: {
      phones: parseArrayValuesForMappers(phones.map((phone) => formatPhoneNumber(phone))),
      emails: parseArrayValuesForMappers(emails),
      sites: parseArrayValuesForMappers(sites),
      legalAddress: legalAddress && legalAddress !== '' ? [legalAddress] : undefined,
    },
    description: shortDescription,
    lat: address && address.length > 1 && lat ? +lat : undefined,
    lon: address && address.length > 1 && lon ? +lon : undefined,
    needs: needs,
    social: mapSocialNetworksToApiSocials(socialNetworks),
    type: eventType,
    altTitle: alternativeTitle,
    format: format,
    priority,
    info: {
      thematics:
        thematics && thematics?.length > 0
          ? thematics?.map((item) => {
              return +item.value;
            })
          : undefined,
      formats:
        formats && formats?.length > 0
          ? formats?.map((item) => {
              return +item.value;
            })
          : undefined,
      organizer,
      organizerUrl,
      streamUrl,
      registerUrl,
      showVideoInNewWindow,
    },
  };
  if (periodicity === 'global') {
    requestData.schedule = undefined;
  }
  return requestData;
};
