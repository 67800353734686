import { Form } from 'antd';
import { DebouncedSelect } from 'components/DebouncedSelect';
import useAsyncSelectOptions from 'hooks/useAsyncSelectOptions';
import { useEvents } from 'hooks/useEvents';
import { useNews } from 'hooks/useNews';
import { usePlaces } from 'hooks/usePlaces';
import { useServices } from 'hooks/useServices';
import { useVideos } from 'hooks/useVideos';
import { baseBindingMapper, bindingVideosMapper } from 'pages/Author/mappers';
import React, { FC } from 'react';
import { CheckBoxProps } from 'utils';
import {
  BASE_EVENTS_SEARCH_URL,
  BASE_NEWS_SEARCH_URL,
  BASE_PLACES_SEARCH_URL,
  BASE_SERVICES_SEARCH_URL,
  BASE_VIDEOS_SEARCH_URL,
} from 'utils/constants';
import { AdminNewsResponseDto, AdminServiceListDto, AuthorVideoResponse } from '../../models';

export type Props = {
  id?: number;
  initialValues: {
    news?: CheckBoxProps[];
    events?: CheckBoxProps[];
    videos?: CheckBoxProps[];
    services?: CheckBoxProps[];
    recommendedEvents?: CheckBoxProps[];
    places?: CheckBoxProps[];
  };
};

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

export const AuthorBindings: FC<Props> = ({ initialValues, id }) => {
  const [form] = Form.useForm();

  const { fetcher: newsFetcher } = useAsyncSelectOptions<AdminNewsResponseDto>(BASE_NEWS_SEARCH_URL, baseBindingMapper);
  const { fetcher: videosFetcher } = useAsyncSelectOptions<AuthorVideoResponse>(
    BASE_VIDEOS_SEARCH_URL,
    bindingVideosMapper,
  );

  const { fetcher: eventsFetcher } = useAsyncSelectOptions<AdminNewsResponseDto>(
    BASE_EVENTS_SEARCH_URL,
    baseBindingMapper,
  );

  const { fetcher: recommendedEventsFetcher } = useAsyncSelectOptions<AdminNewsResponseDto>(
    BASE_EVENTS_SEARCH_URL,
    baseBindingMapper,
  );

  const { fetcher: servicesFetcher } = useAsyncSelectOptions<AdminServiceListDto>(
    BASE_SERVICES_SEARCH_URL,
    baseBindingMapper,
  );

  const { fetcher: placesFetcher } = useAsyncSelectOptions<AdminServiceListDto>(
    BASE_PLACES_SEARCH_URL,
    baseBindingMapper,
  );

  const { data: news } = useNews();
  const mappedNews = baseBindingMapper(news);

  const { data: videos } = useVideos();
  const mappedVideos = bindingVideosMapper(videos);

  const { data: events } = useEvents();
  const mappedEvents = baseBindingMapper(events);

  const { data: services } = useServices();
  const mappedServices = baseBindingMapper(services);

  const { data: recommendedEvents } = useEvents();
  const mappedRecommendedEvents = baseBindingMapper(recommendedEvents);

  const { data: places } = usePlaces();
  const mappedPlaces = baseBindingMapper(places);

  return (
    <Form form={form} {...layout} name="bindings" initialValues={initialValues}>
      <Form.Item label="Рекомендует сервис" name="services">
        <DebouncedSelect
          fetcher={servicesFetcher}
          initialOptions={mappedServices}
          labelInValue={true}
          mode="multiple"
        />
      </Form.Item>
      <Form.Item label="Упоминается в новости" name="news">
        <DebouncedSelect fetcher={newsFetcher} initialOptions={mappedNews} labelInValue={true} mode="multiple" />
      </Form.Item>
      <Form.Item label="Рекомендует место" name="places">
        <DebouncedSelect fetcher={placesFetcher} initialOptions={mappedPlaces} labelInValue={true} mode="multiple" />
      </Form.Item>
      {id !== undefined && !isNaN(id) && (
        <Form.Item label="Рекомендует видео" name="videos">
          <DebouncedSelect fetcher={videosFetcher} initialOptions={mappedVideos} labelInValue={true} mode="multiple" />
        </Form.Item>
      )}
      <Form.Item label="Участник события" name="events">
        <DebouncedSelect fetcher={eventsFetcher} initialOptions={mappedEvents} labelInValue={true} mode="multiple" />
      </Form.Item>
      <Form.Item label="Рекомендует событие" name="recommendedEvents">
        <DebouncedSelect
          fetcher={recommendedEventsFetcher}
          initialOptions={mappedRecommendedEvents}
          labelInValue={true}
          mode="multiple"
        />
      </Form.Item>
    </Form>
  );
};
