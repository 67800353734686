import { notification, Spin } from 'antd';
import useAxios from 'axios-hooks';
import { Index } from 'client/endpoints';
import { AdminServiceDto } from 'models';
import { AdminGetRolesAndNeedsForService } from 'pages/Service/typedef';
import { NAVIGATION } from 'paths';
import React, { useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ClientErrorException } from '../../components/ClientErrorException';
import { FrontendService, getFrontendService, getNewFrontendService, prepareServiceForBackend } from './mappers';
import { ServiceContent } from './ServiceContent';

export const Service = () => {
  const { id } = useParams<{ id: string }>();
  const isCreationMode = id === 'new';
  const navigate = useNavigate();
  const [{ data, loading, error }] = useAxios<BaseResponse<AdminServiceDto>>(
    { url: `${Index.services}/${id}`, method: 'GET' },
    { manual: isCreationMode },
  );

  const [{ data: rolesData, loading: rolesLoading }] = useAxios<BaseResponse<AdminGetRolesAndNeedsForService[]>>(
    { url: `${Index.services}/${id}/roles`, method: 'GET' },
    { manual: isCreationMode },
  );

  const deleteService = useAxios<BaseResponse<AdminServiceDto>>(
    { url: `${Index.services}/${id}`, method: 'DELETE' },
    { manual: true },
  )[1];

  const saveNewService = useAxios({ url: Index.services, method: 'POST' }, { manual: true })[1];

  const updateService = useAxios({ url: `${Index.services}/${id}`, method: 'PUT' }, { manual: true })[1];

  const [isLoading, setIsLoading] = useState(false);

  const service = useMemo(() => {
    return isCreationMode
      ? getNewFrontendService()
      : data?.data
      ? getFrontendService({
          ...data.data,
          rolesData: rolesData?.data,
        })
      : undefined;
  }, [data?.data, isCreationMode, rolesData?.data]);

  if (error) {
    return <ClientErrorException errorCode={error.request.status} />;
  }

  if (loading || !service) {
    return (
      <div className="d-flex mt-32 flex-justify-center">
        <Spin size="large" />
      </div>
    );
  }

  const handleDeleteService = async () => {
    setIsLoading(true);
    try {
      await deleteService();
      notification.success({
        message: `Сервис "${service?.title}" удален`,
      });
      navigate(NAVIGATION.services);
    } catch (error) {
      notification.error({ message: `Извините, произошла ошибка` });
      setIsLoading(false);
    }
  };

  const onSaveButtonClick = async (data: FrontendService) => {
    const serviceForBackend = prepareServiceForBackend({
      ...data,
      id: id === 'new' || !id ? undefined : +id,
    });

    try {
      const query = isCreationMode ? saveNewService : updateService;

      await query({ data: serviceForBackend });

      notification.success({ message: isCreationMode ? 'Сервис создан' : 'Сервис обновлен' });
      navigate(NAVIGATION.services);
    } catch (error) {
      notification.error({ message: 'Извините, при сохранении сервиса произошла ошибка' });
    }
  };

  return (
    <Spin spinning={isLoading || rolesLoading}>
      <ServiceContent service={service} onSave={onSaveButtonClick} onDeleteButtonClick={handleDeleteService} />
    </Spin>
  );
};
