import React, { useMemo, useState } from 'react';
import { axios } from 'client';
import { Index } from 'client/endpoints';
import { PageTitle } from 'components/PageTitle';
import { Content } from 'antd/lib/layout/layout';
import { Button, Layout, List, Modal, ModalProps, notification, Pagination, PaginationProps, Tabs } from 'antd';
import { StyledInput } from '../../components/StyledInputs/StyledInput';
import { StyledSelectComponent } from '../../components/StyledSelect';
import { useEventTags, useListing, usePlaceTags, useServiceTags } from '../../hooks';
import { TagDto } from '../../models';

type TTagCategory = 'service' | 'place' | 'event';

type NewTagModalProps = Omit<ModalProps, 'onOk'> & {
  onOk: (category: TTagCategory, tagName: string) => void;
};

const tagCategoryOptions: { value: TTagCategory; label: string }[] = [
  { value: 'service', label: 'Для сервиса' },
  { value: 'place', label: 'Для места' },
  { value: 'event', label: 'Для события' },
];

const NewTagModal: React.FC<NewTagModalProps> = ({ onOk, ...modalProps }) => {
  const [tagFor, setTagFor] = useState<Optional<TTagCategory>>();
  const [tagName, setTagName] = useState<string>('');

  const handleOkButtonClick = () => {
    if (tagFor) {
      onOk(tagFor, tagName);
    }
  };

  const isOnButtonDisabled = typeof tagFor === 'undefined' || tagName.trim().length === 0;

  return (
    <Modal
      {...modalProps}
      title="Добавить тег"
      onOk={handleOkButtonClick}
      okButtonProps={{ disabled: isOnButtonDisabled }}
    >
      <StyledSelectComponent
        value={tagFor}
        className="w-100"
        onChange={setTagFor}
        placeholder="Категория тега"
        options={tagCategoryOptions}
      />
      <StyledInput
        className="mt-24"
        value={tagName}
        placeholder="Название тега"
        onChange={(e) => setTagName(e.target.value)}
      />
    </Modal>
  );
};

export const Tags = () => {
  const [activeTab, setActiveTab] = useState<TTagCategory>('service');
  const [isModalOpen, setIsModalOpen] = useState(false);

  const {
    data: serviceTagsData,
    paginationProps: serviceTagsPaginationProps,
    meta: serviceTagsMeta,
    refetch: refetchServiceTags,
  } = useListing<TagDto>(Index.services + '/tags?');
  const serviceTags = serviceTagsData?.data;

  const {
    data: placesTagsData,
    paginationProps: placeTagsPaginationProps,
    meta: placeTagsMeta,
    refetch: refetchPlaceTags,
  } = useListing<TagDto>(Index.places + '/tags?');
  const placeTags = placesTagsData?.data;

  const {
    data: eventsTagsData,
    paginationProps: eventsTagsPaginationProps,
    meta: eventsTagsMeta,
    refetch: refetchEventTags,
  } = useListing<TagDto>(Index.adminEvents + '/tags?');
  const eventTags = eventsTagsData?.data;

  const tagTabs: {
    tab: string;
    key: TTagCategory;
    paginationData: { props: PaginationProps; total?: number; defaultCurrent?: number };
  }[] = useMemo(
    () => [
      {
        tab: 'Теги для сервисов',
        key: 'service',
        paginationData: {
          props: serviceTagsPaginationProps,
          total: serviceTagsMeta?.total,
          defaultCurrent: serviceTagsPaginationProps.current,
        },
      },
      {
        tab: 'Теги для мест',
        key: 'place',
        paginationData: {
          props: placeTagsPaginationProps,
          total: placeTagsMeta?.total,
          defaultCurrent: placeTagsPaginationProps.current,
        },
      },
      {
        tab: 'Теги для событий',
        key: 'event',
        paginationData: {
          props: eventsTagsPaginationProps,
          total: eventsTagsMeta?.total,
          defaultCurrent: eventsTagsPaginationProps.current,
        },
      },
    ],
    [
      eventsTagsMeta?.total,
      eventsTagsPaginationProps,
      placeTagsMeta?.total,
      placeTagsPaginationProps,
      serviceTagsMeta?.total,
      serviceTagsPaginationProps,
    ],
  );

  const { refetch: refetchEventTagsEntity } = useEventTags();
  const { refetch: refetchPlaceTagsEntity } = usePlaceTags();
  const { refetch: refetchServiceTagsEntity } = useServiceTags();

  const getRefetchAndBaseUrl = (category: TTagCategory) => {
    switch (category) {
      case 'event':
        return { baseUrl: Index.adminEvents, refetch: refetchEventTags, refetchEntity: refetchEventTagsEntity };
      case 'place':
        return { baseUrl: Index.places, refetch: refetchPlaceTags, refetchEntity: refetchPlaceTagsEntity };
      case 'service':
        return { baseUrl: Index.services, refetch: refetchServiceTags, refetchEntity: refetchServiceTagsEntity };
      default:
        return {};
    }
  };

  const handleDeleteTag = async (category: TTagCategory, id: number) => {
    const { baseUrl, refetch, refetchEntity } = getRefetchAndBaseUrl(category);

    try {
      await axios.delete(baseUrl + `/tags/${id}`);
      notification.success({ message: `Teг удален` });
      refetch?.();
      refetchEntity?.();
    } catch (error) {
      notification.error({ message: 'Ошибка при удалени тега' });
    } finally {
      setIsModalOpen(false);
    }
  };

  const handleSaveNewTag = async (category: TTagCategory, name: string) => {
    const { baseUrl, refetch, refetchEntity } = getRefetchAndBaseUrl(category);

    try {
      await axios.post(baseUrl + '/tags', { name });
      notification.success({ message: `Teг '${name}' сохранен` });
      refetch?.();
      refetchEntity?.();
    } catch (error) {
      notification.error({ message: 'Ошибка при создании тега' });
    } finally {
      setIsModalOpen(false);
    }
  };

  const tagsData = useMemo(() => {
    if (activeTab === 'event') {
      return eventTags;
    } else if (activeTab === 'place') {
      return placeTags;
    } else if (activeTab === 'service') {
      return serviceTags;
    } else {
      return [];
    }
  }, [serviceTags, placeTags, eventTags, activeTab]);

  return (
    <div>
      <PageTitle
        title="Теги"
        titleClassName="mb-24"
        wrapperClassName="mb-24"
        onAddButtonClick={() => setIsModalOpen(true)}
      />
      <Layout>
        <Content className="mt-40 p-16 rounded-16 bg-neutral">
          <Tabs onChange={setActiveTab as (activeKey: string) => void} activeKey={activeTab}>
            {tagTabs.map((t) => (
              <Tabs.TabPane {...t} key={t.key}>
                <List
                  itemLayout="horizontal"
                  dataSource={tagsData}
                  renderItem={(item) => (
                    <List.Item
                      actions={[
                        <Button onClick={() => handleDeleteTag(t.key, item.id)} type="link" danger key="delete">
                          Удалить
                        </Button>,
                      ]}
                    >
                      {item.name}
                    </List.Item>
                  )}
                />
                <Pagination
                  {...t.paginationData.props}
                  total={t.paginationData.total}
                  defaultCurrent={t.paginationData.defaultCurrent}
                />
              </Tabs.TabPane>
            ))}
          </Tabs>
        </Content>
      </Layout>
      <NewTagModal
        destroyOnClose
        open={isModalOpen}
        okText="Сохранить"
        onOk={handleSaveNewTag}
        onCancel={() => setIsModalOpen(false)}
      />
    </div>
  );
};
