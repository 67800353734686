import { useContext } from 'react';
import { UserContext } from '../contexts/UserContext';
import useAxios from 'axios-hooks';
import { TagDto } from '../models';
import { Index } from '../client/endpoints';

export type TThematics = { thematics: TagDto[]; refetchThematics: () => void };
export const useThematics = (): TThematics => {
  const isAuthorized = typeof useContext(UserContext).user !== 'undefined';
  const [{ data }, refetchThematics] = useAxios<BaseResponse<TagDto[]>>(
    { url: Index.adminEvents + '/info/thematics?limit=20', method: 'GET' },
    { manual: !isAuthorized },
  );

  return { thematics: data?.data || [], refetchThematics };
};
