import React from 'react';
import { Typography } from 'antd';
import { FormHeader } from 'components/FormHeader';
import { ListCard } from 'components/ListCard';
import { useNavigate } from 'react-router-dom';
import { PlaceFrontend } from 'pages/Place/mappers';
import { NAVIGATION } from 'paths';
import { placeTabKeys, TAB_PREFIX } from 'hooks/useTabs';

type TParentPlaceProps = Required<PlaceFrontend>['parent'];

export const ParentPlace: React.FC<TParentPlaceProps> = ({ id, image, name }) => {
  const push = useNavigate();
  return (
    <div>
      <FormHeader
        title={
          <Typography.Title className="m-0 d-flex flex-items-end" level={3}>
            Расположение
          </Typography.Title>
        }
        type="fullwidth"
      />
      <ListCard
        title={name}
        imageUrl={image}
        onClick={() => push(`${NAVIGATION.places}/${id}?${TAB_PREFIX}=${placeTabKeys.connections}`)}
      />
    </div>
  );
};
