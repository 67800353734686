import React, { PropsWithChildren, ReactNode } from 'react';
import { Col, Divider, Row, Typography } from 'antd';
import { TitleProps } from 'antd/lib/typography/Title';

export type FormHeaderProps = {
  title?: ReactNode;
  className?: string;
  type?: 'form' | 'fullwidth';
  level?: TitleProps['level'];
  required?: boolean;
};

export const FormHeader: React.FC<PropsWithChildren<FormHeaderProps>> = ({
  title,
  children,
  level = 3,
  type = 'form',
  className = '',
  required,
}) => {
  const titleContent = (
    <div className={className}>
      <div className={`${level === 3 ? 'pb-40' : 'pb-24'}`}>
        <div className="d-flex flex-align-center flex-justify-between">
          <div className="d-flex flex-align-center gap-8">
            {required && <div className={'required'} />}
            {typeof title === 'string' ? (
              <Typography.Title className="m-0" level={level}>
                {title}
              </Typography.Title>
            ) : (
              title
            )}
          </div>
          {children}
        </div>
      </div>
      <Divider className="mt-0 mb-24" />
    </div>
  );

  if (type === 'form') {
    return (
      <Row>
        <Col span={16} offset={8}>
          {titleContent}
        </Col>
      </Row>
    );
  }
  return titleContent;
};
