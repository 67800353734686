import { useCallback } from 'react';
import useAxios from 'axios-hooks';
import { SelectOptions } from '../components/DebouncedSelect/DebouncedSelect';

const useAsyncSelectOptions = <T>(url: string, mapper: (array: Array<T>) => SelectOptions, selectedIds?: number[]) => {
  const [{ loading: isFetching }, getValues] = useAxios<BaseResponse<Array<T>>>(
    {
      url: url,
      method: 'GET',
    },
    { manual: true },
  );

  const fetcher = useCallback(
    async (searchQuery?: string): Promise<SelectOptions> => {
      try {
        const { data } = await getValues({
          url: `${url}${searchQuery}`,
        });

        const filteredData =
          selectedIds && selectedIds?.length > 0
            ? // @ts-ignore: later
              data.data.filter((item) => !selectedIds.includes(item.id))
            : data.data;

        return mapper(filteredData);
      } catch (e) {
        return [];
      }
    },
    [getValues, mapper, url, selectedIds],
  );

  return {
    fetcher,
    isFetching,
  };
};

export default useAsyncSelectOptions;
