import { needTabKeys, needTabs, useTabs } from '../../hooks/useTabs';
import React from 'react';
import { FormProviderProps } from 'antd/lib/form/context';
import { Form, Layout, Spin } from 'antd';
import { EntityHeader } from '../../components/EntityHeader';
import { Content } from 'antd/lib/layout/layout';
import { FormHeader } from '../../components/FormHeader';
import { BaseNeedForm } from '../../components/BaseNeedForm';
import { LinkedRolesForm } from '../../components/LinkedRolesForm';
import { NeedFormValues, NeedRole } from './Need';
import { TabsWithButtons } from '../../components/TabsWithButtons/TabsWithButtons';

export type NeedPageProps = {
  id: string | undefined;
  onArchive: () => void;
  onSubmit: (data: NeedFormValues) => void;
  isLoading: boolean;
  initialValues: {
    title: string;
    roles: Array<NeedRole>;
  };
};

export const NeedContent = ({ id, onArchive, onSubmit, initialValues, isLoading }: NeedPageProps) => {
  const { activeTab, tabs, setActiveTab, goToNextTab, goToPrevTab } = useTabs(needTabs);
  const tabContent = {
    [needTabKeys.common]: <BaseNeedForm initialValues={{ title: initialValues.title }} />,
    [needTabKeys.linkedRoles]: <LinkedRolesForm initialValues={{ socialRoles: initialValues.roles }} />,
  };
  const onFormFinish: FormProviderProps['onFormFinish'] = (_, { forms }) => {
    const linkedRoles: Array<NeedRole> = forms['socialRoles'].getFieldValue('socialRoles');
    const { title } = forms['base'].getFieldsValue();
    onSubmit({
      roles: linkedRoles,
      title: title,
    });
  };

  if (isLoading) {
    return (
      <Layout>
        <Spin spinning={isLoading}></Spin>
      </Layout>
    );
  }

  return (
    <Layout>
      <Form.Provider onFormFinish={onFormFinish}>
        <div>
          <EntityHeader
            title={id !== 'new' ? initialValues.title : 'Новая социальная потребность'}
            onArchiveButtonClick={isNaN(Number(id)) ? undefined : onArchive}
          />
          <TabsWithButtons
            filteredTabs={tabs}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            onLeftButtonClick={goToPrevTab}
            onRightButtonClick={goToNextTab}
          />
          <Content className="mt-40 p-16 rounded-16 bg-neutral">
            {tabs.map(({ key, label, formHeaderType, contentClassName }) => {
              return (
                <div className={contentClassName} key={key}>
                  <FormHeader title={label} type={formHeaderType} />
                  <div>{tabContent[key]}</div>
                </div>
              );
            })}
          </Content>
        </div>
      </Form.Provider>
    </Layout>
  );
};
