import React from 'react';
import { NewContent } from './NewContent';
import { useParams } from 'react-router-dom';
import { Spin } from 'antd';
import { ClientErrorException } from '../../components/ClientErrorException';
import { useNewsBackendActions } from '../../hooks/useNewsActions';
import { mapNewForFrontend } from './mappers';

export const NewItem = () => {
  const { id } = useParams<{ id: string }>();
  const isCreationMode = id === 'new';

  const {
    info: { newData, error, loading, isLoading },
    actions: { handleDeleteNew, onSave },
  } = useNewsBackendActions({ isCreationMode, id });

  if (error) {
    return <ClientErrorException errorCode={error.request.status} />;
  }

  return (
    <Spin spinning={isLoading || loading}>
      {newData && !loading && (
        <NewContent
          newData={mapNewForFrontend(newData)}
          onDeleteButtonClick={handleDeleteNew}
          onSave={onSave}
          isCreationMode={isCreationMode}
        />
      )}
    </Spin>
  );
};
