import React from 'react';
import { ServiceReviewScreens } from 'components/ServiceReviewScreens';
import { FrontendService } from 'pages/Service/mappers';
import { Logo } from '../Logo';
import { Gallery } from 'components/Gallery';
import { ImageEntity, ImageType } from '../../hooks/useUploadImage';

export type ServiceProps = {
  service: FrontendService;
};

export const ServiceImages: React.FC<ServiceProps> = ({ service }) => {
  return (
    <div className="d-flex flex-column gap-40">
      <Logo logo={service.logo?.src} logoId={service.logo?.id} entity={ImageEntity.Services} title={'Иконка сервиса'} />
      <Gallery
        title="Галерея сервиса"
        gallery={service.gallery}
        entity={ImageEntity.Services}
        imageType={ImageType.Gallery}
      />
      <ServiceReviewScreens screens={service.screens} />
    </div>
  );
};
