import { Form } from 'antd';
import { DebouncedSelect } from 'components/DebouncedSelect';
import useAsyncSelectOptions from 'hooks/useAsyncSelectOptions';
import { useCitiesEntity } from 'hooks/useCitiesEntity';
import { AdminCitiesAreasResponseDto } from 'models';
import React, { FC } from 'react';
import { getCitiesEndpointWithSearchUrl } from '../../client/endpoints/cities';

type Props = {
  initialCity?: string;
  onCitySelect?: () => void;
  isMultiple?: boolean;
};

export const CitySelect: FC<Props> = ({ onCitySelect, isMultiple = true }) => {
  const form = Form.useFormInstance();
  const mapDadataResponse = (data?: AdminCitiesAreasResponseDto[]) => {
    return data?.map(({ name, id }) => {
      return { label: name, key: id, value: name };
    });
  };

  const { data: cities } = useCitiesEntity();

  const mappedCities = mapDadataResponse(cities);

  const { fetcher } = useAsyncSelectOptions(getCitiesEndpointWithSearchUrl(), mapDadataResponse);

  return (
    <>
      <Form.Item rules={[{ required: true }]} name="areaName" label="Город">
        <DebouncedSelect
          fetcher={fetcher}
          onSelect={(_, option) => {
            form.setFieldValue('areaId', option.key);
            if (onCitySelect) onCitySelect();
          }}
          initialOptions={mappedCities}
          mode={isMultiple ? 'multiple' : undefined}
        />
      </Form.Item>
      <Form.Item rules={[{ required: true }]} name="areaId" label="Города" style={{ display: 'none' }} />
    </>
  );
};
