import React, { useState } from 'react';
import { DatePicker, Form } from 'antd';
import { ApplicationForm } from 'components/ApplicationForm';
import { FormHeader } from 'components/FormHeader';
import { FrontendService } from 'pages/Service/mappers';
import { ContactsForm } from 'components/ContactsForm';
import { StyledInput } from '../../StyledInputs/StyledInput';
import { StyledInputNumber } from '../../StyledInputs/StyledInputNumber';
import { StyledTextArea } from '../../StyledInputs/StyledTextArea';

export type TechInfoFormInitialValues = Pick<
  FrontendService,
  | 'phones'
  | 'emails'
  | 'sites'
  | 'developer'
  | 'priority'
  | 'compatibility'
  | 'releaseDate'
  | 'legalAddress'
  | 'applications'
  | 'socials'
>;

type TechInfoFormProps = { initialValues: Optional<TechInfoFormInitialValues> };

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

export const TechInfoForm: React.FC<TechInfoFormProps> = ({ initialValues }) => {
  const [form] = Form.useForm();
  const initialFoundChecked = initialValues?.applications.find((item) => item.isBeta);
  const [checkedItem, setCheckedItem] = useState<undefined | string>(
    initialFoundChecked ? `application-info-${initialFoundChecked.name}` : undefined,
  );

  return (
    <Form.Provider
      onFormChange={(_, { forms }) => {
        const applicationInfoForms = [];
        for (const form in forms) {
          if (form.includes('application-info'))
            applicationInfoForms.push({ name: form, item: forms[form].getFieldsValue() });
        }

        const foundBeta = applicationInfoForms.find((form) => form.item.isBeta);
        if (foundBeta) setCheckedItem(foundBeta.name);
        else setCheckedItem(undefined);
      }}
    >
      <Form form={form} initialValues={initialValues} {...layout} name="tech-info">
        <Form.Item name="developer" label="Разработчик">
          <StyledInput />
        </Form.Item>
        <Form.Item label="Приоритет" name="priority" rules={[{ required: true }]}>
          <div>
            <Form.Item name="priority">
              <StyledInputNumber max={30} min={1} maxWidth />
            </Form.Item>
            <div className="text-gray-8">Минимальное значение – 1, максимальное – 30</div>
          </div>
        </Form.Item>
        <Form.Item name="compatibility" label="Совместимость" rules={[{ required: false }]}>
          <StyledTextArea />
        </Form.Item>
        <Form.Item name="releaseDate" label="Дата выпуска" rules={[{ required: false }]}>
          <DatePicker placeholder="" />
        </Form.Item>
        <FormHeader level={4} title="Контакты" className="mb-24" />
      </Form>
      <ContactsForm initialValues={initialValues} />
      <FormHeader level={4} title="Приложения сервиса и версии" className="mb-24" required />
      {initialValues?.applications.map((app) => {
        return <ApplicationForm key={app.name} {...{ ...app, checkedItem }} />;
      })}
    </Form.Provider>
  );
};
