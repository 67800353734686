export const AUTHOR_DESCRIPTION_MAX_LENGTH = 3000;

export const SERVICE_DESCRIPTION_MAX_LENGTH = 15000;

export const SERVICE_SUITABLE_FOR_MAX_LENGTH = 500;
export const NEW_BODY_MAX_LENGTH = 8000;

export const PUSH_DESCRIPTION_MAX_LENGTH = 49;

export const VIDEOS_PLATFORMS = {
  VK: 'Вконтакте',
  YOUTUBE: 'YouTube',
};

export const VIDEOS_QUALITY = {
  high: 'Высокое',
  middle: 'Среднее',
  low: 'Низкое',
};
