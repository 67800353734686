import { Index } from './index';

export const getPushesEndpointUrl = (query = '') => {
  return query.length > 2 ? `${Index.pushes}/?query=${query}` : `${Index.pushes}?`;
};

export const getSendPushesEndpointUrl = (query = '') => {
  return query.length > 2 ? `${Index.pushes}/?isSend=true&query=${query}` : `${Index.pushes}?isSend=true`;
};

export const getPushByIdEndpointUrl = (id?: string) => ({ url: `${Index.pushes}/${id}`, method: 'GET' });

export const deletePushEndpointUrl = (id?: string) => ({ url: `${Index.pushes}/${id}`, method: 'DELETE' });
export const updatePushEndpointUrl = (id?: string) => ({ url: `${Index.pushes}/${id}`, method: 'PATCH' });
export const createPushEndpointUrl = () => ({ url: Index.pushes, method: 'POST' });
