import moment from 'moment';

export const getDateFromHoursAndMinutes = (value: Optional<string>): Optional<moment.Moment> => {
  if (typeof value === 'undefined') {
    return value;
  } else {
    const [h, m] = value.split(':');
    const hours = Number(h);
    const minutes = Number(m);
    if (!isNaN(hours) && !isNaN(minutes)) {
      return moment().set({ hours: +hours, minutes: +minutes });
    }
  }
};
