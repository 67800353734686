import { notification, UploadFile, UploadProps } from 'antd';
import useAxios from 'axios-hooks';
import { PlaceImage } from 'models';
import { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

type Params = Parameters<Required<UploadProps>['customRequest']>[0];

type ImageUploadResponse = BaseResponse<Image[]>;

export enum ImageType {
  Icon = 'icon',
  Gallery = 'gallery',
  Screenshot = 'screenshot',
}

export enum ImageEntity {
  Places = 'places',
  Services = 'services',
  Events = 'events',
  SocialRole = 'roles',
  News = 'news',
  Authors = 'authors',
}

export type Image = {
  id: number;
  url: string;
  fullUrl: string;
  createdAt: string;
  updatedAt: string;
  imageType?: PlaceImage['imageType'];
};

export type GalleryItem = Pick<UploadFile, 'uid' | 'url' | 'name'>;

export const getGalleryItem = ({ id, fullUrl, imageType }: Image) => ({
  url: fullUrl,
  uid: id.toString(),
  name: `${imageType || ''}-${id}`,
});

export const useUploadImage = (url: string, method = 'POST', dataKey = 'files') => {
  const downloadLogo = useAxios<ImageUploadResponse>({ url, method: method }, { manual: true })[1];

  const uploadImage = ({ file }: Params) => {
    const data = new FormData();
    data.append(dataKey, file);
    return downloadLogo({ data, headers: { 'Content-Type': 'multipart/form-data' } });
  };

  return uploadImage;
};

export const useEntityUploadImage = (
  imageType: ImageType,
  entity: ImageEntity,
  onSuccess?: (data: GalleryItem[]) => void,
) => {
  const { id: entityId } = useParams<{ id: string }>();
  const url = `/admin/v1/${entity}/${entityId}/images/${imageType}`;
  const uploadImage = useUploadImage(url);
  const [isLoading, setIsLoading] = useState(false);

  const uploadImageFunction = useCallback(
    async (params: Params) => {
      try {
        setIsLoading(true);
        const { data } = await uploadImage(params);
        setIsLoading(false);
        return onSuccess?.(data.data.map(getGalleryItem));
      } catch (error) {
        notification.error({
          message: 'При загрузке изображения что-то пошло не так. Попробуйте позднее',
        });
        console.error(error);
      }
    },
    [onSuccess, uploadImage],
  );

  return useMemo(
    () => ({
      isLoading,
      uploadImage: uploadImageFunction,
    }),
    [isLoading, uploadImageFunction],
  );
};

export const useEntityUploadSingleImage = (entity: ImageEntity, onSuccess?: () => void) => {
  const { id: entityId } = useParams<{ id: string }>();
  const url = `/admin/v1/${entity}/${entityId}/image`;
  const uploadImage = useUploadImage(url, 'PATCH', 'image');

  return async (params: Params) => {
    try {
      await uploadImage(params);
      return onSuccess?.();
    } catch (error) {
      notification.error({
        message: 'При загрузке изображения что-то пошло не так. Попробуйте позднее',
      });
      console.error(error);
    }
  };
};
