import { Checkbox, Layout, Pagination, Row, Spin } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { Content } from 'antd/lib/layout/layout';
import { getPushesEndpointUrl, getSendPushesEndpointUrl } from 'client/endpoints/pushes';
import { ListCard } from 'components/ListCard';
import { NotFoundSearchResults } from 'components/NotFoundSearchResults';
import { PageTitle } from 'components/PageTitle';
import { StyledInput } from 'components/StyledInputs/StyledInput';
import { useDebounce, useListing } from 'hooks';
import { AdminGetListNotificationsResponse } from 'models';
import moment from 'moment';
import 'moment/locale/ru';
import { NAVIGATION } from 'paths';
import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './styles.module.css';

export const Pushes = () => {
  const push = useNavigate();
  const [searchValue, setSearchValue] = useState('');

  const handleSearchInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const searchName = e.target.value;
    setSearchValue(searchName);
  }, []);

  const debouncedSearchValue = useDebounce(searchValue, 1000);

  const [checkboxValue, changeCheckboxStatus] = useState(false);

  const { data, paginationProps, loading } = useListing<AdminGetListNotificationsResponse>(
    checkboxValue ? getSendPushesEndpointUrl(debouncedSearchValue) : getPushesEndpointUrl(debouncedSearchValue),
    { current: 1, pageSize: 16 },
  );

  const handleCheckboxChange = (e: CheckboxChangeEvent) => {
    changeCheckboxStatus(e.target.checked);
  };

  moment.locale('ru');

  return (
    <div>
      <PageTitle title="Пуши" wrapperClassName="mb-24" onAddButtonClick={() => push(`${NAVIGATION.pushes}/new`)} />
      <Layout>
        <Content>
          <div className="p-24 mt-40 bg-neutral mb-24 rounded-8">
            <StyledInput value={searchValue} onChange={handleSearchInputChange} placeholder="Название пуша" />
            <Checkbox
              className={styles.checkbox}
              // name={item.value}
              // key={item.key}
              checked={checkboxValue}
              value={checkboxValue}
              onChange={handleCheckboxChange}
            >
              Архивные
            </Checkbox>
          </div>
          <Row className="gap-16" justify={loading ? 'center' : undefined}>
            {data && data.data.length < 1 && !loading && <NotFoundSearchResults />}
            {data === undefined || loading ? (
              <Spin className="flex-justify-center" size="large" />
            ) : (
              data.data.map(({ id, title, body, date, time }) => (
                <ListCard
                  key={id}
                  title={title || ''}
                  description={
                    <>
                      <p>{body}</p>
                      <span>
                        {moment(date).format('DD MMMM YYYY')} в {time}
                      </span>
                    </>
                  }
                  onClick={() => push(`${NAVIGATION.pushes}/${id}`)}
                  noImage
                />
              ))
            )}
          </Row>
          {data?.meta.total ? (
            <div className="d-flex flex-justify-end">
              <Pagination
                {...paginationProps}
                total={data.meta.total}
                pageSizeOptions={[8, 12, 16, 20]}
                defaultCurrent={paginationProps.current}
              />
            </div>
          ) : null}
        </Content>
      </Layout>
    </div>
  );
};
