import { Col, Form, Row, Switch, Typography } from 'antd';
import React from 'react';
import { AROUND_THE_CLOCK } from 'utils';

type TScheduleSettingsProps = {
  noScheduleButtonClick: (checked: boolean) => void;
  aroundTheClockButtonClick: (checked: boolean) => void;
};

export const ScheduleSettings: React.FC<TScheduleSettingsProps> = ({
  // eslint-disable-next-line
  noScheduleButtonClick,
  aroundTheClockButtonClick,
}) => {
  return (
    <Row gutter={24}>
      <Col className="d-flex gap-16 flex-items-center">
        <Form.Item name={AROUND_THE_CLOCK} valuePropName="checked" className="m-0">
          <Switch onChange={aroundTheClockButtonClick} />
        </Form.Item>
        <Typography.Title className="m-0" level={4}>
          Работает круглосуточно
        </Typography.Title>
      </Col>
      {/* <Col className="d-flex gap-16 flex-items-center">
        <Form.Item name={NO_SCHEDULE} valuePropName="checked" className="m-0">
          <Switch onChange={noScheduleButtonClick} />
        </Form.Item>
        <Typography.Title className="m-0" level={4}>
          Расписание не указано
        </Typography.Title>
      </Col>*/}
    </Row>
  );
};
