import { Index } from './index';

export const getVideosEndpointUrl = () => {
  return `${Index.videosAdmin}?`;
};

export const getVideoByIdEndpointUrl = (id?: string) => ({ url: `${Index.videosAdmin}/${id}`, method: 'GET' });
export const updateVideoBaseInfoEndpointUrl = (id?: string) => ({ url: `${Index.videosAdmin}/${id}`, method: 'PATCH' });

export const updateVideoRolesEndpointUrl = (id?: string) => ({
  url: `${Index.videosAdmin}/${id}/roles`,
  method: 'PATCH',
});

export const updateVideoAreasEndpointUrl = (id?: string) => ({
  url: `${Index.videosAdmin}/${id}/areas`,
  method: 'PATCH',
});
