import React, { useEffect, useRef, useState } from 'react';
import { Upload, UploadFile } from 'antd';
import { FormHeader } from 'components/FormHeader';
import { PlusOutlined } from '@ant-design/icons';

import { useEntityUploadImage, useServiceDeleteImage } from 'hooks';
import { GalleryItem, ImageEntity, ImageType } from 'hooks/useUploadImage';
import { GalleryImage } from '../GalleryImage';

type GalleryProps = { gallery: GalleryItem[] } & {
  title?: string;
  imageType: ImageType;
  entity: ImageEntity;
  favoriteImage?: string;
  onStarClick?: (imageUrl: string) => void;
};

export const Gallery: React.FC<GalleryProps> = ({ title, gallery, entity, imageType, onStarClick, favoriteImage }) => {
  const initialFavoriteImage = useRef(favoriteImage);
  const [fileList, setFileList] = useState(gallery);
  const { uploadImage: customRequest, isLoading } = useEntityUploadImage(imageType, entity, (data) =>
    setFileList((pV) => [...pV, ...data]),
  );
  const deleteImage = useServiceDeleteImage(entity, imageType, (imageId) => {
    setFileList((pV) => {
      // strange logic for removing favourite image donwloaded image
      const deletedImageUrl = pV.find(({ uid }) => uid === imageId)?.url;
      if (deletedImageUrl === favoriteImage) {
        onStarClick?.('');
      }
      return pV.filter(({ uid }) => uid !== imageId);
    });
  });

  const handleDeleteImage = ({ uid }: UploadFile) => {
    deleteImage(uid);
  };

  useEffect(() => {
    if (isLoading) setFileList((prev) => [...prev, { url: '', name: 'Загрузка...', uid: 'loading' }]);
    else {
      setFileList((prev) => prev.filter((image) => image.uid !== 'loading'));
    }
  }, [isLoading]);

  return (
    <div>
      <FormHeader title={title} type="fullwidth" level={4} />
      <Upload
        accept=".jpeg,.jpg,.png"
        customRequest={customRequest}
        className="service-gallery-upload"
        itemRender={(_, { url, name, thumbUrl }: GalleryItem & Pick<UploadFile, 'thumbUrl'>, __, { remove }) => (
          <GalleryImage
            initialFavoriteImage={initialFavoriteImage}
            onStarClick={onStarClick}
            remove={remove}
            favoriteImage={favoriteImage}
            name={name}
            url={url}
            thumbUrl={thumbUrl}
          />
        )}
        showUploadList={{ previewIcon: undefined, removeIcon: false }}
        onRemove={handleDeleteImage}
        listType="picture-card"
        fileList={fileList}
      >
        <PlusOutlined />
      </Upload>
    </div>
  );
};
