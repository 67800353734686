import { Form } from 'antd';
import { StyledInput } from '../StyledInputs/StyledInput';

type BaseNeedFormProps = {
  initialValues: {
    title: string;
  };
};

export const BaseNeedForm = ({ initialValues }: BaseNeedFormProps) => {
  const [form] = Form.useForm<BaseNeedFormProps['initialValues']>();
  return (
    <Form name={'base'} form={form} initialValues={initialValues}>
      <Form.Item
        label={'Заголовок (наименование)'}
        name={'title'}
        rules={[{ required: true, message: 'Поле обязательно к заполнению' }]}
      >
        <StyledInput />
      </Form.Item>
    </Form>
  );
};
