import { Form, Switch } from 'antd';
import { StyledInputNumber } from 'components/StyledInputs/StyledInputNumber';
import { TextEditor } from 'components/TextEditor';
import { AUTHOR_DESCRIPTION_MAX_LENGTH } from '../../constants';
import { FrontendAuthorItem } from 'pages/Author/types';
import React from 'react';
import { StyledInput } from 'components/StyledInputs/StyledInput';
import styles from 'components/PushBaseInfoForm/styles.module.css';

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

type TPlaceBaseInfoFormProps = { initialValues: FrontendAuthorItem };

export const AuthorBaseInfoForm: React.FC<TPlaceBaseInfoFormProps> = ({ initialValues }) => {
  const [form] = Form.useForm();
  const isTopAuthor = Form.useWatch('isTopAuthor', form);

  const changeTopAuthor = () => {
    form.setFieldValue('isTopAuthor', !isTopAuthor);
  };

  return (
    <Form form={form} initialValues={initialValues} {...layout} name="base-info">
      <Form.Item
        name="name"
        label="Имя"
        rules={[{ required: true }, { max: 100, message: 'Максимальное количество символов не должно превышать 100' }]}
      >
        <StyledInput />
      </Form.Item>
      <Form.Item name="isTopAuthor" label="ТОП автор" className={styles.topContainer}>
        <div className="d-flex gap-8 rounded-8 ">
          <Switch checked={isTopAuthor} onChange={changeTopAuthor} />
        </div>
      </Form.Item>
      <Form.Item
        label="Приоритет"
        name="priority"
        rules={[{ required: true, message: 'Пожалуйста, введите Приоритет' }]}
      >
        <Form.Item name="priority">
          <StyledInputNumber max={30} min={1} maxWidth />
        </Form.Item>
        <div className="text-gray-8 mt-16">Минимальное значение – 1, максимальное – 30</div>
      </Form.Item>
      <Form.Item
        name="announcement"
        label="Анонс"
        rules={[{ required: true }, { max: 200, message: 'Максимальное количество символов не должно превышать 200' }]}
      >
        <StyledInput.TextArea />
      </Form.Item>
      <Form.Item name="description" label="Описание" rules={[{ required: true }]}>
        <TextEditor maxSymbols={AUTHOR_DESCRIPTION_MAX_LENGTH} />
      </Form.Item>
    </Form>
  );
};
